import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin-bottom: 30px;
`;

export const EditPolygon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 25px;
  background-color: #eee;
`;

interface EditButtonProps {
  isActive: boolean;
}

export const EditButton = styled.button<EditButtonProps>`
  border-bottom: 1px solid rgba(29, 62, 139, 1);
  border-left: none;
  border-right: none;
  border-top: none;
  padding: 5px 10px;
  background: transparent;
  font-size: 14px;
  border-radius: 3px;
  color: rgba(29, 62, 139, 1);
  transition: color 0.5s, background 0.5s;
  margin-right: 10px;

  ${(props) =>
    props.isActive &&
    css`
      background: rgba(29, 62, 139, 1);
      color: #fff;
    `}
`;

export const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DeleteButton = styled.button`
  border: none;
  margin: 0 20px;
  background: rgba(29, 62, 139, 1);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
`;

export const ResetButton = styled.button`
  border: none;
  background: rgba(29, 62, 139, 1);
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  color: #fff;
`;

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const AlertResetArea = styled.div`
  z-index: 1000;
  position: fixed;
  top: 25%;
  left: 35%;
  width: 40%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appear} 1s;
  .padding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    h1 {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: 16px;
    }
    .btn-container {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      button {
        width: 25%;
        font-size: 14px;
        padding: 5px 0;
        margin: 0 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
`;
export const ContainerOpacity = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 500;
  background: #000;
  opacity: 0.5;
`;
