import React from 'react';

import { ContainerInitial, BackGround } from './style';
import Loader from '../Loader';
import SideBar from '../SideBar';
import Header from '../Header';
import { useBusiness } from '../../hooks/business';
import { useSocketHook } from '../../hooks/socket';
import AllowSound from '../AllowSound';

interface IProps {
  isLoaded: boolean;
  isOpen:
    | 'dashboard'
    | 'order'
    | 'addItem'
    | 'profile'
    | 'clients'
    | 'notify'
    | 'vouchers'
    | 'rates'
    | 'accounts'
    | 'tutorials'
    | 'configuration';
}

const Container: React.FC<IProps> = ({ isOpen, isLoaded, children }) => {
  const { role } = useBusiness();
  const { soundLoaded } = useSocketHook();

  return (
    <>
      <Header />
      <SideBar isOpen={isOpen} />
      <BackGround>
        <ContainerInitial role={role}>
          {isLoaded ? children : <Loader />}
        </ContainerInitial>
      </BackGround>
      {soundLoaded ? null : <AllowSound />}
    </>
  );
};

export default Container;
