import styled, { css } from 'styled-components';

interface ContainerProps {
  isActivated: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: block;
  ${(props) =>
    !props.isActivated &&
    css`
      display: none;
    `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .first-row {
    width: 100%;
    h1 {
      align-self: flex-start;
      font-size: 12px;
      font-weight: 200;
      margin: 30px 0 10px 0;
    }
    input {
      height: 15px;
      &::placeholder {
        font-size: 12px;
        font-weight: 400;
      }
    }

    button {
      width: 80%;
      height: 37px;
      border: 1px solid #ddd;
      border-radius: 3px;
      background: inherit;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      p {
        margin-left: 20px;
        font-size: 12px;
        color: #777;
      }
      div {
        background: #ccc;
        width: 30%;
        height: 36px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        p {
          margin: 0;
          align-self: center;
          color: #222;
        }
      }
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;

  .first-row,
  .secound-row,
  .third-row {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 30px;

    h1 {
      align-self: flex-start;
      font-size: 12px;
      font-weight: 200;
      margin-bottom: 10px;
    }

    & + div {
      margin-left: 10px;
    }
  }

  .description {
    width: 500px;
  }
  .tag {
    align-self: flex-start;
  }
  .price {
    align-items: flex-start;
    width: 200px !important;
  }
  .isFinalPrice {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  h4 {
    font-size: 14px;
    font-weight: 300;
    display: inline-block;
    margin: 5px 5px 10px 5px;
    span {
      color: #ff0000;
    }
  }
`;
