import React from 'react';
import { FiX } from 'react-icons/fi';

import {
  Container,
  SubCategories,
  RemoveButton,
  MaxChoices,
  OmitMaxMin,
} from './styles';
import TextInput from '../../../../../components/inputs/TextInput';
import RadioInput from '../../../../../components/inputs/RadioInput';

interface IProps {
  removeSubCategory(): void;
  choice: string;
  switchChoiceType(e: React.ChangeEvent<HTMLInputElement>): void;
}

const SubCategoryForm: React.FC<IProps> = ({
  removeSubCategory,
  switchChoiceType,
  choice,
  children,
}) => {
  return (
    <Container>
      <SubCategories>
        <div className="subCategory-first-row">
          <div className="subCategory-name">
            <h1>Nome:</h1>
            <TextInput name="name" type="text" />
          </div>

          <div className="subCategory-choices">
            <h1>Qtd. Mínima:</h1>
            <TextInput
              placeholder="Ex: 0"
              name="minChoices"
              type="text"
              disabled={choice === 'RADIO'}
            />
            {choice === 'RADIO' ? <OmitMaxMin /> : null}
          </div>
          <div className="subCategory-choices">
            <h1>Qtd. Máxima:</h1>

            <TextInput
              placeholder="Ex: 5"
              name="maxChoices"
              type="text"
              disabled={choice === 'RADIO'}
            />
            {choice === 'RADIO' ? <OmitMaxMin /> : null}

            <MaxChoices isModify />
          </div>
          <div className="subCategory-priority">
            <h1>Ordenamento:</h1>
            <TextInput name="priority" type="text" />
          </div>
        </div>
        <div className="subCategory-secound-row">
          <RadioInput
            name="choiceType"
            onChange={switchChoiceType}
            options={[
              {
                label: 'Única Escolha',
                value: 'RADIO',
              },
              {
                label: 'Múltipla Escolha',
                value: 'CHECKBOX',
              },
              {
                label: 'Múltipla Escolha Empilhável',
                value: 'STACK',
              },
            ]}
          />
          <RemoveButton type="button" onClick={removeSubCategory}>
            <h1>Remover</h1>
            <FiX size={15} />
          </RemoveButton>
        </div>
      </SubCategories>
      {children}
    </Container>
  );
};

export default SubCategoryForm;
