import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Button = styled.button`
  display: flex;
  width: 200px;
  align-items: center;
  justify-content: center;
  background: #f58a07;
  border-radius: 3px;
  border: none;
  padding: 10px 10px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  animation: ${appear} 1s;

  &:hover {
    background: ${shade(0.2, '#f58a07')};
  }
`;

const appearOpacity = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
`;

export const ContainerOpacity = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  animation: ${appearOpacity} 1s;
`;
