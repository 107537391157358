import React, { useState, useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';

import Container from '../../components/Container';
import Title from '../../components/Title';

import { Table, Client } from './styles';
import { useBusiness } from '../../hooks/business';

const Clients: React.FC = () => {
  // State
  const [isLoaded, setIsLoaded] = useState(false);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const formattedPhoneNumber = useCallback((x) => {
    // recebe um +5547123456789 e retorna (47) 12345-6789
    return `(${x.slice(3, 5)}) ${x.slice(5, 10)}-${x.slice(10, x.length)}`;
  }, []);

  return (
    <Container isOpen="clients" isLoaded={isLoaded}>
      <Table>
        <Title title="Meus Clientes" />
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>CPF</th>
              <th>Telefone</th>
              <th>Data de Nascimento</th>
            </tr>
          </thead>
          <tbody>
            {currentUnit.clients &&
              currentUnit.clients.map((client, index) => (
                <Client hasColor={!(index % 2)} key={client.id}>
                  <th>{client.nickname}</th>
                  <th>{client.cpf ? client.cpf : '-'}</th>
                  <th>{formattedPhoneNumber(client.phone)}</th>
                  <th>
                    {client.dateOfBirth
                      ? `${format(new Date(client.dateOfBirth), `d'/'M'/'Y`, {
                          locale: ptLocale,
                        })}`
                      : '-'}
                  </th>
                </Client>
              ))}
          </tbody>
        </table>
      </Table>
    </Container>
  );
};

export default Clients;
