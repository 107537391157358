/* eslint-disable no-nested-ternary */
import React, { useState, useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';

import { FiX, FiArrowDown, FiArrowUp, FiPrinter } from 'react-icons/fi';

import { uuid } from 'uuidv4';
import { Link } from 'react-router-dom';
import {
  Container,
  InfoClientOrder,
  Order,
  Price,
  Description,
  OrderInformation,
  Table,
  SubItem,
  ButtonStatus,
  ChangeStatus,
  ChangeStatusBtn,
  CancelBtn,
  AlertModal,
  PrintBtn,
} from './styles';
import Title from '../../../components/Title';
import { IOrder } from '../../../entities/Order';
import { useUnit } from '../../../hooks/unit';
import { useBusiness } from '../../../hooks/business';

const colors = {
  finished: 'lightblue',
  cancelled: 'lightred',
  ready: 'orange',
  confirmed: 'lightgreen',
  received: 'yellow',
};

interface IProps {
  order: IOrder;
  HandleHideModal(): void;
}

const OrderInfo: React.FC<IProps> = ({ HandleHideModal, order }) => {
  const [changeStatus, setChangeStatus] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, setPrinter] = useState(false);

  const { currentUnit } = useBusiness();
  const { updateOrderStatus } = useUnit();

  const formattedPhoneNumber = useCallback((x) => {
    // recebe um +5547123456789 e retorna (47) 12345-6789
    return `(${x.slice(3, 5)}) ${x.slice(5, 10)}-${x.slice(10, x.length)}`;
  }, []);

  const getLastOrderStatus = useCallback(
    (orderStatus: {
      received: Date;
      confirmed?: Date;
      ready?: Date;
      finished?: Date;
      cancelled?: Date;
    }): {
      status: string;
      translation: string;
      color: string;
    } => {
      if (orderStatus.cancelled) {
        return {
          status: 'cancelled',
          translation: 'Cancelado',
          color: 'red',
        };
      }

      if (orderStatus.finished) {
        return {
          status: 'finished',
          translation: 'Finalizado',
          color: 'lightblue',
        };
      }

      if (orderStatus.ready) {
        return {
          status: 'ready',
          translation: 'Pronto',
          color: 'orange',
        };
      }

      if (orderStatus.confirmed) {
        return {
          status: 'confirmed',
          translation: 'Confirmado',
          color: 'lightgreen',
        };
      }

      if (orderStatus.received) {
        return {
          status: 'received',
          translation: 'Recebido',
          color: 'yellow',
        };
      }

      return {
        status: 'finished',
        translation: 'erro',
        color: 'lightblue',
      };
    },
    [],
  );

  const handleTotalPrice = useCallback(
    (voucherDiscount, subTotal, deliveryFee, isPercentage) => {
      if (voucherDiscount) {
        if (!isPercentage) {
          return `R$${(subTotal + deliveryFee - voucherDiscount)
            .toFixed(2)
            .replace('.', ',')}`;
        }
        return `R$${(
          subTotal +
          deliveryFee -
          ((subTotal * voucherDiscount) / 100 || 0)
        )
          .toFixed(2)
          .replace('.', ',')}`;
      }
      return `R$${(subTotal + deliveryFee).toFixed(2).replace('.', ',')}`;
    },
    [],
  );

  const handleIsPercentage = useCallback(
    (voucherDiscount, isPercentage, voucherTitle) => {
      if (voucherDiscount) {
        if (isPercentage) {
          return `%${voucherDiscount.toFixed(2).replace('.', ',')}`;
        }
        return `- R$${voucherDiscount.toFixed(2).replace('.', ',')}`;
      }
      if (voucherTitle) {
        return `${voucherTitle}`;
      }
      return '-R$0,00';
    },
    [],
  );

  return (
    <Container>
      <div className="headline">
        <i>
          <FiX
            size={20}
            onClick={() => {
              HandleHideModal();
            }}
          />
        </i>
        <Link to={{ pathname: '/print', state: order }}>
          <PrintBtn
            type="button"
            onClick={() => {
              setPrinter(true);
            }}
          >
            Imprimir <FiPrinter size={15} />
          </PrintBtn>
        </Link>
      </div>
      <OrderInformation>
        <div className="orders-headline">
          <h3>#{order?.orderNumber}</h3>
          <div className="btn-status">
            {!order.status.finished && (
              <>
                <CancelBtn
                  onClick={() => {
                    setShowModal(true);
                  }}
                  type="button"
                >
                  <p>Cancelar Pedido</p>
                </CancelBtn>
                <ButtonStatus
                  style={
                    changeStatus
                      ? { background: '#fff' }
                      : { background: 'inherit' }
                  }
                  onClick={() => {
                    setChangeStatus(!changeStatus);
                  }}
                >
                  <p>Avançar Etapa</p>
                  {!changeStatus ? (
                    <FiArrowUp size={15} />
                  ) : (
                    <>
                      <FiArrowDown size={15} />
                      <ChangeStatus>
                        {!order.status.confirmed && (
                          <ChangeStatusBtn
                            key="confirmed"
                            onClick={() => {
                              updateOrderStatus(order.id, 'confirmed');
                            }}
                            type="button"
                            style={{
                              background: colors.confirmed,
                            }}
                          >
                            <p>Confirmado</p>
                          </ChangeStatusBtn>
                        )}
                        {!order.status.ready && (
                          <ChangeStatusBtn
                            key="ready"
                            onClick={() => {
                              updateOrderStatus(order.id, 'ready');
                            }}
                            type="button"
                            style={{
                              background: colors.ready,
                            }}
                          >
                            <p>Pronto</p>
                          </ChangeStatusBtn>
                        )}
                        {!order.status.finished && (
                          <ChangeStatusBtn
                            key="finished"
                            onClick={() => {
                              updateOrderStatus(order.id, 'finished');
                            }}
                            type="button"
                            style={{
                              background: colors.finished,
                            }}
                          >
                            <p>Finalizado</p>
                          </ChangeStatusBtn>
                        )}
                      </ChangeStatus>
                    </>
                  )}
                </ButtonStatus>
              </>
            )}
          </div>
        </div>
        <InfoClientOrder>
          <div className="first-infoClintOrder-row">
            <Table isDescription={false}>
              <div className="left">
                <h2>Nome</h2>
                <h1>
                  {order.client.nickname
                    ? order.client.nickname
                    : currentUnit.clients.find(
                        (client) => client.id === (order.client as unknown),
                      )?.nickname}
                </h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center">
                <h2>Data do pedido</h2>
                <h1>
                  {`${format(
                    new Date(order.status.received),
                    `d'/'M'/'Y 'às' H':'mm`,
                    {
                      locale: ptLocale,
                    },
                  )}`}
                </h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center status">
                <h2>Estado do pedido</h2>
                <span
                  style={{
                    background: getLastOrderStatus(order.status).color,
                    color: 'black',
                  }}
                >
                  {getLastOrderStatus(order.status).translation}
                </span>
                <span>la</span>
              </div>
            </Table>
          </div>
          <div className="secound-infoClintOrder-row">
            <Table isDescription={false}>
              <div className="left">
                <h2>Telefone</h2>
                <h1>
                  {formattedPhoneNumber(
                    order.client.phone
                      ? order.client.phone
                      : currentUnit.clients.find(
                          (client) => client.id === (order.client as unknown),
                        )?.phone,
                  )}
                </h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center">
                <h2>CPF/CNPJ</h2>
                <h1>{order?.invoiceDocument ? order?.invoiceDocument : '-'}</h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center">
                <h2>Cep</h2>
                <h1>{order.isDelivery ? order?.address?.cep : '-'}</h1>
              </div>
            </Table>
          </div>
          <div className="third-infoClintOrder-row">
            <Table isDescription>
              <div className="left">
                <h2>Endereço</h2>
                <h1>
                  {order.isDelivery
                    ? `${order?.address?.street} ${order?.address?.number} - ${order?.address?.city}`
                    : '-'}
                </h1>
              </div>
            </Table>
          </div>
          <div className="fourth-infoClintOrder-row">
            <Table isDescription={false}>
              <div className="left">
                <h2>Complemento</h2>
                <h1>
                  {order?.address?.extraInfo ? order?.address.extraInfo : '-'}
                </h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center">
                <h2>Canal</h2>
                <h1>{order.isDelivery ? 'Entrega' : 'Retirada'}</h1>
              </div>
            </Table>
            <Table isDescription={false}>
              <div className="center">
                <h2>Forma de Pagamento</h2>
                <h1>
                  {`${
                    order.paymentMethod?.type !== 'Dinheiro'
                      ? `${order.paymentMethod?.cardBrand} - `
                      : ''
                  }${order.paymentMethod?.type}`}
                </h1>
              </div>
            </Table>
          </div>
        </InfoClientOrder>
        {order?.items.map((item, index) => {
          return (
            <Order key={`${item.name}+${index}`}>
              <div className="row-order">
                <div className="first-order-column">
                  <Table isDescription={false}>
                    <div className="left">
                      <h2>qtd</h2>
                      <h1>{`${item.amount}x`}</h1>
                    </div>
                  </Table>
                </div>
                <div className="secound-order-column">
                  <Table isDescription={false}>
                    <div className="left">
                      <h2>Descrição</h2>
                      <h1>{item.name}</h1>
                    </div>
                  </Table>
                  <SubItem>
                    {item.subItems.map((subItem) => {
                      return (
                        <p key={uuid()}>
                          {`${subItem.amount}x ${subItem.name}`}
                        </p>
                      );
                    })}
                  </SubItem>
                </div>
                <div className="third-order-column">
                  <Table isDescription={false}>
                    <div className="right">
                      <h2>Preço</h2>
                      <h1>
                        {`${item.price.toFixed(2).replace('.', ',')} reais`}
                      </h1>
                    </div>
                  </Table>
                </div>
              </div>
              <div className="observation">
                <h1>Observações do item:</h1>
                <p>{item.observations}</p>
              </div>
            </Order>
          );
        })}
        <Price>
          <Description>
            <div className="observation">
              <h1>Observações do pedido:</h1>
              <p>{order?.observations}</p>
            </div>
          </Description>
          <div className="position">
            <div className="sub-total">
              <h4>Subtotal</h4>
              <h4>
                {`R$${order?.subTotalPrice.toFixed(2).replace('.', ',')}`}
              </h4>
            </div>
            <div className="voucher">
              <h4>Cupom</h4>
              <h4>
                {handleIsPercentage(
                  order.voucher?.discount,
                  order.voucher?.isPercentage,
                  order.voucher?.title,
                )}
              </h4>
            </div>
            <div className="delivery">
              <h4>Taxa de entrega</h4>
              <h4>{`R$${order?.deliveryFee?.toFixed(2).replace('.', ',')}`}</h4>
            </div>
            <div className="spread" />
            <div className="total">
              <h4>Total</h4>
              <h4>
                {handleTotalPrice(
                  order.voucher?.discount,
                  order.subTotalPrice,
                  order.deliveryFee,
                  order.voucher?.isPercentage,
                ) || '0'}
              </h4>
            </div>
            {order.changeFor ? (
              <div className="change">
                <h4>Troco para:</h4>
                <h4>{`R$${order.changeFor.toString()},00`}</h4>
              </div>
            ) : null}
          </div>
        </Price>
      </OrderInformation>
      {showModal ? (
        <AlertModal>
          <Title title="Cancelamento do pedido" />
          <div className="padding-container">
            <h1>Deseja realizar o cancelamento desse pedido?</h1>
            <div className="btn-container">
              <button
                style={{ background: 'red' }}
                type="button"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Não
              </button>
              <button
                style={{ background: 'green' }}
                type="button"
                onClick={() => {
                  updateOrderStatus(order.id, 'cancelled');
                  setShowModal(false);
                }}
              >
                Sim
              </button>
            </div>
          </div>
        </AlertModal>
      ) : null}
    </Container>
  );
};

export default OrderInfo;
