import React, { createContext, useContext, useCallback, useState } from 'react';
import { useToast } from './toast';

export interface IUser {
  name: string;
  invoiceDocument?: string;
  dateOfBirth?: string;
}

export interface IAddress {
  cep: string;
  state: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
  complement: string;
}

interface IProps {
  createUser(user: IUser): Promise<void>;
  user: IUser | undefined;
  createAddress(address: IAddress): Promise<void>;
  userAddress: IAddress | undefined;
}

const SiteContext = createContext<IProps>({} as IProps);

const SiteProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser>();
  const [userAddress, setUserAddress] = useState<IAddress>();

  const { addToast } = useToast();

  const createUser = useCallback(
    async (data: IUser) => {
      try {
        setUser(data);
        addToast({
          title: 'Unidade atualizada',
          type: 'success',
        });
        return;
      } catch (error) {
        addToast({
          title: 'Não foi possível atualizar essa unidade',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
      }
    },
    [addToast],
  );

  const createAddress = useCallback(
    async (data: IAddress) => {
      try {
        setUserAddress(data);
        addToast({
          title: 'Endereço criado',
          type: 'success',
        });
        return;
      } catch (error) {
        addToast({
          title: 'Não foi possível criar esse endereço',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
      }
    },
    [addToast],
  );

  return (
    <SiteContext.Provider
      value={{ createUser, user, createAddress, userAddress }}
    >
      {children}
    </SiteContext.Provider>
  );
};

function useSite(): IProps {
  const context = useContext(SiteContext);

  if (!context) {
    throw new Error('useSite must be used within a SiteProvider');
  }
  return context;
}

export { SiteContext, SiteProvider, useSite };
