import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 95%;
  border: solid 1px #ccc;
  margin: 20px auto 20px auto;
`;

export const PrepareTime = styled.div`
  padding: 30px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  .prepare-input {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;

    h1 {
      font-size: 14px;
      font-weight: 400;
      margin: 0 0 20px 0;
    }
    .input-btn {
      height: 40px;
      display: flex;
      flex-direction: row;
      .btn-alterar {
        font-size: 12px;
        height: 40px;
        color: #fff;
        background: #f58a07;
        border: 0;
        border-radius: 3px;
        align-self: flex-end;
      }
    }
  }
`;

export const ChooseDelivery = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 30px 30px;
`;

interface IButtonProps {
  isClicked: boolean;
}

export const ButtonDelivery = styled.button<IButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  border: none;
  padding-right: 30px;
  h1 {
    font-size: 14px;
    font-weight: 200;
  }
  div {
    height: 15px;
    width: 15px;
    border: solid 1px #ccc;
    margin-right: 10px;
    border-radius: 50%;
  }

  ${(props) =>
    props.isClicked &&
    css`
      div {
        background: #f58a07;
      }
    `}
`;

export const AreaDelivery = styled.div`
  margin: 0 30px;
  h1 {
    font-size: 14px;
    font-weight: 200;
    margin-bottom: 15px;
  }
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    .div-input {
      margin-right: 30px;

      p {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }

    .btn-add {
      button {
        font-size: 12px;
        color: #fff;
        background: #f58a07;
        border: 0;
        border-radius: 3px;
        padding: 13px 20px;
        align-self: flex-end;
      }
    }
  }
`;

export const DeliveryRadius = styled.div`
  margin-top: 30px;
`;

export const RangePrice = styled.div`
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 10px;
  margin-bottom: 15px;
  div {
    height: 30px;
    width: 30px;
    border-radius: 4px;
  }
  h1 {
    font-size: 14px;
    font-weight: 200;
  }
  button {
    border: none;
    background: inherit;
    svg {
      color: #ff0000;
    }
  }
`;
