import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaDatabase,
  FaUtensils,
  FaPlusCircle,
  FaRegEdit,
  FaDoorOpen,
  FaBell,
  FaUsers,
  FaTicketAlt,
  FaWhmcs,
  FaLock,
  FaStar,
  FaFilm,
} from 'react-icons/fa';

import { useAuth } from '../../hooks/auth';
import { Container, RetractBtn } from './styles';
import ContainerOpacity from '../ContainerOpacity';
import { useBusiness } from '../../hooks/business';

interface IProps {
  isOpen:
    | 'dashboard'
    | 'order'
    | 'addItem'
    | 'profile'
    | 'clients'
    | 'notify'
    | 'vouchers'
    | 'rates'
    | 'accounts'
    | 'tutorials'
    | 'configuration';
}

const SideBar: React.FC<IProps> = ({ isOpen }) => {
  const { signOut } = useAuth();
  const { role } = useBusiness();
  const [showBar, setShowBar] = useState(false);
  return (
    <>
      <Container
        isOpen={isOpen}
        showBar={showBar}
        isManager={!!(role === 'manager')}
        isEmployee={!!(role === 'employee')}
      >
        <Link
          to="/dashboard"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="dashboard">
            <FaDatabase size={15} />

            <h1>Início</h1>
          </div>
        </Link>

        <Link
          to="/order"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="order">
            <FaUtensils size={15} />

            <h1>Pedidos</h1>
          </div>
        </Link>

        <Link
          to="/additem"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="addItem">
            <FaPlusCircle size={15} />

            <h1>Meu Cardápio</h1>
          </div>
        </Link>

        <Link
          to="/notify"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="notify">
            <FaBell size={15} />

            <h1>Enviar Notificação</h1>
          </div>
        </Link>

        <Link
          to="/vouchers"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="vouchers">
            <FaTicketAlt size={15} />

            <h1>Cupom</h1>
          </div>
        </Link>

        <Link
          to="/configurations"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="configuration">
            <FaWhmcs size={15} />

            <h1>Meu Delivery</h1>
          </div>
        </Link>

        <Link
          to="/clients"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="clients">
            <FaUsers size={15} />

            <h1>Meus Clientes</h1>
          </div>
        </Link>

        <Link
          to="/editinfo"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="profile">
            <FaRegEdit size={15} />

            <h1>Meu Perfil</h1>
          </div>
        </Link>

        <Link
          to="/rates"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="rates">
            <FaStar size={15} />

            <h1>Avaliações</h1>
          </div>
        </Link>

        <Link
          to="/accounts"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="accounts">
            <FaLock size={15} />

            <h1>Contas</h1>
          </div>
        </Link>

        <Link
          to="/tutorials"
          onClick={() => {
            setShowBar(false);
          }}
        >
          <div className="tutorials">
            <FaFilm size={15} />

            <h1>Tutoriais</h1>
          </div>
        </Link>

        <Link
          to="/"
          onClick={() => {
            setShowBar(false);
            signOut();
          }}
        >
          <div>
            <FaDoorOpen size={15} />

            <h1>Sair</h1>
          </div>
        </Link>
      </Container>
      {!showBar ? (
        <RetractBtn
          type="button"
          isEmployee={role === 'employee'}
          onClick={() => {
            setShowBar(true);
          }}
        />
      ) : (
        <ContainerOpacity
          onClick={() => {
            setShowBar(false);
          }}
        />
      )}
    </>
  );
};

export default SideBar;
