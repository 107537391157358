import styled, { keyframes, css } from 'styled-components';

interface IContainerProps {
  isColored: boolean;
}

export const Container = styled.tbody<IContainerProps>`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;
  border-top: 1px solid #bbb;

  button {
    border: none;
    background-color: inherit;
    color: rgba(29, 62, 139, 1);
    font-size: 14px;
  }
  a {
    cursor: pointer;
  }
  td {
    font-size: 15px;
  }
  .tr-body {
    text-align: center;

    .td-body {
      padding: 20px 0;
    }
    .details {
      width: 10%;
    }
    .voucher {
      width: 10%;
    }
    .canal {
      width: 10%;
    }
    .status {
      width: 10%;
    }
    .client {
      width: 10%;
    }
    .cellphone {
      width: 10%;
    }
    .payment {
      width: 10%;
    }
    .price {
      width: 10%;
    }
    .voucher {
      width: 10%;
    }

    .status {
      span {
        padding: 5px;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
        font-weight: 700;
      }
    }
    .details {
      button {
        text-decoration-line: underline;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  ${(props) =>
    props.isColored &&
    css`
      background: #f1f3f6;
    `}
  @media screen and (min-width: 1600px) {
    button {
      font-size: 15px;
    }
    .tr-body {
      .td-body {
        font-size: 14px;
      }
      .status {
        span {
          background: green;
          padding: 5px;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
`;

const appearOpacity = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
`;

export const ContainerOpacity = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  animation: ${appearOpacity} 1s;
  z-index: 500;
`;
