import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const AccountContainer = styled.div`
  width: 100%;
  padding: 0 0 50px 0;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
  margin-bottom: 20px;
`;

export const AccountInfo = styled.div`
  width: 650px;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
  margin-bottom: 20px;
  button {
    margin-left: 15px;
    padding: 5px;
    font-size: 12px;
    border-bottom: none;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    transition: background 1s;
    &:hover {
      cursor: pointer;
      background: #ccc;
    }
  }
`;
export const ContentInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 30px 0;
  width: 650px;
  .label {
    width: 300px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    input {
      padding: 5px 10px;
      width: 250px;
      font-size: 14px;
      border: 1px solid #ccc;
      background: #eee;
      border-radius: 3px;
    }
    p {
      font-size: 12px;
      margin-right: 5px;
    }
  }
`;
const appearPassword = keyframes`
  from {
    height: 0;
  }
  to{
    height: 250px;
  }
`;
export const PasswordInfo = styled.div`
  width: 648px;
  padding: 10px 30px 0 30px;
  background: #eee;
  border-top: 1px solid #ccc;
  border-radius: 3px;
  animation: ${appearPassword} 1s;
  h1 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .label {
    width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    div {
      padding: 0;
      margin: 0;
      width: 250px;
      input {
        padding: 5px 10px;
        width: 250px;
        font-size: 14px;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 3px;
      }
    }
    p {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  button {
    width: 100px;
    margin-bottom: 10px;
    margin-top: 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin: 40px;
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: center;
    background: #f58a07;
    border-radius: 3px;
    border: none;
    padding: 10px 10px;
    color: #fff;
    font-weight: 500;
    transition: background-color 0.2s;
    font-size: 14px;
    animation: ${appearContainer} 1s;

    &:hover {
      background: ${shade(0.2, '#f58a07')};
    }
  }
`;

export const TableAccounts = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;

  thead {
    border-top: none;
    border-bottom: 1px solid #111;
    .tr-header {
      text-align: center;
      .td-header {
        font-size: 12px;
        font-weight: bold;
        padding: 10px 0;
      }
      .username {
        text-align: start;

        padding-left: 10px;
        width: 20%;
      }

      .role {
        text-align: center;

        padding-left: 10px;
        width: 10%;
      }

      .unit {
        text-align: center;

        padding-left: 10px;
        width: 40%;
      }
      .active {
        width: 10%;
      }
      .remove {
        width: 10%;
      }
    }
  }
`;
