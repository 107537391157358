import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  min-width: 700px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 1000;
  animation: ${appear} 1s;

  p {
    color: white;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 50px 30px 50px;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    width: 100%;
    h4 {
      font-size: 14px;
      font-weight: 400;
      display: inline-block;
      margin: 5px 5px 0 5px;
    }
    div {
      input {
        margin-right: 10px;
        padding-right: 10px;
      }
      div {
        width: 20px;
        margin: 0;
        padding: 0;
      }
    }
    margin-right: 10px;
    margin-top: 10px;
  }
`;

export const Button = styled.button`
  align-self: flex-end;
  width: 150px;
  background: #70bb35;
  border-radius: 3px;
  border: none;
  padding: 10px 16px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  margin-top: 50px;

  &:hover {
    background: ${shade(0.2, '#70bb35')};
  }
`;

export const Permissions = styled.div`
  align-self: flex-start;
  margin-top: 20px;
  h1 {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .div-input-permission {
    div {
      display: none;
    }
  }
  select {
    margin-bottom: 10px;
    padding: 5px;
    border: 1px solid #ccc;
  }
`;
