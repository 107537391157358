import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 30px;
`;

export const SubCategories = styled.div`
  background: #e3e6f0;
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  .subCategory-first-row {
    margin-bottom: 30px;
  }
  .subCategory-first-row,
  .subCategory-secound-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;

    .isRequired {
      display: flex;
      flex-direction: row;
      p {
        color: #111;
      }
      .div-input-subCategory {
        div {
          padding: 0;
          margin: 0;
          background: inherit;
          border: none;
          input {
            padding: 0;
            margin: 0;
            width: 30px;
          }
        }
      }
    }
    .isMultipleChoice {
      display: flex;
      flex-direction: row;
      p {
        color: #111;
      }
      .div-input-subCategory {
        div {
          padding: 0;
          margin: 0;
          background: inherit;
          border: none;
          input {
            padding: 0;
            margin: 0;
            width: 30px;
          }
        }
      }
    }
    .isStackable {
      display: flex;
      flex-direction: row;
      p {
        color: #111;
      }
      .div-input-subCategory {
        div {
          padding: 0;
          margin: 0;
          background: inherit;
          border: none;
          input {
            padding: 0;
            margin: 0;
            width: 30px;
          }
        }
      }
    }
    .subCategory-name,
    .subCategory-choices,
    .subCategory-priority {
      h1 {
        align-self: flex-start;
        font-size: 12px;
        font-weight: 200;
        margin-bottom: 10px;
      }
      input {
        height: 15px;
        width: 100%;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .subCategory-choices,
    .subCategory-priority {
      flex: 2;
      margin-left: 20px;
    }
    .subCategory-choices {
      position: relative;
    }
  }
`;

interface MaxchoicesProps {
  isModify: boolean;
}

export const MaxChoices = styled.div<MaxchoicesProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 50px;
  width: 150px;
  background-color: #e3e6f0;
  opacity: 0.6;
  z-index: 1000;

  ${(props) =>
    props.isModify &&
    css`
      display: none;
    `}
`;

interface ButtonProps {
  isActive: boolean;
}

export const IsRequiredButton = styled.button<ButtonProps>`
  border: none;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    height: 15px;
    width: 15px;
    background: #fff;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    transition: background 0.5s;
  }
  h1 {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 200;
  }

  ${(props) =>
    props.isActive &&
    css`
      div {
        background: #333;
      }
    `}
`;
export const MultipleChoiceButton = styled.button<ButtonProps>`
  border: none;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    height: 15px;
    width: 15px;
    background: #fff;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    transition: background 0.5s;
  }
  h1 {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 200;
  }
  ${(props) =>
    props.isActive &&
    css`
      div {
        background: #333;
      }
    `}
`;
export const StackableButton = styled.button<ButtonProps>`
  border: none;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  div {
    height: 15px;
    width: 15px;
    background: #fff;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 2px;
    transition: background 0.5s;
  }
  h1 {
    align-self: flex-start;
    font-size: 12px;
    font-weight: 200;
  }
  ${(props) =>
    props.isActive &&
    css`
      div {
        background: #333;
      }
    `}
`;

export const RemoveButton = styled.button`
  border: none;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 12px;
    font-weight: 200;
    color: #c53030;
    margin-right: 5px;
  }
  svg {
    color: #c53030;
  }
`;

export const OmitMaxMin = styled.div`
  position: absolute;
  bottom: 0;

  height: 35px;
  width: 145px;
  background: #ccc;
  opacity: 0.5;
  z-index: 50;
`;
