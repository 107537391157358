import React from 'react';
import Container from '../../components/Container';

import { ContainerTutorials, Content } from './styles';
import Title from '../../components/Title';

const Tutorial: React.FC = () => {
  return (
    <Container isOpen="tutorials" isLoaded>
      <ContainerTutorials>
        <Title title="Tutoriais" />
        <Content>
          <div className="grid">
            <div className="video">
              <h1>1. Avaliações, Meus clientes e Início</h1>
              <iframe
                title="Avaliações, Meus clientes e Início"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/Mt45VbtUr4A"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="video">
              <h1>2. Meu perfil</h1>
              <iframe
                title="Meu perfil"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/OD7BhDoAoCA"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>3. Meu delivery</h1>
              <iframe
                title="Meu delivery"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/kDleRtq4G6s"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>4. Meu Cardápio - Criando categorias</h1>
              <iframe
                title="Meu Cardápio - Criando categorias"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/S1AmMD7AXzc"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
            <div className="video">
              <h1>5. Meu Cardápio - Criando e ordenando item</h1>
              <iframe
                title="Meu Cardápio - Criando e ordenando item"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/sDnnm48K15A"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>6. Meu Cardápio - Criando itens "A partir de"</h1>
              <iframe
                title="Meu Cardápio - Criando A partir de"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/hkmQxyCxz0I"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>7. Meu Cardápio - Criando complementos nos itens</h1>
              <iframe
                title="Meu Cardápio - Criando complementos nos itens"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/-dR7bPJQUK4"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>8. Meu Cardápio - ordenando complementos e subitens</h1>
              <iframe
                title="Meu Cardápio - ordenando complementos e subitens"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/cmuRxPgcwYI"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>9.Meus Pedidos</h1>
              <iframe
                title="Meus pedidos"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/Q9dfis7IFyM"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>10. Notificações</h1>
              <iframe
                title="Notificações"
                width={270}
                height={270}
                src="https://www.youtube.com/embed/PaU621cC-q0"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>11.Cupons</h1>
              <iframe
                title="Cupons"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/s_6Gq6F67SU"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>12. Contas</h1>
              <iframe
                title="Contas"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/MY4wMpsvZXQ"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>

            <div className="video">
              <h1>13. Configurações de administrador</h1>
              <iframe
                title="Configurações de administrador"
                width="270"
                height="270"
                src="https://www.youtube.com/embed/FeGjQHEaBuY"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </Content>
      </ContainerTutorials>
    </Container>
  );
};

export default Tutorial;
