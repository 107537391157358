import React, { useState, useEffect } from 'react';
import TextInput from '../../../../../../components/inputs/TextInput';

import { Container } from './styles';

interface IProps {
  label: string;
  name: string;
  initValue: string;
}

const HourPicker: React.FC<IProps> = ({ label, name, initValue }) => {
  const [hours, setHours] = useState(() => {
    // eslint-disable-next-line eqeqeq
    if (initValue == '1440') return '0';
    const totalMinutes = Number.parseInt(initValue, 10);
    return (totalMinutes - (totalMinutes % 60)).toString();
  });
  const [minutes, setMinutes] = useState(() => {
    const totalMinutes = Number.parseInt(initValue, 10);
    return (totalMinutes % 60).toString();
  });
  const [time, setTime] = useState(initValue);

  useEffect(() => {
    console.log(hours, minutes);
    let totalMinutes: number;

    if (hours === '0' && minutes === '0' && name === 'closesAt') {
      totalMinutes = 1440;
    } else {
      totalMinutes = Number.parseInt(hours, 10) + Number.parseInt(minutes, 10);
    }

    setTime(totalMinutes.toString());
    console.log('totalMInutes', totalMinutes);
  }, [hours, minutes, name]);

  return (
    <Container>
      <p>{label} </p>
      <div className="div-input-pickHour">
        <TextInput name={name} type="hidden" value={time} />
      </div>
      <select
        value={hours}
        onChange={(e) => {
          setHours(e.target.value);
        }}
      >
        <option value="0">00h</option>
        <option value="60">1h</option>
        <option value="120">2h</option>
        <option value="180">3h</option>
        <option value="240">4h</option>
        <option value="300">5h</option>
        <option value="360">6h</option>
        <option value="420">7h</option>
        <option value="480">8h</option>
        <option value="540">9h</option>
        <option value="600">10h</option>
        <option value="660">11h</option>
        <option value="720">12h</option>
        <option value="780">13h</option>
        <option value="840">14h</option>
        <option value="900">15h</option>
        <option value="960">16h</option>
        <option value="1020">17h</option>
        <option value="1080">18h</option>
        <option value="1140">19h</option>
        <option value="1200">20h</option>
        <option value="1260">21h</option>
        <option value="1320">22h</option>
        <option value="1380">23h</option>
      </select>

      <select
        value={minutes}
        onChange={(e) => {
          setMinutes(e.target.value);
        }}
      >
        <option value="0">00</option>
        <option value="1">01</option>
        <option value="2">02</option>
        <option value="3">03</option>
        <option value="4">04</option>
        <option value="5">05</option>
        <option value="6">06</option>
        <option value="7">07</option>
        <option value="8">08</option>
        <option value="9">09</option>
        <option value="10">10</option>
        <option value="11">11</option>
        <option value="12">12</option>
        <option value="13">13</option>
        <option value="14">14</option>
        <option value="15">15</option>
        <option value="16">16</option>
        <option value="17">17</option>
        <option value="18">18</option>
        <option value="19">19</option>
        <option value="20">20</option>
        <option value="21">21</option>
        <option value="22">22</option>
        <option value="23">23</option>
        <option value="24">24</option>
        <option value="25">25</option>
        <option value="26">26</option>
        <option value="27">27</option>
        <option value="28">28</option>
        <option value="29">29</option>
        <option value="30">30</option>
        <option value="31">31</option>
        <option value="32">32</option>
        <option value="33">33</option>
        <option value="34">34</option>
        <option value="35">35</option>
        <option value="36">36</option>
        <option value="37">37</option>
        <option value="38">38</option>
        <option value="39">39</option>
        <option value="40">40</option>
        <option value="41">41</option>
        <option value="42">42</option>
        <option value="43">43</option>
        <option value="44">44</option>
        <option value="45">45</option>
        <option value="46">46</option>
        <option value="47">47</option>
        <option value="48">48</option>
        <option value="49">49</option>
        <option value="50">50</option>
        <option value="51">51</option>
        <option value="52">52</option>
        <option value="53">53</option>
        <option value="54">54</option>
        <option value="55">55</option>
        <option value="56">56</option>
        <option value="57">57</option>
        <option value="58">58</option>
        <option value="59">59</option>
      </select>
    </Container>
  );
};

export default HourPicker;
