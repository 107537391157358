/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';
import { uuid } from 'uuidv4';

import { Container } from './styles';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  options: {
    value: 'manager' | 'employee';
    label: string;
  }[];
}

const RadioInputPermission: React.FC<IProps> = ({ name, options, ...rest }) => {
  const inputRefs = useRef<HTMLInputElement[]>([]);
  const { fieldName, registerField, defaultValue = 'manager' } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs: HTMLInputElement[]) => {
        return (refs.find((ref) => ref.checked) as HTMLInputElement).value;
      },
      setValue: (refs: HTMLInputElement[], value: string) => {
        refs.forEach((ref) => {
          if (value === ref.value) {
            ref.checked = true;
          } else {
            ref.checked = false;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const setName = uuid();
  return (
    <Container>
      {options.map((option, index) => (
        <label htmlFor={option.value} key={option.value}>
          <input
            defaultChecked={defaultValue === option.value}
            ref={(ref) => {
              inputRefs.current[index] = ref as HTMLInputElement;
            }}
            value={option.value}
            type="radio"
            name={setName}
            {...rest}
          />
          <p>{option.label}</p>
        </label>
      ))}
    </Container>
  );
};

export default RadioInputPermission;
