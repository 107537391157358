import styled, { keyframes } from 'styled-components';

const appearOpacity = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
`;

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  animation: ${appearOpacity} 1s;
`;
