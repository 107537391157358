import React, { useRef, useCallback, useState, useEffect } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import Picker from 'emoji-picker-react';
import getValidationErrors from '../../utils/getValidationErrors';

import TextInput from '../../components/inputs/TextInput';
import SpotsButton from '../../components/buttons/SpotsButton';

import Container from '../../components/Container';
import Title from '../../components/Title';

import { Notifies, NotifiesTable, Notified, EmojiBtn } from './styles';
import { useBusiness } from '../../hooks/business';
import { useUnit } from '../../hooks/unit';

interface IFormData {
  title: string;
  body: string;
}

const Notify: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [openEmojiTitle, setOpenEmojiTitle] = useState(false);
  const [openEmojiBody, setOpenEmojiBody] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [busy, setBusy] = useState(false);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();
  const { createNotification } = useUnit();

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setBusy(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string(),
          body: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await createNotification({
          body: data.body,
          title: data.title,
          unit: currentUnit.id,
        });
        setBusy(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          setBusy(false);
        }
      }
    },
    [createNotification, currentUnit.id],
  );

  const onEmojiClickTitle = useCallback((event, emojiObject) => {
    const value = formRef.current?.getFieldValue('title');
    formRef.current?.setFieldValue('title', `${value}${emojiObject.emoji}`);
    setOpenEmojiTitle(false);
  }, []);

  const onEmojiClickBody = useCallback((event, emojiObject) => {
    const value = formRef.current?.getFieldValue('body');
    formRef.current?.setFieldValue('body', `${value}${emojiObject.emoji}`);
    setOpenEmojiBody(false);
  }, []);

  return (
    <Container isOpen="notify" isLoaded={isLoaded}>
      <Notifies>
        <Title title="Notificações push" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="line">
            <div className="text-input title">
              <p>Título da Notificação</p>
              <TextInput name="title" type="text" maxLength={30} />
              <div>
                <EmojiBtn
                  type="button"
                  onClick={() => {
                    setOpenEmojiTitle(!openEmojiTitle);
                  }}
                >
                  Emoji
                </EmojiBtn>
                {openEmojiTitle ? (
                  <div style={{ position: 'absolute' }}>
                    <Picker disableSearchBar onEmojiClick={onEmojiClickTitle} />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="text-input description">
              <p>Descrição da Notificação</p>
              <TextInput name="body" type="text" maxLength={100} />
              <div>
                <EmojiBtn
                  type="button"
                  onClick={() => {
                    setOpenEmojiBody(!openEmojiBody);
                  }}
                >
                  Emoji
                </EmojiBtn>
                {openEmojiBody ? (
                  <div style={{ position: 'absolute' }}>
                    <Picker disableSearchBar onEmojiClick={onEmojiClickBody} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="btn-width">
            <SpotsButton type="submit" disabled={busy}>
              Enviar
            </SpotsButton>
          </div>
        </Form>
        <NotifiesTable>
          <thead>
            <tr>
              <th className="title-th">Título</th>
              <th className="message-th">Mensagem</th>
              <th className="create-th"> Enviado em:</th>
            </tr>
          </thead>
          {currentUnit.notifications &&
            currentUnit.notifications.map((notify) => {
              return (
                <Notified>
                  <tr>
                    <th className="title-th">
                      <p>{notify.title}</p>
                    </th>
                    <th className="message-th">
                      <p>{notify.body}</p>
                    </th>
                    <th className="create-th">
                      <p>
                        {`${format(
                          new Date(notify.date),
                          `d 'de' MMMM 'de' yyyy 'às' H':'m`,
                          {
                            locale: ptLocale,
                          },
                        )}`}
                      </p>
                    </th>
                  </tr>
                </Notified>
              );
            })}
        </NotifiesTable>
      </Notifies>
    </Container>
  );
};

export default Notify;
