/* eslint-disable consistent-return */
import React, { createContext, useContext, useState, useCallback } from 'react';
import { addDays, addMinutes, addMilliseconds } from 'date-fns';
import api from '../services/api';
import { useBusiness } from './business';
import { IUnit } from '../entities/Unit';
import { IUser } from '../entities/User';
import { useAuth } from './auth';
import { IVoucher } from '../entities/Voucher';
import { ICreateVoucher } from '../dtos/CreateVoucher';
import { IEditUnitData } from '../dtos/EditUnit';
import { ICreateCategory } from '../dtos/CreateCategory';
import { ICreateNotification } from '../dtos/CreateNotification';
import { useToast } from './toast';
import { ICategory } from '../entities/Category';
import { IEditCategory } from '../dtos/EditCategory';
import { IItem } from '../entities/Item';
import { IOrder } from '../entities/Order';
import { INotification } from '../entities/Notification';
import { IStorageFile } from '../entities/StorageFile';

interface IUnitContextData {
  editUnit(data: IEditUnitData): Promise<boolean>;

  getUsers(): Promise<void>;
  users?: IUser[];

  vouchers?: IVoucher[];
  getVouchers(): Promise<void>;
  expireVoucher(id: string): Promise<IVoucher | undefined>;
  createVoucher(data: ICreateVoucher): Promise<void>;

  addCategory(data: ICreateCategory): Promise<void | undefined>;
  editCategory(data: IEditCategory): Promise<void | undefined>;
  removeCategory(id: string): Promise<boolean | undefined>;
  addItem(
    data: Omit<IItem, 'id'>,
    categoryId: string,
  ): Promise<IItem | undefined>;
  editItem(
    data: Partial<Omit<IItem, 'id'>>,
    categoryId: string,
    itemId: string,
  ): Promise<IItem | undefined>;
  deleteItem(itemId: string, categoryId: string): Promise<boolean>;
  addItemPhoto(
    data: FormData,
    categoryId: string,
    itemId: string,
  ): Promise<IStorageFile | undefined>;
  deleteItemPhoto(
    itemId: string,
    categoryId: string,
  ): Promise<void | undefined>;

  updateOrderStatus(
    orderId: string,
    orderStatus: 'confirmed' | 'ready' | 'finished' | 'cancelled',
  ): Promise<IOrder | undefined>;

  createNotification(
    data: ICreateNotification,
  ): Promise<INotification | undefined>;
  editLogo(data: FormData, unitId: string): Promise<IStorageFile | undefined>;
}

const UnitContext = createContext({} as IUnitContextData);

const UnitProvider: React.FC = ({ children }) => {
  // State
  const [users, setUsers] = useState<IUser[]>([]);
  const [vouchers, setVoucher] = useState<IVoucher[]>([]);

  // Hooks
  const { addToast } = useToast();
  const { token } = useAuth();
  const { currentUnit, setCurrentUnit } = useBusiness();

  // Business Logic
  //= ================================================= CURRENT UNIT ===================================================
  // -------------------------------------------------- EDIT UNIT -----------------------------------------------------

  const editUnit = useCallback(
    async (data: IEditUnitData) => {
      try {
        const response = await api.put<IUnit>(
          `/units/${currentUnit?.id}`,
          data,
          {
            headers: { authorization: `Bearer ${token}` },
          },
        );
        setCurrentUnit(response.data);
        addToast({
          title: 'Unidade atualizada',
          type: 'success',
        });
        return true;
      } catch (error) {
        addToast({
          title: 'Não foi possível atualizar essa unidade',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return false;
      }
    },
    [addToast, currentUnit, setCurrentUnit, token],
  );

  // --------------------------------------------------- EDIT LOGO ------------------------------------------------------------------

  const editLogo = useCallback(
    async (data: FormData, unitId: string) => {
      try {
        const response = await api.post<IStorageFile>(
          `/units/${unitId}/logo`,
          data,
          {
            headers: { authorization: `Bearer ${token}` },
          },
        );
        setCurrentUnit((state) => {
          state.logo = response.data;
          return state;
        });
        addToast({
          title: 'Unidade atualizada',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Não foi possível atualizar essa unidade atualizada',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );
  //= ===================================================== USERS ===================================================
  // ----------------------------------------------------- GET USERS ----------------------------------------------------------------
  const getUsers = useCallback(async () => {
    try {
      const response = await api.get<IUser[]>(
        `/busnisses/me/clients?unit=${currentUnit?.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      setUsers(response.data);
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }, [currentUnit, token]);

  //= ===================================================== VOUCHERS ===================================================
  // ----------------------------------------------------- GET VOUCHERS ----------------------------------------------------------------
  const getVouchers = useCallback(async () => {
    try {
      const response = await api.get<IVoucher[]>(
        `/vouchers/${currentUnit?.id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        },
      );

      setVoucher(response.data);
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }, [currentUnit, token]);

  // ----------------------------------------------------- EXPIRE VOUCHER ----------------------------------------------------------------
  const expireVoucher = useCallback(
    async (id: string): Promise<IVoucher | undefined> => {
      try {
        const response = await api.delete(`/vouchers/${id}`, {
          headers: { authorization: `Bearer ${token}` },
        });
        setCurrentUnit((state) => {
          const index = state.vouchers.findIndex(
            (voucher) => voucher.id === id,
          );
          state.vouchers[index] = response.data;
          return state;
        });
        addToast({
          title: 'Cupom atualizado com sucesso',
          type: 'success',
        });
        return undefined;
      } catch {
        addToast({
          title: 'Não foi possível atualizar esse cupom',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );
  // ----------------------------------------------------- CREATE VOUCHER ----------------------------------------------------------------
  const createVoucher = useCallback(
    async (data: ICreateVoucher): Promise<void> => {
      const startDate = addMinutes(new Date(data.startsAt), 180);
      const expiryDate = addMilliseconds(
        addMinutes(addDays(new Date(data.expiresAt), 1), 179),
        59999,
      );

      data.startsAt = startDate;
      data.expiresAt = expiryDate;

      try {
        const response = await api.post<IVoucher>(`vouchers`, data, {
          headers: { authorization: `Bearer ${token}` },
        });
        setCurrentUnit((state) => {
          state.vouchers.push(response.data);
          return state;
        });
        addToast({
          title: 'Seu cupom foi criado',
          type: 'success',
        });
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível criar um cupom',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, setCurrentUnit, token],
  );

  //= ===================================================== CATEGORY ===================================================
  // ----------------------------------------------------- Add Category ----------------------------------------------------------------
  const addCategory = useCallback(
    async (data: ICreateCategory) => {
      try {
        const response = await api.post<ICategory>(
          `/categories`,
          { ...data, unit: currentUnit.id },
          {
            headers: { authorization: `Bearer ${token}` },
          },
        );
        setCurrentUnit((state) => {
          state.menu.push(response.data);
          return state;
        });
        addToast({
          title: 'Sua categoria foi adicionada',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Não foi possível criar uma categoria',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
      }
    },
    [addToast, currentUnit.id, setCurrentUnit, token],
  );

  // ----------------------------------------------------- Edit Category ----------------------------------------------------------------
  const editCategory = useCallback(
    async ({ categoryId, name, priority, isActive }: IEditCategory) => {
      try {
        const response = await api.put<ICategory>(
          `/categories/${categoryId}`,
          {
            name,
            priority,
            isActive,
          },
          {
            headers: { authorization: `Bearer ${token}` },
          },
        );

        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          state.menu[index] = response.data;
          return state;
        });
        addToast({
          title: 'Categoria atualizada',
          type: 'success',
        });
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Não foi possível editar essa categoria',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, setCurrentUnit, token],
  );

  // ----------------------------------------------------- Remove Category ----------------------------------------------------------------
  const removeCategory = useCallback(
    async (id: string) => {
      try {
        await api.delete(`categories/${id}`, {
          headers: { authorization: `Bearer ${token}` },
        });

        setCurrentUnit((state) => {
          const index = state?.menu.findIndex((category) => category.id === id);
          state.menu.splice(index, 1);
          return state;
        });
        addToast({
          title: 'Categoria excluida',
          type: 'success',
        });
        return false;
      } catch (error) {
        addToast({
          title: 'Não foi possível excluir essa categoria',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
      }
    },
    [addToast, setCurrentUnit, token],
  );

  //= ===================================================== MENU ===================================================
  // ----------------------------------------------------- Add Item ----------------------------------------------------------------
  const addItem = useCallback(
    async (
      data: Omit<IItem, 'id'>,
      categoryId: string,
    ): Promise<IItem | undefined> => {
      try {
        const response = await api.post<IItem>(
          `/categories/${categoryId}/items`,
          data,
          { headers: { authorization: `Bearer ${token}` } },
        );
        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          state.menu[index].items.push(response.data);
          return state;
        });
        addToast({
          title: 'Produto adicionado no cardápio',
          type: 'success',
        });
      } catch (e) {
        addToast({
          title: 'Não foi possível adicionar este produto',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(e.response);
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );

  // ----------------------------------------------------- Edit Item ----------------------------------------------------------------
  const editItem = useCallback(
    async (
      data: Partial<Omit<IItem, 'id'>>,
      categoryId: string,
      itemId: string,
    ): Promise<IItem | undefined> => {
      try {
        const response = await api.put<IItem>(
          `/categories/${categoryId}/items/${itemId}`,
          data,
          { headers: { authorization: `Bearer ${token}` } },
        );

        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          const itemIndex = state.menu[index].items.findIndex(
            (item) => item.id === itemId,
          );
          state.menu[index].items[itemIndex] = response.data;
          return state;
        });
        addToast({
          title: 'Produto editado',
          type: 'success',
        });
      } catch (error) {
        addToast({
          title: 'Não foi possível editar este produto',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );
  // ----------------------------------------------------- add Item Photo ----------------------------------------------------------------
  const addItemPhoto = useCallback(
    async (
      data: FormData,
      categoryId: string,
      itemId: string,
    ): Promise<IStorageFile | undefined> => {
      try {
        const response = await api.put<IItem>(
          `/categories/${categoryId}/items/${itemId}/photo`,
          data,
          { headers: { authorization: `Bearer ${token}` } },
        );
        addToast({
          title: 'Foto editada',
          type: 'success',
        });
        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          const itemIndex = state.menu[index].items.findIndex(
            (item) => item.id === itemId,
          );
          state.menu[index].items[itemIndex] = response.data;
          return state;
        });
      } catch (error) {
        addToast({
          title: 'Não foi possível editar esta foto',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );
  // ----------------------------------------------------- Delete Item Photo ----------------------------------------------------------------
  const deleteItemPhoto = useCallback(
    async (categoryId: string, itemId: string): Promise<void | undefined> => {
      try {
        await api.delete<IStorageFile>(
          `/categories/${categoryId}/items/${itemId}/photo`,
          { headers: { authorization: `Bearer ${token}` } },
        );
        addToast({
          title: 'Foto deletada',
          type: 'success',
        });
        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          const itemIndex = state.menu[index].items.findIndex(
            (item) => item.id === itemId,
          );
          delete state.menu[index].items[itemIndex].photo;
          return state;
        });
      } catch (error) {
        addToast({
          title: 'Não foi possível deletar esta foto',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );
  // ----------------------------------------------------- Delete Item ----------------------------------------------------------------
  const deleteItem = useCallback(
    async (itemId: string, categoryId: string): Promise<boolean> => {
      try {
        await api.delete(`/categories/${categoryId}/items/${itemId}`, {
          headers: { authorization: `Bearer ${token}` },
        });
        setCurrentUnit((state) => {
          const index = state?.menu.findIndex(
            (category) => category.id === categoryId,
          );
          const itemIndex = state.menu[index].items.findIndex(
            (item) => item.id === itemId,
          );
          state.menu[index].items.splice(itemIndex, 1);
          return state;
        });
        addToast({
          title: 'Produto excluído',
          type: 'success',
        });
        return false;
      } catch (error) {
        addToast({
          title: 'Não foi possível excluir este produto',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        console.log(error);
        return false;
      }
    },
    [addToast, setCurrentUnit, token],
  );

  //= ===================================================== ORDER ===================================================
  // --------------------------------------------------- UPDATE STATUS ----------------------------------------------------------------
  const updateOrderStatus = useCallback(
    async (
      orderId: string,
      orderStatus: 'confirmed' | 'ready' | 'finished' | 'cancelled',
    ): Promise<IOrder | undefined> => {
      try {
        const response = await api.patch<IOrder>(
          `orders/${orderId}/status`,
          { orderStatus },
          {
            headers: { authorization: `Bearer ${token}` },
          },
        );
        setCurrentUnit((state) => {
          const index = state.orders.findIndex((order) => order.id === orderId);
          state.orders[index] = response.data;
          return state;
        });
        addToast({
          title: 'Pedido alterado',
          type: 'success',
        });
        return response.data;
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Não foi alterar o status deste pedido',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );

  //= ===================================================== NOTIFICATION ===================================================
  // --------------------------------------------------- Create Notification ----------------------------------------------------------------
  const createNotification = useCallback(
    async (data: ICreateNotification): Promise<INotification | undefined> => {
      try {
        const response = await api.post<INotification>(`/notifications`, data, {
          headers: { authorization: `Bearer ${token}` },
        });
        addToast({
          title: 'Notificação enviada',
          type: 'success',
        });
        console.log(response.data);
        setCurrentUnit((state) => {
          state.notifications = [...state.notifications, response.data];
          return state;
        });
      } catch (error) {
        console.log(error);
        addToast({
          title: 'Não foi possível enviar essa notificação',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return undefined;
      }
    },
    [addToast, setCurrentUnit, token],
  );

  return (
    <UnitContext.Provider
      value={{
        // Notifications
        createNotification,

        // ORDER
        updateOrderStatus,

        // MENU
        deleteItemPhoto,
        addItemPhoto,
        deleteItem,
        editItem,
        addItem,
        removeCategory,
        editCategory,
        addCategory,

        // VOUCHER
        createVoucher,
        expireVoucher,
        vouchers,
        getVouchers,

        // USERS
        getUsers,
        users,

        // UNIT
        editUnit,
        editLogo,
      }}
    >
      {children}
    </UnitContext.Provider>
  );
};

function useUnit(): IUnitContextData {
  const context = useContext(UnitContext);

  if (!context) {
    throw new Error('use unit must be used within a UnitProvider');
  }

  return context;
}

export { UnitContext, UnitProvider, useUnit };
