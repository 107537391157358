import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const ConfigurationContainer = styled.div`
  border: 1px solid #e3e6f0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  background: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bbb;
  padding-bottom: 30px;
  animation: ${appearContainer} 1s;
`;

export const MapContainer = styled.div`
  margin: 25px 0px;
`;

export const ShowAddress = styled.div`
  position: fixed;
  top: 0;
  left: 13%;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 10;
`;

export const LocationButton = styled.button`
  background: #f58a07;
  color: #fff;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 3px;
  margin-right: 20px;
  margin-left: 30px;
`;

export const ContainerOpacity = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  z-index: 500;
  background: #000;
  opacity: 0.5;
`;
