import styled, { css } from 'styled-components';

export const Container = styled.div``;

interface NavButtonProps {
  isActivated: boolean;
}

export const NavButton = styled.button<NavButtonProps>`
  width: 100%;
  background-color: inherit;
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ccc;

  display: block;

  ${(props) =>
    props.isActivated &&
    css`
      background-color: #fff;
      border-bottom: 0;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    `};

  &:hover {
    background-color: #fff;
  }
`;
