import styled from 'styled-components';

export const Container = styled.div`
  .print {
    width: 210px;
    max-width: 210px;
    padding: 5px;
    h1 {
      font-size: 15px;
    }
    h2 {
      margin-top: 10px;
      text-transform: uppercase;
    }
  }
`;

export const Order = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dotted black;

  .observation-item {
    width: 100%;
    font-size: 12px;
    text-align: left;
    margin: 5px;
  }
`;
export const DeliveryBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 5px;
  border: 1px dotted black;
`;

export const OrderStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 5px 0;
`;

export const ClientStatus = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 5px;
`;
export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px 0;
  border-bottom: 1px dotted black;
  .qtd {
    font-size: 14px;
    text-align: start;
    text-transform: uppercase;
    justify-self: start;
    font-weight: bold;
    width: 15%;
    margin: 0;
  }
  .name {
    font-size: 14px;
    text-transform: uppercase;
    text-align: start;
    justify-self: start;
    width: 55%;
    font-weight: bold;
  }
  .price {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    width: 30%;
    font-weight: bold;
  }
`;

export const TitleOrder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  margin: 3px 0;
  padding-top: 5px;
  .qtd-item {
    font-size: 14px;
    text-align: start;
    text-transform: uppercase;
    justify-self: start;
    width: 10%;
    margin: 0;
  }
  .name-item {
    font-size: 14px;
    text-transform: uppercase;
    text-align: start;
    justify-self: start;
    width: 70%;
  }
  .price-item {
    font-size: 12px !important;
    text-transform: uppercase;
    text-align: start;
    justify-self: start;
    width: 30%;
    font-weight: bold;
  }
`;

export const SubTitleOrder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  padding-bottom: 3px;

  h3 {
    font-size: 12px;
    margin-left: 10px;
    text-align: start;
    width: 80%;
  }
  h4 {
    font-size: 12px;
    text-align: end;
    width: 20%;
  }
`;

export const PaymentStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  border: 1px dotted black;
  margin: 5px;
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .row-total {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 1px dotted black;
    border-bottom: 1px dotted black;
  }
`;

export const PaymentDescription = styled.div`
  width: 100%;
`;
