import React from 'react';

import { TitleComponent } from './styles';

interface TitleProps {
  title: string;
}

const Title: React.FC<TitleProps> = ({ title }) => {
  return <TitleComponent>{title}</TitleComponent>;
};

export default Title;
