import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  width: 100%;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #fff;

  .name {
    display: flex;
    align-items: center;
    flex: 1;

    h4 {
      font-size: 16px;
      font-weight: 300;
    }
    strong {
      background: lightblue;
      font-size: 12px;
      border-radius: 3px;
      margin-left: 10px;
      padding: 5px;
      font-weight: 500;
    }
  }
  .price {
    width: 10%;
    margin-right: 10%;
    text-align: center;

    h3 {
      font-size: 14px;
      font-weight: 400;
    }
  }
  .buttons {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 270px;
  }
`;

export const EditBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4e73df;
  border-radius: 3px;
  border: none;
  padding: 5px 16px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  margin-left: 3px;

  &:hover {
    background: ${shade(0.2, '#4e73df')};
  }
`;

export const StopBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #999;
  border: 0;
  border-radius: 3px;
  padding: 5px 6px;
  color: #fff;
  font-weight: 200;
  transition: background-color 0.2s;
  font-size: 14px;
  svg {
    color: #fff;
    margin: 0 3px;
  }

  &:hover {
    background: ${shade(0.2, '#999')};
  }
`;

export const RemoveBtn = styled.button`
  border-radius: 3px;
  padding: 5px 6px;
  margin-left: 3px;
  background: #fff;
  border: 1px solid #ccc;
  svg {
    color: #c53030;
  }
`;

export const ImageInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .add-photo {
    margin-left: 7.5px;
    width: 85px;
    label {
      margin-left: 23.5px;
      border: 1px solid #eee;
      width: 50px;
      height: 50px;
      border-radius: 3px;
      svg {
        margin: 0 auto;
      }
    }
  }
  .edit-dell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      color: #4e73df;
      margin: 3px;
      &:hover {
        cursor: pointer;
        background: #4e73df;
        color: #fff;
      }
    }
  }

  img {
    margin: 0 10px;
  }

  label {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      margin: 0 5px;
      color: #4e73df;
    }
    &:hover {
      cursor: pointer;
    }
  }
`;
