import styled, { css } from 'styled-components';

export const BackGround = styled.div`
  min-height: calc(100vh - 60px);
  min-width: 700px;
  background: #f8f9fc;
`;

interface IContainerInitial {
  role: 'admin' | 'manager' | 'employee';
}

export const ContainerInitial = styled.div<IContainerInitial>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 60px 0 60px 200px;
  padding: 30px 30px 0 30px;
  overflow: visible;
  @media screen and (max-width: 1000px) {
    overflow-x: visible;
    overflow-y: hidden;

    margin: 60px 0 0 0;
    width: 100%;
    min-width: 700px;
    display: block;
  }
  ${(props) =>
    props.role === 'employee' &&
    css`
      margin: 60px 0 60px 0;
    `}
`;
