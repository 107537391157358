import styled, { css } from 'styled-components';

interface IContainerProps {
  isOpen:
    | 'dashboard'
    | 'order'
    | 'addItem'
    | 'profile'
    | 'clients'
    | 'notify'
    | 'vouchers'
    | 'rates'
    | 'accounts'
    | 'tutorials'
    | 'configuration';
  isManager: boolean;
  showBar: boolean;
  isEmployee: boolean;
}

const isOpenSideBar = {
  dashboard: css`
    .dashboard {
      border-left: 2px solid #ddd;
      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  order: css`
    .order {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  addItem: css`
    .addItem {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  profile: css`
    .profile {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  clients: css`
    .clients {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  notify: css`
    .notify {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  accounts: css`
    .accounts {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  rates: css`
    .rates {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  vouchers: css`
    .vouchers {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  tutorials: css`
    .tutorials {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
  configuration: css`
    .configuration {
      border-left: 2px solid #ddd;

      h1 {
        color: #fff;
      }
      svg {
        color: #fff;
      }
    }
  `,
};

export const Container = styled.div<IContainerProps>`
  min-height: 100vh;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #4e73df;
  background-image: linear-gradient(180deg, #4e73df 10%, #224abe 100%);
  margin-top: 40px;
  padding: 30px 0 0 10px;
  z-index: 1000;
  button {
    display: none;
  }
  a {
    text-decoration: none;
  }
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: inherit;
    transition: background 0.2s, color 0.3s;
    padding: 15px 10px;
    svg {
      color: rgba(255, 255, 255, 0.3);
    }
    h1 {
      margin-left: 10px;
      color: rgba(255, 255, 255, 0.8);
      font-size: 14px;
      font-weight: 200;
    }
    &:hover {
      color: #fff;
      cursor: pointer;
      svg {
        color: #fff;
      }
    }
  }

  ${(props) =>
    props.isManager &&
    css`
      .accounts {
        display: none;
      }
      .dashboard {
        display: none;
      }
    `}
  ${(props) =>
    isOpenSideBar[props.isOpen]}

  @media screen and (max-width: 1000px) {
    left: -195px;
    transition: left 1s;
    min-height: 100vh;
  }
  ${(props) =>
    props.showBar &&
    css`
      @media screen and (max-width: 1000px) {
        position: fixed;
        left: 0;
      }
    `}
  ${(props) =>
    props.isEmployee &&
    css`
      display: none;
    `}
`;

interface IRetractBtn {
  isEmployee: boolean;
}

export const RetractBtn = styled.button<IRetractBtn>`
  position: fixed;
  top: 10vh;
  width: 0;
  height: 0;
  background: inherit;
  border-top: 15px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: none;

  border-left: 20px solid #4e73df;
  @media screen and (min-width: 1000px) {
    display: none;
  }
  ${(props) =>
    props.isEmployee &&
    css`
      display: none;
    `}
`;
