import React from 'react';
import {
  FaCartArrowDown,
  FaMotorcycle,
  FaUtensils,
  FaDollarSign,
  FaTag,
  FaWindowClose,
} from 'react-icons/fa';

import { Container } from './styles';

interface ICardDashboardProps {
  icon: 'cart' | 'motor' | 'utensils' | 'dollar' | 'tag' | 'close';
  title: string;
  value: string;
  colorLine: string;
}

const icons = {
  cart: <FaCartArrowDown size={20} />,
  motor: <FaMotorcycle size={20} />,
  utensils: <FaUtensils size={20} />,
  dollar: <FaDollarSign size={20} />,
  tag: <FaTag size={20} />,
  close: <FaWindowClose size={20} />,
};

const CardDashboard: React.FC<ICardDashboardProps> = ({
  title,
  value,
  icon,
  colorLine,
}) => {
  return (
    <Container>
      <i>{icons[icon]}</i>
      <h1>{value}</h1>
      <p>{title}</p>
      <div className={colorLine} />
    </Container>
  );
};

export default CardDashboard;
