import React, { useState } from 'react';
import { FaDoorOpen } from 'react-icons/fa';

import { Link } from 'react-router-dom';
import Logo from '../../assets/logospots.png';
import { Container, EmergencyBtn, AlertPause, LeaveBtn } from './styles';
import Title from '../Title';
import UnitManagement from '../UnitManagement';
import ContainerOpacity from '../ContainerOpacity';
import { useUnit } from '../../hooks/unit';
import { useBusiness } from '../../hooks/business';
import { useAuth } from '../../hooks/auth';

const Header: React.FC = () => {
  // State
  const [showAlert, setShowAlert] = useState<boolean>(false);

  // Hooks
  const { signOut } = useAuth();
  const { editUnit } = useUnit();
  const { currentUnit, role } = useBusiness();
  const [isActive, setIsActive] = useState<boolean>(currentUnit.isActive);
  return (
    <Container>
      <img src={Logo} alt={Logo} />
      <UnitManagement />
      {role === 'employee' ? (
        <Link
          to="/"
          onClick={() => {
            signOut();
          }}
        >
          <LeaveBtn type="button">
            Sair <FaDoorOpen size={15} />
          </LeaveBtn>
        </Link>
      ) : (
        <EmergencyBtn
          type="button"
          isActive={isActive}
          onClick={async () => {
            if (isActive) {
              setShowAlert(true);
            } else {
              await editUnit({
                generalData: { isActive: true },
              });
              setIsActive(true);
            }
          }}
        >
          <div className="external">
            <div className="circle">
              <p>{isActive ? 'On' : 'Off'}</p>
            </div>
          </div>
        </EmergencyBtn>
      )}

      {showAlert ? (
        <>
          <AlertPause>
            <Title title="Pausar estabelecimento" />
            <div className="padding-container">
              <h1>
                Deseja pausar as vendas de seu estabelecimento? Para retormar as
                vendas será necessário ativar o botão novamente!
              </h1>
              <div className="btn-container">
                <button
                  style={{ background: 'red' }}
                  type="button"
                  onClick={() => {
                    setShowAlert(false);
                  }}
                >
                  Recusar
                </button>
                <button
                  style={{ background: 'green' }}
                  type="button"
                  onClick={async () => {
                    await editUnit({
                      generalData: { isActive: false },
                    });
                    setIsActive(false);
                    setShowAlert(false);
                  }}
                >
                  Aceitar
                </button>
              </div>
            </div>
          </AlertPause>
          <ContainerOpacity
            onClick={() => {
              setShowAlert(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default Header;
