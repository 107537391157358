import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  bottom: 30px;
  left: 25%;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 300px;
  padding: 10px;
  z-index: 100;
  span {
    text-align: center;
    margin: 3px 0;
    background: #f58a07;
    padding: 7px;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
  }
`;

interface ButtonsProps {
  timer: number;
}

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const Radio = styled.div<ButtonsProps>`
  margin-top: 10px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #eee;
    border: 1px solid #eee;
    padding: 20px;
    .choose {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 12px;
        font-weight: 700;
        color: #333;
      }
      p {
        font-size: 12px;
        color: #333;
      }
    }
    .required {
      span {
        padding: 5px;
        background: #111;
        color: #fff;
        border-radius: 3px;
        font-weight: 700;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    h1 {
      font-size: 12px;
    }
    .circle-large {
      width: 17px;
      height: 17px;
      position: relative;
      border-radius: 50%;
      background: #fff;
      border: 1px solid #ccc;
      text-align: center;
      .circle {
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        position: relative;
        border-radius: 50%;
        background: #fff;
        animation: ${appear} 1s;
        background: blue;
      }
    }
  }
`;

export const MultipleChoice = styled.div<ButtonsProps>`
  margin-top: 10px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #eee;
    border: 1px solid #eee;
    padding: 20px;
    .choose {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 12px;
        font-weight: 700;
        color: #333;
      }
      p {
        font-size: 12px;
        color: #333;
      }
    }
    .required {
      span {
        padding: 5px;
        background: #111;
        color: #fff;
        border-radius: 3px;
        font-weight: 700;
        animation: ${appear} 1s;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    h1 {
      font-size: 12px;
    }
    .check {
      height: 20px;
      width: 20px;
      background: #fff;
      border: 1px solid #ccc;
      text-align: center;
      svg {
        color: blue;
        display: block;
      }
    }
  }

  ${(props) =>
    props.timer % 2 &&
    css`
      svg {
        display: none;
      }
    `}
`;

export const Stackable = styled.div<ButtonsProps>`
  margin-top: 10px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #eee;
    border: 1px solid #eee;
    padding: 20px;
    .choose {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 12px;
        font-weight: 700;
        color: #333;
      }
      p {
        font-size: 12px;
        color: #333;
      }
    }
    .required {
      span {
        padding: 5px;
        background: #111;
        color: #fff;
        border-radius: 3px;
        font-weight: 700;
        animation: ${appear} 1s;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #eee;
    h1 {
      font-size: 12px;
    }
    .stack {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .plus {
        color: green;
      }
    }
  }

  ${(props) =>
    props.timer % 2 &&
    css`
      div {
        .item {
          .plus {
            color: red;
          }
        }
      }
    `}
`;
