import React, { useState, useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';

import { Container, ContainerOpacity } from './styles';
import OrderInfo from '../../OrderInfo';
import { IOrder } from '../../../../entities/Order';
import { useBusiness } from '../../../../hooks/business';
import { useSocketHook } from '../../../../hooks/socket';

interface IProps {
  index: number;
  order: IOrder;
}

const Demand: React.FC<IProps> = ({ index, order }) => {
  const [showOrderinfo, setShowOrderInfo] = useState(false);

  const { currentUnit } = useBusiness();
  const { stopBeep } = useSocketHook();

  const handleShowOrderInfo = useCallback(() => {
    setShowOrderInfo(!showOrderinfo);
  }, [showOrderinfo]);

  const HandleHideModal = useCallback(() => {
    setShowOrderInfo(false);
  }, []);

  const handleTotalPrice = useCallback(
    (voucherDiscount, subTotal, deliveryFee, isPercentage) => {
      if (voucherDiscount) {
        if (!isPercentage) {
          return `R$${(subTotal + deliveryFee - voucherDiscount)
            .toFixed(2)
            .replace('.', ',')}`;
        }
        return `R$${(
          subTotal +
          deliveryFee -
          ((subTotal * voucherDiscount) / 100 || 0)
        )
          .toFixed(2)
          .replace('.', ',')}`;
      }
      return `R$${(subTotal + deliveryFee).toFixed(2).replace('.', ',')}`;
    },
    [],
  );

  const getLastOrderStatus = useCallback(
    (orderStatus: {
      received: Date;
      confirmed?: Date;
      ready?: Date;
      finished?: Date;
      cancelled?: Date;
    }): {
      status: string;
      translation: string;
      color: string;
    } => {
      if (orderStatus.cancelled) {
        return {
          status: 'cancelled',
          translation: 'Cancelado',
          color: 'red',
        };
      }

      if (orderStatus.finished) {
        return {
          status: 'finished',
          translation: 'Finalizado',
          color: 'lightblue',
        };
      }

      if (orderStatus.ready) {
        return {
          status: 'ready',
          translation: 'Pronto',
          color: 'orange',
        };
      }

      if (orderStatus.confirmed) {
        return {
          status: 'confirmed',
          translation: 'Confirmado',
          color: 'lightgreen',
        };
      }

      if (orderStatus.received) {
        return {
          status: 'received',
          translation: 'Recebido',
          color: 'yellow',
        };
      }

      return {
        status: 'finished',
        translation: 'erro',
        color: 'lightblue',
      };
    },
    [],
  );

  return (
    <>
      <Container isColored={!(index % 2)}>
        <tr className="tr-body">
          <td className="td-body details">
            <button
              type="button"
              onClick={() => {
                handleShowOrderInfo();
                stopBeep();
              }}
            >
              #{order.orderNumber.toString()}
            </button>
          </td>
          <td className="td-body canal">
            {order.isDelivery ? 'Entrega' : 'Retirada'}
          </td>
          <td className="td-header status">
            <span
              style={{
                background: getLastOrderStatus(order.status).color,
                color: 'black',
              }}
            >
              {getLastOrderStatus(order.status).translation}
            </span>
          </td>
          <td className="td-body">
            {`${format(
              new Date(order.status.received),
              `d'/'M'/'Y 'às' H':'mm`,
              {
                locale: ptLocale,
              },
            )}`}
          </td>
          <td className="td-body client">
            {order.client.nickname
              ? order.client.nickname
              : currentUnit.clients.find(
                  (client) => client.id === (order.client as unknown),
                )?.nickname}
          </td>
          <td className="td-body cellphone">
            {order.client.phone
              ? order.client.phone
              : currentUnit.clients.find(
                  (client) => client.id === (order.client as unknown),
                )?.phone}
          </td>
          <td className="td-body payment">
            {`${
              order.paymentMethod?.type !== 'Dinheiro'
                ? `${order.paymentMethod?.cardBrand} - `
                : ''
            }${order.paymentMethod?.type}`}
          </td>
          <td className="td-body price">
            {handleTotalPrice(
              order.voucher?.discount,
              order.subTotalPrice,
              order.deliveryFee,
              order.voucher?.isPercentage,
            )}
          </td>
          <td className="td-body voucher">{order.voucher?.title}</td>
          <td className="td-body details">
            <button
              type="button"
              onClick={() => {
                handleShowOrderInfo();
                stopBeep();
              }}
            >
              Detalhes
            </button>
          </td>
        </tr>
      </Container>
      {showOrderinfo && (
        <>
          <OrderInfo order={order} HandleHideModal={handleShowOrderInfo} />
          <ContainerOpacity onClick={HandleHideModal} />
        </>
      )}
    </>
  );
};

export default Demand;
