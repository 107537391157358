import React from 'react';

import { Scope } from '@unform/core';
import AmericanExpress from '../../../../assets/AmericanExpress.png';
import Banricompras from '../../../../assets/Banricompras.png';
import Diners from '../../../../assets/Diners.png';
import Elo from '../../../../assets/Elo.png';
import Goodcard from '../../../../assets/Goodcard.png';
import HiperCard from '../../../../assets/Hipercard.png';
import MasterCard from '../../../../assets/Mastercard.png';
import Dinheiro from '../../../../assets/Dinheiro.png';
import Visa from '../../../../assets/Visa.png';
import Verdecard from '../../../../assets/Verdecard.png';
import Nugo from '../../../../assets/Nugo.png';
import Sodexo from '../../../../assets/Sodexo.png';
import VR from '../../../../assets/VR.png';
import Ticket from '../../../../assets/Ticket.png';
import Alelo from '../../../../assets/Alelo.png';

import { Container, Grid, Content } from './styles';
import TextInput from '../../../../components/inputs/TextInput';
import Title from '../../../../components/Title';

interface IPaymentMethodsData {
  label: string;
  value: boolean;
  cardBrand:
    | 'Visa'
    | 'MasterCard'
    | 'Elo'
    | 'AmericanExpress'
    | 'HiperCard'
    | 'Banricompras'
    | 'Diners'
    | 'Goodcard'
    | 'Verdecard'
    | 'Nugo'
    | 'Sodexo'
    | 'Alelo'
    | 'VR'
    | 'Ticket'
    | 'Dinheiro';
}

interface IProps {
  paymentMethods: IPaymentMethodsData[];
  onHandleBagaman(bagaIndex: number): void;
}

const PaymentMethods: React.FC<IProps> = ({
  paymentMethods,
  onHandleBagaman,
}) => {
  const brands = {
    Dinheiro: <img src={Dinheiro} alt={Dinheiro} />,
    Verdecard: <img src={Verdecard} alt={Verdecard} />,
    Nugo: <img src={Nugo} alt={Nugo} />,
    Visa: <img src={Visa} alt={Visa} />,
    MasterCard: <img src={MasterCard} alt={MasterCard} />,
    Elo: <img src={Elo} alt={Elo} />,
    AmericanExpress: <img src={AmericanExpress} alt={AmericanExpress} />,
    HiperCard: <img src={HiperCard} alt={HiperCard} />,
    Banricompras: <img src={Banricompras} alt={Banricompras} />,
    Diners: <img src={Diners} alt={Diners} />,
    Goodcard: <img src={Goodcard} alt={Goodcard} />,
    Sodexo: <img src={Sodexo} alt={Sodexo} />,
    Alelo: <img src={Alelo} alt={Alelo} />,
    VR: <img src={VR} alt={VR} />,
    Ticket: <img src={Ticket} alt={Ticket} />,
  };

  return (
    <Scope path="paymentMethods">
      <Content>
        <Title title="Formas de pagamento" />
        <Grid>
          {paymentMethods.map((paymentMethod, bagaIndex) => {
            return (
              <Container key={paymentMethod.label}>
                {brands[paymentMethod.cardBrand]}
                <label htmlFor={paymentMethod.label} key={paymentMethod.label}>
                  <p>
                    {paymentMethod.label === 'Dinheiro-Dinheiro'
                      ? paymentMethod.cardBrand
                      : paymentMethod.label}
                  </p>
                  <TextInput
                    checked={paymentMethod.value}
                    type="checkbox"
                    value={paymentMethod.value.toString()}
                    name={paymentMethod.label}
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    onChange={(_) => {
                      onHandleBagaman(bagaIndex);
                    }}
                  />
                </label>
              </Container>
            );
          })}
        </Grid>
      </Content>
    </Scope>
  );
};

export default PaymentMethods;
