/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';

import { Scope } from '@unform/core';
import Title from '../../../../components/Title';

import HoursPicker from './components/HoursPicker';

import { Container, Days, Day, OpenButton, CloseButton } from './styles';
import TextInput from '../../../../components/inputs/TextInput';
import { IWorkingHours } from '../../../../entities/WorkingHours';

interface IProps {
  workingHours: IWorkingHours;
}

const WorkingHoursForm: React.FC<IProps> = ({ workingHours }) => {
  const [weekAvailability, setWeekAvailability] = useState({
    sunday: workingHours.sunday.length > 0,
    monday: workingHours.monday.length > 0,
    tuesday: workingHours.tuesday.length > 0,
    wednesday: workingHours.wednesday.length > 0,
    thursday: workingHours.thursday.length > 0,
    friday: workingHours.friday.length > 0,
    saturday: workingHours.saturday.length > 0,
  });

  const weekdays = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sábado',
  ];
  return (
    <Container>
      <Title title="Horários de Funcionamento" />
      <Days>
        <Scope path="workingHours">
          {Object.keys(weekAvailability).map((key, index) => (
            <Day>
              <div>
                <h1>{weekdays[index]}</h1>
                <OpenButton
                  type="button"
                  onClick={() => {
                    setWeekAvailability({ ...weekAvailability, [key]: true });
                  }}
                  isOpen={
                    weekAvailability[
                      key as
                        | 'sunday'
                        | 'monday'
                        | 'tuesday'
                        | 'wednesday'
                        | 'thursday'
                        | 'friday'
                        | 'saturday'
                    ]
                  }
                >
                  Aberto
                </OpenButton>
                <CloseButton
                  type="button"
                  onClick={() => {
                    setWeekAvailability({ ...weekAvailability, [key]: false });
                  }}
                  isOpen={
                    weekAvailability[
                      key as
                        | 'sunday'
                        | 'monday'
                        | 'tuesday'
                        | 'wednesday'
                        | 'thursday'
                        | 'friday'
                        | 'saturday'
                    ]
                  }
                >
                  Fechado
                </CloseButton>
              </div>
              {weekAvailability[
                key as
                  | 'sunday'
                  | 'monday'
                  | 'tuesday'
                  | 'wednesday'
                  | 'thursday'
                  | 'friday'
                  | 'saturday'
              ] ? (
                <HoursPicker
                  weekday={key}
                  times={
                    workingHours[
                      key as
                        | 'sunday'
                        | 'monday'
                        | 'tuesday'
                        | 'wednesday'
                        | 'thursday'
                        | 'friday'
                        | 'saturday'
                    ]
                  }
                />
              ) : (
                <div className="input-day-div">
                  <TextInput type="hidden" name={key} value={[]} />
                </div>
              )}
            </Day>
          ))}
        </Scope>
      </Days>
    </Container>
  );
};

export default WorkingHoursForm;
