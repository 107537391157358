import React from 'react';
import Chart from 'react-google-charts';

import Title from '../../../../components/Title';

import { Container } from './styles';
import Loader from '../../../../components/Loader';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  weekData: any;
}

const WeekChart: React.FC<Props> = ({ weekData }) => {
  return (
    <Container>
      <Title title="Pedidos por dia da semana" />
      <Chart
        width="100%"
        height="45vh"
        chartType="PieChart"
        loader={<Loader />}
        data={weekData}
      />
    </Container>
  );
};

export default WeekChart;
