/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, InputHTMLAttributes } from 'react';
import { useField } from '@unform/core';

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
}

const CheckboxInput: React.FC<IProps> = ({ name, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
    });
  }, [fieldName, registerField]);
  return (
    <div>
      <input ref={inputRef} type="checkbox" {...rest} />
    </div>
  );
};
export default CheckboxInput;
