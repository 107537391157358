import React from 'react';

import { Container, PauseBtn } from './styles';
import { IAccount } from '../../../../entities/Account';
import { useBusiness } from '../../../../hooks/business';

interface IProps {
  index: number;
  account: IAccount;
}

const AccountStatus: React.FC<IProps> = ({ index, account }) => {
  // Hooks
  const { updateAccount, deleteAccount, business } = useBusiness();

  return (
    <>
      {account.username !== 'admin' ? (
        <Container isColored={!(index % 2)}>
          <tr className="tr-body">
            <td className="td-body username">{account.username}</td>
            <td className="td-body role">
              {account.role === 'employee' ? 'Caixa' : 'Gerente'}
            </td>
            <td className="td-body unit">
              {business.units.find((unit) => unit.id === account.unit)?.name}
            </td>
            <td className="td-body active">
              <div>
                <PauseBtn
                  isActive={account.isActive}
                  onClick={async () => {
                    await updateAccount(!account.isActive, account.username);
                  }}
                >
                  <div className="circle">
                    <p>{account.isActive ? 'On' : 'Off'}</p>
                  </div>
                </PauseBtn>
              </div>
            </td>
            <td className="td-body remove">
              <button
                type="button"
                onClick={async () => {
                  await deleteAccount(account.username);
                }}
              >
                remover
              </button>
            </td>
          </tr>
        </Container>
      ) : null}
    </>
  );
};

export default AccountStatus;
