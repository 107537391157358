import React, { useEffect, useRef, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';

import { Form } from '@unform/web';
import { Container, Content, Row } from './styles';
import Title from '../../../../components/Title';
import TextInput from '../../../../components/inputs/TextInput';
import { ICreateCategory } from '../../../../dtos/CreateCategory';
import SpotsButton from '../../../../components/buttons/SpotsButton';
import { useUnit } from '../../../../hooks/unit';
import { ICategory } from '../../../../entities/Category';

interface IProps {
  initialData?: ICategory;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryForm: React.FC<IProps> = ({ initialData, setShowForm }) => {
  // State
  const formRef = useRef<FormHandles>(null);
  const [busy, setBusy] = useState(false);

  // Hooks
  const { addCategory, editCategory } = useUnit();

  useEffect(() => {
    if (initialData) {
      formRef.current?.setData(initialData);
    } else {
      formRef.current?.setData({ priority: 0 });
    }
  }, [initialData, formRef]);

  const handleSubmit = useCallback(
    async (data: ICreateCategory) => {
      if (initialData) {
        await editCategory({
          isActive: true,
          name: data.name,
          priority: data.priority,
          categoryId: initialData.id,
        });
        setShowForm(false);
        return;
      }
      await addCategory(data);
      setShowForm(false);
    },
    [addCategory, editCategory, initialData, setShowForm],
  );

  return (
    <Container>
      <Content>
        <Title title="Adicionar uma nova categoria" />

        <Form
          ref={formRef}
          onSubmit={async (data) => {
            setBusy(true);
            await handleSubmit(data);
            setBusy(false);
          }}
        >
          <Row>
            <div>
              <TextInput
                placeholder="Nome da Categoria"
                name="name"
                type="text"
              />
              <h4>
                Adicione um nome para a sua categoria<span>*</span>
              </h4>
            </div>
            <div>
              <TextInput name="priority" type="text" />
              <h4>Ordenamento de aparição no cardápio</h4>
            </div>
          </Row>
          <SpotsButton type="submit" disabled={busy}>
            Cadastrar
          </SpotsButton>
        </Form>
      </Content>
    </Container>
  );
};

export default CategoryForm;
