import React, { createContext, useCallback, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../services/api';
import { useToast } from './toast';

interface ISignInCredentials {
  email: string;
  username: string;
  password: string;
}

interface ISignUpCredentials {
  invitation: string;
  email: string;
  password: string;
}

interface IAuthContextData {
  token?: string;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signUp(credentials: ISignUpCredentials): Promise<void>;
  signOut(): void;
}

interface IResponse {
  token: string;
}

const AuthContext = createContext({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  // State
  const [token, setToken] = useState<string | undefined>(() => {
    return localStorage.getItem('@SpotsDelivery:token') || undefined;
  });

  // Hooks
  const { addToast } = useToast();
  const { push } = useHistory();

  // Business Logic
  const signIn = useCallback(
    async (credentials: ISignInCredentials) => {
      try {
        const response = await api.post<IResponse>('sessions', {
          as: 'business',
          credentials,
        });

        localStorage.setItem('@SpotsDelivery:token', response.data.token);
        setToken(response.data.token);
      } catch (error) {
        console.log(error.response);
        addToast({
          title: 'Falha ao fazer login',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        throw new Error('baga');
      }
    },
    [addToast],
  );

  const signUp = useCallback(
    async (credentials: ISignUpCredentials) => {
      try {
        const response = await api.post('businesses', {
          invitation: credentials.invitation,
          email: credentials.email,
          username: 'admin',
          password: credentials.password,
          confirmPassword: credentials.password,
        });

        if (response.status === 200) {
          addToast({
            title: 'Sua conta foi criada',
            type: 'success',
          });
          push('/');
        }
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Falha ao criar conta',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, push],
  );

  const signOut = useCallback(() => {
    setToken(undefined);
    localStorage.removeItem('@SpotsDelivery:token');
  }, []);

  return (
    <AuthContext.Provider value={{ token, signIn, signUp, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('use auth must be used within a AuthProvider');
  }

  return context;
}

export { AuthContext, AuthProvider, useAuth };
