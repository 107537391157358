import styled from 'styled-components';

export const ContainerWithOpacity = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  z-index: 2000;
`;

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 150px;
  width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  text-align: center;
  background: rgba(29, 62, 139, 1);
  border-radius: 5px;
  z-index: 3000;
  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    color: #fff;
  }
  button {
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
  }
`;
