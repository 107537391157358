/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { FiMinus, FiPlus, FiCheck } from 'react-icons/fi';

import { Container, Radio, MultipleChoice, Stackable } from './styles';

interface TooltipProps {
  type: number;
}

const TooltipButtons: React.FC<TooltipProps> = ({ type }) => {
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    setInterval(() => {
      setTimer((t) => (t > 2 ? t - 2 : t + 1));
    }, 1000);
  }, []);
  return (
    <Container>
      <span>Exemplo prático</span>
      {type === 0 ? (
        <Radio timer={timer}>
          <div className="header">
            <div className="choose">
              <h1>Escola a borda</h1>
              <p>Selecione 1</p>
            </div>
            <div className="required">
              <span>OBRIGATÓRIO</span>
            </div>
          </div>
          <div className="item">
            <h1>Sem borda</h1>
            <div className="circle-large">
              {timer === 1 ? <div className="circle" /> : null}
            </div>
          </div>
          <div className="item">
            <h1>Borda de catupiry</h1>
            <div className="circle-large">
              {timer === 2 ? <div className="circle" /> : null}
            </div>
          </div>
          <div className="item">
            <h1>Borda de chocolate</h1>
            <div className="circle-large">
              {timer === 3 ? <div className="circle" /> : null}
            </div>
          </div>
        </Radio>
      ) : type === 2 ? (
        <Stackable timer={timer}>
          <div className="header">
            <div className="choose">
              <h1>Escola os sabores</h1>
              <p>Selecione 3 sabores</p>
            </div>
            <div className="required">
              {timer % 2 ? <span>OBRIGATÓRIO</span> : null}
            </div>
          </div>
          <div className="item">
            <h1>Margatira</h1>
            <div className="stack">
              <FiMinus size={15} />
              <h1>0</h1>
              <FiPlus size={15} className="plus" />
            </div>
          </div>
          <div className="item">
            <h1>Peperoni</h1>
            <div className="stack">
              <FiMinus size={15} />
              <h1>{timer > 1 ? 1 : timer}</h1>
              <FiPlus size={15} className="plus" />
            </div>
          </div>
          <div className="item">
            <h1>Brócolis</h1>
            <div className="stack">
              <FiMinus size={15} />
              <h1>{timer === 3 ? 2 : timer}</h1>
              <FiPlus size={15} className="plus" />
            </div>
          </div>
        </Stackable>
      ) : type === 1 ? (
        <MultipleChoice timer={timer}>
          <div className="header">
            <div className="choose">
              <h1>Escola adicionaris</h1>
              <p>Escolha até 3 adicionais</p>
            </div>
            <div className="required">
              {timer % 2 ? <span>OBRIGATÓRIO</span> : null}
            </div>
          </div>
          <div className="item">
            <h1>Dobro de bacon</h1>
            <div className="check">
              {timer === 2 ? <FiCheck size={15} /> : null}
            </div>
          </div>
          <div className="item">
            <h1>Dobro de queijo</h1>
            <div className="check">
              {timer === 2 ? <FiCheck size={15} /> : null}
            </div>
          </div>
          <div className="item">
            <h1>Dobro de molho</h1>
            <div className="check">
              {timer === 3 ? <FiCheck size={15} /> : null}
            </div>
          </div>
        </MultipleChoice>
      ) : null}
    </Container>
  );
};

export default TooltipButtons;
