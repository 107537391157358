import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
`;

export const Section = styled.div`
  width: 49%;
  display: flex;
  flex-direction: column;
  animation: ${appearContainer} 1s;
`;

export const SaveAll = styled.div`
  width: 100%;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  margin-top: 25px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  button {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 3px;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    background: #f58a07;
    svg {
      margin-left: 5px;
      color: #fff;
    }
  }
`;

export const DeleteUnitBtn = styled.button`
  width: 200px;
  padding: 10px 20px;
  font-size: 14px;
  background: #c53030;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-weight: bold;
  color: #fff;
  justify-self: flex-end;
  align-self: flex-end;
  &:hover {
    background: ${shade(0.2, '#c53030')};
  }
`;

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const AlertDeleteUnit = styled.div`
  z-index: 1000;
  position: fixed;
  top: 25%;
  left: 35%;
  width: 40%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appear} 1s;
  .padding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    h1 {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: 16px;
      margin-bottom: 25px;
    }
    div {
      width: 400px;
      input {
        width: 400px;
        margin-right: 10px;
        padding-right: 10px;
      }
      div {
        width: 20px;
        margin: 0;
        padding: 0;
      }
    }
    .btn-container {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      button {
        width: 25%;
        font-size: 14px;
        padding: 5px 0;
        margin: 0 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
`;
