import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1,
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 20vh;
  left: 50%;
  transform: translateX(-41.5%);
  width: 60%;
  background: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  animation: ${appear} 1s;
  z-index: 1000;
`;

export const Content = styled.div`
  padding: 30px;
  form {
    .title-input {
      display: flex;
      flex-direction: column;
      h1 {
        align-self: flex-start;
        font-size: 12px;
        font-weight: 200;
        margin-bottom: 10px;
      }
    }
    button {
      width: 150px;
      border-radius: 3px;
      border: none;
      padding: 10px 16px;
      font-weight: 500;
      transition: background-color 0.2s;
      font-size: 14px;
      margin-right: 20px;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-bottom: 50px;
  .title {
    flex: 2;
    margin-right: 50px;
  }
  .date {
    flex: 1;
    margin-right: 25px;
  }
  .isPercentage {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    h1 {
      margin-right: 5px;
    }
  }
  .discount {
    flex: 1;
  }
  .minPrice {
    flex: 1;
    margin: 0 60px;
  }
  .maxUses {
    flex: 1;
  }
  .description {
    flex: 1;
    margin-right: 25px;
  }
`;

export const Button = styled.button`
  padding: 10px 25px;
  background: #f58a07;
  border: none;
  border-radius: 3px;
  color: #fff;
`;
