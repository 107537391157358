import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useAuth } from './auth';
import { IBusiness } from '../entities/Business';
import api from '../services/api';
import { useToast } from './toast';
import { IUnit } from '../entities/Unit';
import { ICreateAccount } from '../dtos/CreateAccount';
import { IAccount } from '../entities/Account';
import { IChangePassword } from '../dtos/ChangePassword';

interface IBusinessContextData {
  business: IBusiness;
  role: Role;
  activeUnit: string | undefined;
  selectUnit(id: string): void;
  getBusiness(): Promise<IResponse | undefined>;
  createUnit(name: string): Promise<void>;
  deleteUnit(id: string): Promise<void>;
  currentUnit: IUnit;
  setCurrentUnit: React.Dispatch<React.SetStateAction<IUnit>>;
  ChangePassword(data: IChangePassword): Promise<boolean>;
  createAccount(data: ICreateAccount): Promise<IAccount | undefined>;
  updateAccount(data: boolean, username: string): Promise<IAccount | undefined>;
  deleteAccount(accountId: string): Promise<void>;
}

type Role = 'admin' | 'manager' | 'employee';

interface IResponse {
  business: IBusiness;
  role: Role;
}

const BusinessContext = createContext({} as IBusinessContextData);

const BusinessProvider: React.FC = ({ children }) => {
  // State
  const [business, setBusiness] = useState({} as IBusiness);
  const [role, setRole] = useState('' as Role);
  const [activeUnit, setActiveUnit] = useState<string>();
  const [currentUnit, setCurrentUnit] = useState<IUnit>({} as IUnit);

  // Hooks
  const { token, signOut } = useAuth();
  const { addToast } = useToast();

  // Business Logic

  // ---------------------------------------------- GET BUSINESS -------------------------------
  const getBusiness = useCallback(async () => {
    if (token) {
      try {
        const response = await api.get<IResponse>('businesses/me', {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        setBusiness(response.data.business);
        setRole(response.data.role);

        return response.data;
      } catch (error) {
        signOut();
        setBusiness({} as IBusiness);
        addToast({
          title: 'Sua sessão expirou',
          description: 'Entre novamente',
          type: 'info',
        });
        return undefined;
      }
    }

    return undefined;
  }, [addToast, signOut, token]);

  // ---------------------------------------------- SELECT UNIT -------------------------------
  const selectUnit = useCallback(
    (id: string) => {
      const unitExists = business.units.find((unit) => unit.id === id);
      if (!unitExists) {
        addToast({
          title: 'Não encontramos essa unidade',
          description: 'Fale com o Salambors imediatamente',
          type: 'error',
        });

        return;
      }
      localStorage.setItem('@SpotsDelivery:unitId', id);
      setActiveUnit(id);
      addToast({
        title: 'Você trocou de unidade',
        type: 'info',
      });
      window.location.reload(false);
    },
    [addToast, business],
  );

  // ---------------------------------------------- CREATE UNIT -------------------------------
  const createUnit = useCallback(
    async (name: string) => {
      try {
        const response = await api.post<IUnit>(
          'units',
          { name },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );

        setBusiness((state) => {
          const { units } = state;
          units.push(response.data);
          return state;
        });
        selectUnit(response.data.id);
        addToast({
          title: 'Você adicionou uma nova unidade',
          type: 'success',
        });
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível criar a unidade',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, selectUnit, token],
  );

  // ---------------------------------------------- DELET UNIT -------------------------------
  const deleteUnit = useCallback(
    async (id: string) => {
      const index = business.units.findIndex((unit) => unit.id === id);
      if (index < 0) {
        addToast({
          title: 'Não encontramos essa unidade',
          description: 'Fale com o Kow imediatamente',
          type: 'error',
        });

        return;
      }

      try {
        await api.delete(`units/${id}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setBusiness((state) => {
          state.units.splice(index, 1);
          return state;
        });

        selectUnit(business.units[0].id);

        addToast({
          title: 'Unidade deletada',
          type: 'success',
        });
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível deletar sua unidade',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, business.units, selectUnit, token],
  );

  const ChangePassword = useCallback(
    async (data: IChangePassword) => {
      try {
        await api.put<boolean>('businesses/me', data, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        addToast({
          title: 'Senha alterada com sucesso',
          type: 'success',
        });
        signOut();
        return true;
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível alterar essa senha',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return false;
      }
    },
    [addToast, signOut, token],
  );

  //= ===================================================== Account ===================================================
  // --------------------------------------------------- Create Account ----------------------
  const createAccount = useCallback(
    async (data: ICreateAccount) => {
      try {
        const response = await api.post<IAccount>(
          'businesses/me/accounts',
          data,
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
        console.log('doidao');
        setBusiness((state) => {
          business.accounts.push(response.data);
          return state;
        });
        addToast({
          title: 'Nova conta de usuário adicionada',
          type: 'success',
        });
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível criar essa conta',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return undefined;
      }
    },
    [addToast, business.accounts, token],
  );

  // --------------------------------------------------- Update Account ----------------------
  const updateAccount = useCallback(
    async (data: boolean, username: string) => {
      try {
        const response = await api.put<IAccount>(
          `businesses/me/accounts/${username}`,
          {
            isActive: data,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          },
        );
        setBusiness((state) => {
          const index = business.accounts.findIndex(
            (account) => account.username === username,
          );
          business.accounts[index] = response.data;
          return state;
        });
        addToast({
          title: 'Usuário atualizado',
          type: 'success',
        });
        return response.data;
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível atualizar este usuário',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
        return undefined;
      }
    },
    [addToast, business.accounts, token],
  );

  // --------------------------------------------------- Delete Account ----------------------
  const deleteAccount = useCallback(
    async (username: string) => {
      try {
        await api.delete(`businesses/me/accounts/${username}`, {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        setBusiness((state) => {
          const index = business.accounts.findIndex(
            (account) => account.username === username,
          );
          business.accounts.splice(index, 1);
          return state;
        });
        addToast({
          title: 'Usuário deletado',
          type: 'success',
        });
      } catch (error) {
        console.log(error.response.data);
        addToast({
          title: 'Não foi possível deletar esta conta de usuário',
          description: 'Tente novamente ou entre em contato',
          type: 'error',
        });
      }
    },
    [addToast, business.accounts, token],
  );

  // ---------------------------------------------- CURRENT UNIT -------------------------------
  useEffect(() => {
    if (business.units && business.units.length > 0) {
      const storageId = localStorage.getItem('@SpotsDelivery:unitId');
      if (storageId) {
        const unit = business.units.find((tmpUnit) => tmpUnit.id === storageId);

        if (!unit) {
          localStorage.setItem('@SpotsDelivery:unitId', business.units[0].id);
          setCurrentUnit(business.units[0]);
        } else {
          localStorage.setItem('@SpotsDelivery:unitId', unit.id);
          setCurrentUnit(unit);
        }
      } else {
        localStorage.setItem('@SpotsDelivery:unitId', business.units[0].id);
        setCurrentUnit(business.units[0]);
      }
    }
  }, [business.units, activeUnit, selectUnit]);

  return (
    <BusinessContext.Provider
      value={{
        activeUnit,
        setCurrentUnit,
        currentUnit,
        business,
        role,
        selectUnit,
        getBusiness,
        createUnit,
        deleteUnit,
        ChangePassword,
        createAccount,
        updateAccount,
        deleteAccount,
      }}
    >
      {children}
    </BusinessContext.Provider>
  );
};

function useBusiness(): IBusinessContextData {
  const context = useContext(BusinessContext);

  if (!context) {
    throw new Error('use business must be used within a BusinessProvider');
  }

  return context;
}

export { BusinessContext, BusinessProvider, useBusiness };
