import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  height: 60px;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  img {
    width: 180px;
    margin-left: 25px;
    margin-top: 8px;
  }
`;

interface IEmergencyButtonProps {
  isActive: boolean;
}

export const LeaveBtn = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  border: none;
  padding: 10px 15px;
  font-size: 12px;
  background: #f8f9fc;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 75px;
  svg {
    margin-left: 5px;
  }
`;

export const EmergencyBtn = styled.button<IEmergencyButtonProps>`
  position: relative;
  width: 75px;
  height: 30px;
  border-radius: 30px;
  border: 1px solid #555;
  background: #70bb35;
  margin-right: 50px;
  transition: background 1s;
  .circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    background: #fff;
    border-radius: 50%;
    border: 1px #555 solid;
    transition: all 1s ease;
    display: flex;
    text-align: center;
    justify-content: center;
    transform: translate(-1px, -1px);
    p {
      transform: translateY(25%);
      font-size: 10px;
      color: #bbb;
    }
  }
  ${(props) =>
    !props.isActive &&
    css`
      background: #c53030;
      .circle {
        left: 100%;
        transform: translate(calc(-100% + 1px), -1px);
      }
    `}
  @media screen and (max-width: 1400px) {
    width: 50px;
    height: 25px;
    border-radius: 25px;
    .circle {
      height: 25px;
      width: 25px;
    }
  }
`;

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const AlertPause = styled.div`
  z-index: 1000;
  position: fixed;
  top: 25%;
  left: 35%;
  width: 40%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appear} 1s;
  .padding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    h1 {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: 16px;
    }
    .btn-container {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      button {
        width: 25%;
        font-size: 14px;
        padding: 5px 0;
        margin: 0 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
`;

export const ChooseUnit = styled.div`
  position: relative;
  height: 30px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  h1 {
    font-size: 14px;
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const Units = styled.div`
  position: absolute;
  width: 300px;
  top: 30px;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;

  animation: ${appear} 1s;
  h1 {
    font-size: 14px;
  }
`;
export const Unit = styled.button`
  padding: 10px 0;
  width: 300px;
  border: 1px solid #ddd;
  background: #fff;
  transition: background 0.5s;
  h1 {
    font-size: 12px;
  }
  &:hover {
    background: #ccc;
  }
`;

export const AddUnit = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 300px;
  color: #f58a07;
  background: #fff;
  border: 1px solid #ccc;
`;

export const NewUnit = styled.div`
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 500px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 1000;
  animation: ${appear} 0.5s;
`;

export const ContentNewUnit = styled.div`
  padding: 40px;
  h1 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    background: #f58a07;
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 3px;
    margin-top: 10px;
  }
`;
