import React, { useState } from 'react';

import {
  FiStopCircle,
  FiPlayCircle,
  FiPlusCircle,
  FiEdit,
  FiTrash2,
} from 'react-icons/fi';

import Loader from 'react-spinners/ClipLoader';
import { Container, Title, Button, StopBtn } from './styles';
import CategoryForm from '../CategoryForm';
import ContainerOpacity from '../../../../components/ContainerOpacity';
import { ICategory } from '../../../../entities/Category';
import { useUnit } from '../../../../hooks/unit';
import ItemForm from '../../ItemForm';

interface IProps {
  category: ICategory;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
}

const CategoryTile: React.FC<IProps> = ({
  category,
  children,
  setShowForm,
}) => {
  // State
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [showItemForm, setShowItemForm] = useState(false);
  const [isLoaderTrash, setIsLoaderTrash] = useState(false);

  // Hooks
  const { editCategory, removeCategory, addItem } = useUnit();

  return (
    <Container>
      <Title>
        <h2>{category.name}</h2>
        <Button
          colorHex="#70bb35"
          type="button"
          onClick={() => {
            window.scrollTo(0, 65);
            setShowItemForm(true);
          }}
        >
          <FiPlusCircle />
          Adicionar novo produto
        </Button>
        <StopBtn
          type="button"
          onClick={async () => {
            editCategory({
              categoryId: category.id,
              isActive: !category.isActive,
            });
          }}
        >
          {category.isActive ? <FiStopCircle /> : <FiPlayCircle />}
          {category.isActive ? 'Pausar a venda' : 'Retomar a venda'}
        </StopBtn>
        <Button
          colorHex="#224abe"
          type="button"
          onClick={() => {
            setShowCategoryForm(true);
            window.scrollTo(0, 65);
          }}
        >
          <FiEdit />
          Editar
        </Button>

        <Button
          colorHex="#c53030"
          type="button"
          onClick={async () => {
            setIsLoaderTrash(true);
            await removeCategory(category.id);
            setIsLoaderTrash(false);
          }}
        >
          {isLoaderTrash ? <Loader size={15} /> : <FiTrash2 size={15} />}
        </Button>
      </Title>
      {children}
      {showCategoryForm && (
        <>
          <CategoryForm initialData={category} setShowForm={setShowForm} />
          <ContainerOpacity
            onClick={() => {
              setShowCategoryForm(false);
            }}
          />
        </>
      )}
      {showItemForm && (
        <>
          <ItemForm
            handleSubmit={async (data) => {
              const baga = { ...data };
              baga.price = parseFloat(baga.price.toString().replace(',', '.'));
              baga.subCategories?.forEach((subCategory) => {
                // eslint-disable-next-line array-callback-return
                subCategory.subItems.map((item) => {
                  // eslint-disable-next-line no-param-reassign
                  item.price = parseFloat(
                    item.price.toString().replace(',', '.'),
                  );
                });
              });
              await addItem(
                {
                  isActive: true,
                  description: baga.description,
                  isFinalPrice: baga.isFinalPrice,
                  name: baga.name,
                  price: baga.price,
                  priority: baga.priority ? baga.priority : 0,
                  subCategories: baga.subCategories,
                  tag: baga.tag,
                },
                category.id,
              );

              setShowItemForm(false);
            }}
          />
          <ContainerOpacity
            onClick={() => {
              setShowItemForm(false);
            }}
          />
        </>
      )}
    </Container>
  );
};

export default CategoryTile;
