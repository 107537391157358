/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useCallback, useContext, useState } from 'react';
import UIfx from 'uifx';
import { useSocket } from 'use-socketio';
import { useToast } from './toast';
import { useBusiness } from './business';

interface IProps {
  startBeep(): void;
  stopBeep(): void;
  soundLoaded: boolean;
  toggleSoundLoaded(value: boolean): void;
}

const SocketContext = createContext<IProps>({} as IProps);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const swiftly = require('../assets/beep.mp3');

const SocketProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { currentUnit, setCurrentUnit } = useBusiness();
  const [beeper, setBeeper] = useState<number>();
  const [soundLoaded, setSoundLoaded] = useState(false);

  const bell = new UIfx(swiftly, {
    volume: 1,
    throttleMs: 50,
  });

  const startBeep = useCallback(() => {
    clearInterval(beeper);
    setBeeper(
      setInterval(() => {
        bell.setVolume(1).play();
      }, 500),
    );
  }, [beeper, bell]);

  const stopBeep = useCallback(() => {
    clearInterval(beeper);
  }, [beeper]);

  const toggleSoundLoaded = useCallback((value: boolean): void => {
    bell.setVolume(0).play();
    setSoundLoaded(value);
  }, []);

  useSocket(currentUnit.id, (event) => {
    if (event.type === 'NEW_ORDER') {
      startBeep();
      setCurrentUnit((state) => {
        state.orders = [event.data.order, ...state.orders];
        return state;
      });
      addToast({
        title: 'Novo pedido',
        type: 'info',
        description: 'Você recebeu um novo pedido, confira!',
        duration: 2000,
      });
    }
  });

  return (
    <SocketContext.Provider
      value={{ startBeep, stopBeep, soundLoaded, toggleSoundLoaded }}
    >
      {children}
    </SocketContext.Provider>
  );
};
function useSocketHook(): IProps {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocketHook must be used within a socketProvider');
  }

  return context;
}

export { SocketProvider, useSocketHook };
