import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const ContainerTutorials = styled.div`
  width: 100%;
  padding: 0 0 10px 0px;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
`;

export const Content = styled.div`
  width: 100%;
  min-width: 750px;
  padding: 50px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;

  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 50px;
    grid-column-gap: 50px;
    .video {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      h1 {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 10px;
      }
    }
  }
`;
