import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;
export const Container = styled.div`
  width: 49%;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
`;

export const Content = styled.div`
  padding: 20px 40px;

  h1 {
    text-align: start;
    background-color: inherit;
    border: 0;
    padding: 10px 10px 10px 0;
    font-size: 14px;
    font-weight: 400;
  }
`;

export const LogoInput = styled.div`
  width: 49%;
  .logo-content {
    display: flex;
    flex-direction: column;
    width: 250px;
    border-radius: 3px;
    margin: 20px 0;
    .logo {
      border: 1px solid #ccc;
      img {
        height: 200px;
        width: 200px;
        object-fit: contain;
      }
    }
    p {
      align-self: flex-start;
      padding: 10px 0;
      font-size: 14px;
    }
    .choose-logo {
      display: flex;
      flex-direction: row;
      label {
        margin-top: 15px;
        span {
          padding: 5px;
          background: #ccc;
          border-radius: 3px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export const ColorsInput = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 20px;
  width: 100%;
  margin-bottom: 15px;
  .title-color {
    background: #f1f3f6;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    font-size: 16px;
    font-weight: 100;
    margin-bottom: 15px;
  }
  div {
    padding: 0 15px 15px 5px;
    border: none;
    input {
      width: 100%;
      margin-bottom: 10px;
    }
    h1 {
      text-align: start;
      font-weight: 400;
      font-size: 14px;
      padding: 5px;
    }
  }
`;
