import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(29, 62, 139, 1);
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }

`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 35rem;
  height: 30rem;

  border-radius: 4px;
  background: #fff;

  animation: ${appearFromLeft} 1s;

  img {
    width: 200px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 14px;
    font-weight: 300;
    width: 70%;
    line-height: 20px;
    text-align: center;
    margin-bottom: 15px;
  }
  a {
    color: rgba(29, 62, 139, 1);
    display: block;
    margin-top: 8px;
    text-decoration: none;
    transition: color 0.2s;
    font-size: 14px;
    &:hover {
      color: ${shade(0.2, 'rgba(29, 62, 139, 1)')};
    }
  }
  form {
    width: 340px;
    display: flex;
    flex-direction: column;
  }

  .bottom-btn {
    button {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #f58a07;
      background: inherit;
      color: #f58a07;
      font-size: 14px;
      margin-top: 10px;
    }
  }
`;
