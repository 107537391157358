import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FiPlus } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { uuid } from 'uuidv4';
import getValidationErrors from '../../utils/getValidationErrors';

import {
  AccountContainer,
  Content,
  TableAccounts,
  ContentInfo,
  AccountInfo,
  PasswordInfo,
} from './styles';

import AddAccount from './components/AddAccount';

import Container from '../../components/Container';
import Title from '../../components/Title';
import ContainerOpacity from '../../components/ContainerOpacity';
import AccountStatus from './components/AccountStatus';
import { useBusiness } from '../../hooks/business';
import TextInput from '../../components/inputs/TextInput';
import SpotsButton from '../../components/buttons/SpotsButton';
import { IChangePassword } from '../../dtos/ChangePassword';

const Accounts: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [showAddAccount, setShowAddAccount] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  // Hooks
  const { business, getBusiness, ChangePassword } = useBusiness();

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const handleSubmit = useCallback(
    async (data: IChangePassword) => {
      setBusy(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          oldPassword: Yup.string().required('Senha obrigatória'),
          password: Yup.string()
            .required('Insira a nova senha')
            .min(6, 'Senha com no mínimo 6 dígitos'),
          confirmPassword: Yup.string()
            .required('Confirme sua senha')
            .oneOf([Yup.ref('password')], 'Senhas não coincidem'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        ChangePassword(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },
    [ChangePassword],
  );

  return (
    <Container isOpen="accounts" isLoaded={isLoaded}>
      <AccountInfo>
        <Title title="Informações da conta" />
        <ContentInfo>
          <div className="label">
            <p>E-mail:</p>
            <input name="state" type="text" value={business.email} />
          </div>
          <div className="label">
            <p>Usuário:</p>
            <input name="city" type="text" value="admin" />
          </div>
        </ContentInfo>
        <button
          type="button"
          onClick={() => {
            setChangePassword(!changePassword);
          }}
          style={{ background: changePassword ? '#ccc' : '#fff' }}
        >
          Trocar minha senha
        </button>
        {changePassword ? (
          <PasswordInfo>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Alterar sua senha</h1>
              <div className="label">
                <p>Senha atual:</p>
                <TextInput name="oldPassword" type="password" />
              </div>
              <ContentInfo>
                <div className="label">
                  <p>Nova senha:</p>
                  <TextInput name="password" type="password" />
                </div>
                <div className="label">
                  <p>Repetir senha:</p>
                  <TextInput name="confirmPassword" type="password" />
                </div>
              </ContentInfo>
              <SpotsButton type="submit" disabled={busy}>
                Enviar
              </SpotsButton>
            </Form>
          </PasswordInfo>
        ) : null}
      </AccountInfo>

      <AccountContainer>
        <Title title="Lista de usuários" />
        <Content>
          <button
            type="button"
            onClick={() => {
              setShowAddAccount(true);
            }}
          >
            <FiPlus size={20} /> Adicionar nova conta
          </button>
          <TableAccounts>
            <thead>
              <tr className="tr-header">
                <td className="td-header username">Usuário</td>
                <td className="td-header role">Permissão</td>
                <td className="td-header unit">Unidade</td>
                <td className="td-header active">Ativo</td>
                <td className="td-header remove">Remover</td>
              </tr>
            </thead>
            {business.accounts.map((account, index) => {
              return (
                <>
                  <AccountStatus key={uuid()} index={index} account={account} />
                </>
              );
            })}
          </TableAccounts>
        </Content>
      </AccountContainer>

      {showAddAccount ? (
        <>
          <AddAccount setShowAddAccount={setShowAddAccount} />
          <ContainerOpacity
            onClick={() => {
              setShowAddAccount(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default Accounts;
