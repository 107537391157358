import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FiX } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import * as Yup from 'yup';

import { uuid } from 'uuidv4';
import { IDeliveryConfig } from '../../../../entities/DeliveryConfig';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import TextInput from '../../../../components/inputs/TextInput';
import Title from '../../../../components/Title';

import {
  Container,
  ChooseDelivery,
  ButtonDelivery,
  AreaDelivery,
  DeliveryRadius,
  RangePrice,
  PrepareTime,
} from './styles';
import SpotsButton from '../../../../components/buttons/SpotsButton';
import { useUnit } from '../../../../hooks/unit';

interface IDeliveryFormData {
  radius: number;
  fee: number;
  estimatedTime: number;
}

interface IProps {
  deliveryConfigData: IDeliveryConfig;
  hasDeliveryTemp: boolean | undefined;
  hasTakeAwayTemp: boolean | undefined;
  prepareTimeData?: number;
}

const Delivery: React.FC<IProps> = ({
  deliveryConfigData,
  hasDeliveryTemp,
  hasTakeAwayTemp,
  prepareTimeData,
}) => {
  // Refs
  const formRefDeliveryRadius = useRef<FormHandles>(null);
  const formRefPrepareTime = useRef<FormHandles>(null);

  // State
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderPrepare, setIsLoaderPrepare] = useState(false);

  const colors = ['red', 'blue', 'yellow', 'orange', 'green', 'black'];

  // Hooks
  const { addToast } = useToast();
  const { editUnit } = useUnit();

  const handleSubmit = useCallback(
    async (data: IDeliveryFormData) => {
      setIsLoader(true);
      try {
        if (deliveryConfigData?.rates.length >= 6) {
          alert('É possível adicionar no máximo 6 raios');
          setIsLoader(false);
          return;
        }
        if (deliveryConfigData.area.length < 1) {
          alert('Primeiro é necessario cadastrar uma area de entrega');
          setIsLoader(false);
          return;
        }

        formRefDeliveryRadius.current?.setErrors({});

        const schema = Yup.object().shape({
          radius: Yup.number().required('Distância em metros obrigatória'),
          fee: Yup.number().required('Tempo em minutos obrigatório'),
          estimatedTime: Yup.number().required('preço obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { radius, fee, estimatedTime } = data;

        deliveryConfigData?.rates.push({ radius, fee, estimatedTime });

        await editUnit({
          deliveryData: {
            deliveryRates: deliveryConfigData?.rates,
          },
        });

        addToast({
          title: 'Distância e tempo de entrega atualizados',
          type: 'success',
        });
        setIsLoader(false);
      } catch (err) {
        setIsLoader(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefDeliveryRadius.current?.setErrors(errors);
        }
        // disparar toast
        addToast({
          title: 'Erro ao adicionar distância de entrega',
          description:
            'Tente novamente, ou entre em contato para realizar sua atualização',
          type: 'error',
        });
      }
    },
    [addToast, deliveryConfigData, editUnit],
  );

  const handleRemoveDelivery = useCallback(
    async (index) => {
      if (deliveryConfigData.rates.length === 1) {
        await editUnit({
          deliveryData: { hasDelivery: !hasDeliveryTemp },
        });
      }
      deliveryConfigData?.rates.splice(index, 1);
      await editUnit({
        deliveryData: {
          deliveryRates: deliveryConfigData?.rates,
        },
      });

      window.location.reload(false);
    },
    [deliveryConfigData, editUnit, hasDeliveryTemp],
  );

  const handleSubmitPrepareTime = useCallback(
    async (data: { prepareTime: number }) => {
      setIsLoaderPrepare(true);
      try {
        formRefPrepareTime.current?.setErrors({});
        const schema = Yup.object().shape({
          prepareTime: Yup.number().required('Tempo de preparo obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { prepareTime } = data;

        editUnit({ deliveryData: { prepareTime } });

        addToast({
          title: 'Tempo de preparo atualizado',
          type: 'success',
        });
        setIsLoaderPrepare(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefDeliveryRadius.current?.setErrors(errors);
        }
        // disparar toast
        addToast({
          title: 'Erro ao adicionar tempo de preparo',
          description:
            'Tente novamente, ou entre em contato para realizar sua atualização',
          type: 'error',
        });
      }
    },
    [addToast, editUnit],
  );

  const handlePostHasTakeAway = useCallback(async () => {
    try {
      if (!hasDeliveryTemp && hasTakeAwayTemp) {
        addToast({
          title: 'É necessário manter um selecionado',
          type: 'info',
        });
        return;
      }
      await editUnit({
        deliveryData: { hasTakeaway: !hasTakeAwayTemp },
      });
      addToast({
        title: 'Status de delivery atualizado',
        type: 'success',
      });
    } catch (err) {
      addToast({
        title: 'Erro ao alterar status de delivery',
        description: 'Tente novamente',
        type: 'error',
      });
    }
  }, [editUnit, addToast, hasTakeAwayTemp, hasDeliveryTemp]);

  const handlePostHasDelivery = useCallback(async () => {
    try {
      if (hasDeliveryTemp && !hasTakeAwayTemp) {
        addToast({
          title: 'É necessário manter um selecionado',
          type: 'info',
        });
        return;
      }
      if (deliveryConfigData.rates.length === 0 && !hasDeliveryTemp) {
        addToast({
          title: 'Status de delivery não atualizado',
          description: 'Insira um raio de entrega para selecionar entrega',
          type: 'info',
        });
        return;
      }
      await editUnit({
        deliveryData: { hasDelivery: !hasDeliveryTemp },
      });
      addToast({
        title: 'Status de delivery atualizado',
        type: 'success',
      });
    } catch (err) {
      addToast({
        title: 'Erro ao alterar status de delivery',
        description:
          'Tente novamente, ou entre em contato para realizar sua atualização',
        type: 'error',
      });
    }
  }, [
    hasDeliveryTemp,
    hasTakeAwayTemp,
    deliveryConfigData.rates.length,
    editUnit,
    addToast,
  ]);

  useEffect(() => {
    formRefPrepareTime.current?.setData({
      prepareTime: prepareTimeData,
    });
  }, [prepareTimeData]);

  return (
    <Container>
      <Title title="Configurando delivery" />
      <PrepareTime>
        <Form ref={formRefPrepareTime} onSubmit={handleSubmitPrepareTime}>
          <div className="prepare-input">
            <h1>Tempo médio de preparo (minutos)</h1>
            <div className="input-btn">
              <TextInput type="number" name="prepareTime" />
              <div className="btn-alterar">
                <SpotsButton type="submit" disabled={isLoaderPrepare}>
                  Alterar
                </SpotsButton>
              </div>
            </div>
          </div>
        </Form>
      </PrepareTime>
      <ChooseDelivery>
        <ButtonDelivery
          isClicked={hasDeliveryTemp || false}
          onClick={handlePostHasDelivery}
        >
          <div />
          <h1>Estou fazendo entregas</h1>
        </ButtonDelivery>
        <ButtonDelivery
          isClicked={hasTakeAwayTemp || false}
          onClick={handlePostHasTakeAway}
        >
          <div />
          <h1>Estou aceitando retiradas</h1>
        </ButtonDelivery>
      </ChooseDelivery>
      <AreaDelivery>
        <h1>Taxas de entrega:</h1>
        <Form ref={formRefDeliveryRadius} onSubmit={handleSubmit}>
          <div className="div-input">
            <p>Distância (metros)</p>
            <TextInput name="radius" type="number" />
          </div>
          <div className="div-input">
            <p>Tempo (minutos)</p>
            <TextInput name="estimatedTime" type="number" />
          </div>
          <div className="div-input">
            <p>Preço</p>
            <TextInput name="fee" type="number" />
          </div>
          <div className="add-btn">
            <SpotsButton type="submit" disabled={isLoader}>
              Adicionar
            </SpotsButton>
          </div>
        </Form>
      </AreaDelivery>
      <DeliveryRadius>
        {deliveryConfigData?.rates &&
          deliveryConfigData.rates.map((delivery, index) => {
            return (
              <RangePrice key={uuid()}>
                <div
                  style={{
                    background:
                      colors[deliveryConfigData.rates.indexOf(delivery)],
                  }}
                />
                <h1>
                  Raio de <strong>{delivery.radius}</strong> metros
                </h1>
                <h1>
                  Tempo de <strong>{delivery.estimatedTime}</strong> minutos
                </h1>
                <h1>
                  Taxa de entrega <strong>{delivery.fee}</strong> reais
                </h1>
                <button
                  type="button"
                  onClick={() => {
                    handleRemoveDelivery(index);
                  }}
                >
                  <FiX size={25} />
                </button>
              </RangePrice>
            );
          })}
      </DeliveryRadius>
    </Container>
  );
};

export default Delivery;
