import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const Container = styled.div`
  z-index: 1000;
  position: absolute;
  margin-bottom: 40px;
  top: 25%;
  left: 35%;
  width: 40%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appear} 1s;

  form {
    margin: 20px;
  }
`;

export const CepInput = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
  button {
    padding: 5px;
    width: 100px;
    border: 0;
    border-radius: 3px;
    background: #f58a07;
    font-size: 10px;
    color: #fff;
  }
`;
export const AddressFields = styled.div`
  display: flex;
  flex-direction: column;
  input {
    height: 30px;
    margin: 5px 20px;
    padding: 0px 10px;
    border: 1px solid #ccc;
    background-color: #ccc;
    border-radius: 3px;
    font-size: 12px;
  }
  .number {
    background-color: #fff;
  }
  button {
    margin-top: 10px;
    border: 0;
    background: #f58a07;
    color: #fff;
    font-size: 10px;
    padding: 10px;
    width: 150px;
    font-size: 12px;
    border-radius: 3px;
  }
`;
