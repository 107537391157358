/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { FiPlusCircle, FiMinusCircle } from 'react-icons/fi';

import { Scope } from '@unform/core';
import { Container, Time, PlusTime, TimeContainer } from './styles';

import HourPicker from '../HourPicker';
import { IWorkingHoursItem } from '../../../../../../entities/WorkingHourItem';

interface IProps {
  weekday: string;
  times: IWorkingHoursItem[];
}

const HoursPicker: React.FC<IProps> = ({ weekday, times }) => {
  const [intervals, setIntervals] = useState<number[]>(() => {
    if (times.length === 0) {
      return [0];
    }
    return times.map(() => 0);
  });

  return (
    <Container>
      {intervals.map((interval, index) => (
        <TimeContainer>
          <Scope path={`${weekday}[${index}]`}>
            <Time>
              <HourPicker
                label="Abre às: "
                name="opensAt"
                initValue={times[index]?.opensAt || '0000'}
              />
            </Time>
            <Time>
              <HourPicker
                label="Fecha às: "
                name="closesAt"
                initValue={times[index]?.closesAt || '0000'}
              />
            </Time>
          </Scope>
        </TimeContainer>
      ))}

      <PlusTime
        type="button"
        onClick={() => {
          setIntervals([...intervals, 0]);
        }}
      >
        <FiPlusCircle size={20} />
      </PlusTime>

      {intervals.length > 1 && (
        <PlusTime
          type="button"
          onClick={() => {
            const tmp = [...intervals];
            tmp.splice(intervals.length - 1, 1);
            setIntervals([...tmp]);
          }}
        >
          <FiMinusCircle size={20} />
        </PlusTime>
      )}
    </Container>
  );
};

export default HoursPicker;
