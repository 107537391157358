import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    svg {
      color: #f58a07;
      margin-right: 10px;
    }
    p {
      color: #333;
      font-size: 12px;
      margin: 0 5px;
    }
  }
`;
