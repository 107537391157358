import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { LoaderContainer } from './styles';

const ButtonLoader: React.FC = () => {
  return (
    <LoaderContainer>
      <ClipLoader size={20} color="#fff" />
    </LoaderContainer>
  );
};

export default ButtonLoader;
