import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import { LoaderContainer } from './styles';

const Loader: React.FC = () => {
  return (
    <LoaderContainer>
      <ClipLoader size={50} color="#123abc" />
    </LoaderContainer>
  );
};

export default Loader;
