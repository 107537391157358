import React from 'react';
import { useSocketHook } from '../../hooks/socket';

import { Container, ContainerWithOpacity } from './style';

const AllowSound: React.FC = () => {
  const { toggleSoundLoaded } = useSocketHook();

  return (
    <>
      <ContainerWithOpacity />
      <Container>
        <h1>Clique em ok para habilitar o som</h1>
        <button type="button" onClick={() => toggleSoundLoaded(true)}>
          OK
        </button>
      </Container>
    </>
  );
};

export default AllowSound;
