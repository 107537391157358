import styled, { keyframes } from 'styled-components';

const appear = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const Container = styled.div``;

export const Units = styled.div`
  position: relative;
  height: 30px;
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  h1 {
    font-size: 14px;
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ChooseUnit = styled.div`
  position: absolute;
  width: 300px;
  top: 30px;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #ccc;

  animation: ${appear} 1s;
  h1 {
    font-size: 14px;
  }
`;
export const Unit = styled.button`
  padding: 10px 0;
  width: 300px;
  border: 1px solid #ddd;
  background: #fff;
  transition: background 0.5s;
  h1 {
    font-size: 12px;
  }
  &:hover {
    background: #ccc;
  }
`;

export const AddUnit = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  width: 300px;
  color: #f58a07;
  background: #fff;
  border: 1px solid #ccc;
`;

export const NewUnit = styled.div`
  position: absolute;
  left: 50%;
  top: 100px;
  transform: translateX(-50%);
  width: 500px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 1000;
  animation: ${appear} 0.5s;
`;

export const ContentNewUnit = styled.div`
  padding: 40px;
  h1 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  button {
    padding: 10px 20px;
    background: #f58a07;
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 3px;
    margin-top: 10px;
  }
`;
