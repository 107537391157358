import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import cep from 'cep-promise';

import { useToast } from '../../../../hooks/toast';

import getValidationErrors from '../../../../utils/getValidationErrors';

import Title from '../../../../components/Title';
import TextInput from '../../../../components/inputs/TextInput';

import { Container, AddressFields, CepInput } from './styles';
import { IAddress } from '../../../../entities/Address';
import { useUnit } from '../../../../hooks/unit';
import SpotsButton from '../../../../components/buttons/SpotsButton';

interface IAddressForm {
  cep: string;
  state: string;
  city: string;
  street: string;
  number: string;
  neighborhood: string;
}

interface IProps {
  handleHideEditAddress(): void;

  addressData?: IAddress;
}

const Address: React.FC<IProps> = ({ addressData, handleHideEditAddress }) => {
  const formRef = useRef<FormHandles>(null);
  const [cepNumber, setCepNumber] = useState('');
  const [cepAddress, setCepAddress] = useState<Omit<IAddressForm, 'number'>>(
    {} as IAddressForm,
  );

  const { addToast } = useToast();
  const { editUnit } = useUnit();

  const [busy, setBusy] = useState(false);
  const [busyAddress, setBusyAddress] = useState(false);

  useEffect(() => {
    formRef.current?.setData({
      cep: addressData?.cep,
      state: addressData?.state,
      street: addressData?.street,
      city: addressData?.city,
      neighborhood: addressData?.neighborhood,
      number: addressData?.number,
    } as IAddressForm);
  }, [addressData]);

  const handlePost = useCallback(
    async (data: IAddressForm) => {
      setBusyAddress(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          cep: Yup.number().required('CEP obrigatório'),
          number: Yup.number().required('Número obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const response = await editUnit({
          deliveryData: {
            address: {
              cep: data.cep,
              city: data.city,
              state: data.state,
              street: data.street,
              neighborhood: data.neighborhood,
              number: data.number,
            },
          },
        });
        if (response) {
          handleHideEditAddress();
          window.location.reload(false);
        }
        setBusyAddress(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusyAddress(false);
      }
    },
    [editUnit, handleHideEditAddress],
  );

  const handleCep = useCallback(() => {
    setBusy(true);
    cep(cepNumber)
      .then((response) => {
        setCepAddress({
          cep: response.cep,
          city: response.city,
          street: response.street,
          neighborhood: response.neighborhood,
          state: response.state,
        });
        setBusy(false);
      })
      .catch(() => {
        setBusy(false);
        addToast({
          title: 'CEP não encontrado',
          description: 'Tente novamente',
          type: 'error',
        });
      });
  }, [addToast, cepNumber]);

  return (
    <Container>
      <Title title="Minha localização" />
      <Form ref={formRef} onSubmit={handlePost}>
        <CepInput>
          <TextInput
            onChange={(e) => setCepNumber(e.target.value)}
            placeholder="cep"
            name="cep"
            type="text"
          />
          <SpotsButton type="button" onClick={handleCep} disabled={busy}>
            Verificar Cep
          </SpotsButton>
        </CepInput>
        <AddressFields>
          <TextInput name="city" type="text" value={cepAddress.city} />
          <TextInput name="state" type="text" value={cepAddress.state} />
          <TextInput name="street" type="text" value={cepAddress.street} />
          <TextInput
            name="neighborhood"
            type="text"
            value={cepAddress.neighborhood}
          />
          <TextInput
            className="number"
            name="number"
            placeholder="Numero"
            type="number"
          />
          <SpotsButton type="submit" disabled={busyAddress}>
            Enviar
          </SpotsButton>
        </AddressFields>
      </Form>
    </Container>
  );
};

export default Address;
