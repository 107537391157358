import React from 'react';

import { SocketIOProvider } from 'use-socketio';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { BusinessProvider } from './business';
import { UnitProvider } from './unit';
import { SocketProvider } from './socket';
import { SiteProvider } from './site';
// import { NotificationProvider } from './notification';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <SocketIOProvider url={`${process.env.REACT_APP_API_URL}/`}>
        <BusinessProvider>
          <UnitProvider>
            <SocketProvider>
              <SiteProvider>{children}</SiteProvider>
            </SocketProvider>
          </UnitProvider>
        </BusinessProvider>
      </SocketIOProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
