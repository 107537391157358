import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.button`
  background: #f58a07;
  height: 2rem;
  border-radius: 3px;
  border: none;
  padding: 0 16px;
  width: 100%;
  color: #fff;
  font-weight: 500;
  margin-top: 5px;
  transition: background-color 0.2s;
  font-size: 14px;
  &:hover {
    color: #fff;
    background: ${shade(0.2, '#f58a07')};
  }
`;
