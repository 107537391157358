import React, { useEffect, useState } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
} from 'react-router-dom';

import { useBusiness } from '../hooks/business';
import { useAuth } from '../hooks/auth';
import { IBusiness } from '../entities/Business';
import FirstUnit from '../pages/FirstUnit';

interface IProps extends ReactDOMRouterProps {
  isPrivate: boolean;
  access?: 'manager' | 'employee' | 'admin';
  component: React.ComponentType;
}

const Route: React.FC<IProps> = ({
  isPrivate,
  access,
  component: Component,
  path,
  ...rest
}) => {
  const { getBusiness } = useBusiness();
  const [busy, setBusy] = useState(true);
  const { token } = useAuth();
  const [business, setBusiness] = useState<IBusiness>();
  const [role, setRole] = useState<'admin' | 'manager' | 'employee'>();

  useEffect(() => {
    getBusiness().then((response) => {
      setBusiness(response?.business);
      setRole(response?.role);
      setBusy(false);
    });
  }, [getBusiness, token]);

  return (
    <ReactDOMRoute
      {...rest}
      render={
        busy
          ? () => <h1> </h1>
          : () => {
              if (isPrivate) {
                if (role === 'employee') {
                  if (access !== 'employee') {
                    return <Redirect to={{ pathname: '/order' }} />;
                  }
                  return <Component />;
                }
                if (role === 'manager') {
                  if (access !== 'manager' && access !== 'employee') {
                    return <Redirect to={{ pathname: '/order' }} />;
                  }
                  return <Component />;
                }
                if (role === 'admin') {
                  if (business?.units.length === 0) {
                    return <FirstUnit />;
                  }

                  if (path === '/firstunit') {
                    return <Redirect to={{ pathname: '/dashboard' }} />;
                  }
                  return <Component />;
                }
                return <Redirect to={{ pathname: '/' }} />;
              }

              if (role === 'employee') {
                return <Redirect to={{ pathname: '/order' }} />;
              }
              if (role === 'manager') {
                return <Redirect to={{ pathname: '/order' }} />;
              }
              if (role === 'admin') {
                if (business?.units.length === 0) {
                  return <Redirect to={{ pathname: '/firstunit' }} />;
                }
                return <Redirect to={{ pathname: '/dashboard' }} />;
              }
              return <Component />;
            }
      }
    />
  );
};

export default Route;
