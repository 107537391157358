import styled, { css, keyframes } from 'styled-components';

const appear = keyframes`
  from{
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  width: 600px;
  min-width: 600px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  animation: ${appear} 1s;
  z-index: 1000;
  i {
    cursor: pointer;
  }
  .headline {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 20px 20px 10px 20px;
    font-weight: 100;
    h3 {
      font-weight: 200;
    }
  }
`;

export const OrderInformation = styled.div`
  margin: 20px;
  border: solid 1px #bbb;
  border-radius: 2px;
  .orders-headline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border: 1px solid #bbb;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: lightgrey;
    h3 {
      font-weight: 200;
      font-size: 16px;
    }
    .btn-status {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const PrintBtn = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background: #ccc;
  font-size: 14px;
`;

export const CancelBtn = styled.button`
  padding: 5px;
  background-color: #c53030;
  transform: translateX(-50%);
  border: 1px solid #c53030 !important;
  border-radius: 3px !important;
  p {
    color: #fff;
  }
`;

export const SubItem = styled.div`
  margin-left: 5px;
  justify-self: flex-start;
  align-self: flex-start;
  p {
    font-size: 12px;
  }
`;

export const ButtonStatus = styled.div`
  position: relative;
  height: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
  border-radius: 3px;
  p {
    margin-right: 5px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ChangeStatus = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  transform: translateY(25px);
`;
export const ChangeStatusBtn = styled.button`
  width: 100%;
  border: none;
  padding: 10px 0;
  p {
    color: #111;
    font-size: 12px;
  }
`;

export const InfoClientOrder = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 30px 30px 20px 30px;

  .first-infoClintOrder-row,
  .secound-infoClintOrder-row,
  .third-infoClintOrder-row,
  .fourth-infoClintOrder-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
`;

export const Order = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 30px 30px 0px 30px;
  border-bottom: 1px solid #bbb;
  border-top: 1px solid #bbb;
  .row-order {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    .first-order-column,
    .secound-order-column,
    .third-order-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }
    .first-order-column {
      width: 10%;
    }
    .secound-order-column {
      width: 65%;
    }
    .third-order-column {
      width: 25%;
    }
  }
  .observation {
    justify-self: flex-start;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #eee;
    margin-top: 20px;
    h1 {
      padding: 10px;
      font-size: 14px;
      font-weight: 800;
    }
  }
`;

export const Description = styled.div`
  align-self: flex-start;
  justify-self: flex-start;
  padding-left: 30px;
  width: 50%;
  border-right: 1px solid #ccc;
  h1 {
    font-size: 14px;
    margin-bottom: 5px;
  }
  p {
    font-size: 14px;
  }
`;

export const Price = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  .position {
    width: 40%;
    display: flex;
    flex-direction: column;

    padding-right: 40px;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: space-between;
      padding-bottom: 5px;
    }
    h4 {
      font-size: 12px;
    }
    .spred {
      height: 1px;
      width: 100%;
      background-color: #000;
    }
    .change {
      background: #f8f9fc;
      border: 1px solid #ccc;
      padding: 5px;
    }
  }
`;

interface ITableProps {
  isDescription: boolean;
}

export const Table = styled.div<ITableProps>`
  width: 100%;
  margin-bottom: 5px;
  div {
    h2 {
      font-size: 12px;
      font-weight: 200;
      margin-bottom: 5px;
    }
    h1 {
      font-size: 12px;
      font-weight: 800;
    }
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .status {
    span {
      padding: 5px;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
    }
  }
  .left {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
  }

  ${(props) =>
    props.isDescription &&
    css`
      width: 100%;
    `}
`;

const appearModal = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const AlertModal = styled.div`
  z-index: 1000;
  position: fixed;
  top: 25%;
  left: 10%;
  width: 80%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appearModal} 1s;
  .padding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    h1 {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: 16px;
    }
    .btn-container {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      button {
        width: 25%;
        font-size: 14px;
        padding: 5px 0;
        margin: 0 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
`;
