import styled, { css, keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Table = styled.div`
  width: 100%;
  margin: 0 auto;
  border-radius: 3px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 0.8s;
  table {
    border-collapse: collapse;
    width: 100%;
    padding: 20px;
    margin: 20px 0;
    thead {
      tr {
        th {
          border-bottom: 1px solid #ccc;
          padding-bottom: 15px;
          padding-top: 5px;
          font-size: 14px;
        }
      }
    }
  }
`;

interface PropsClient {
  hasColor: boolean;
}

export const Client = styled.tr<PropsClient>`
  th {
    border-bottom: 1px solid #ccc;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
    font-weight: 100;
  }

  background: #fff;

  ${(props) =>
    props.hasColor &&
    css`
      background: #f1f3f6;
    `}
`;
