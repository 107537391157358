import React, { useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format, addMinutes } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';

import { useLocation, Redirect } from 'react-router-dom';
import { uuid } from 'uuidv4';
import {
  Container,
  Order,
  TitleOrder,
  SubTitleOrder,
  Title,
  DeliveryBox,
  OrderStatus,
  ClientStatus,
  PaymentStatus,
  PaymentDescription,
} from './styles';
import { IOrder } from '../../entities/Order';
import { useBusiness } from '../../hooks/business';

const PrintOrder: React.FC = () => {
  const { currentUnit } = useBusiness();
  const order = (useLocation().state as unknown) as IOrder;
  useEffect(() => {
    window.print();
  }, []);
  const formattedPhoneNumber = useCallback((x) => {
    // recebe um +5547123456789 e retorna (47) 12345-6789
    return `(${x.slice(3, 5)}) ${x.slice(5, 10)}-${x.slice(10, x.length)}`;
  }, []);
  const handleTotalPrice = useCallback(
    (voucherDiscount, subTotal, deliveryFee, isPercentage) => {
      if (voucherDiscount) {
        if (!isPercentage) {
          return `R$${(subTotal + deliveryFee - voucherDiscount)
            .toFixed(2)
            .replace('.', ',')}`;
        }
        return `R$${(
          subTotal +
          deliveryFee -
          ((subTotal * voucherDiscount) / 100 || 0)
        )
          .toFixed(2)
          .replace('.', ',')}`;
      }
      return `R$${(subTotal + deliveryFee).toFixed(2).replace('.', ',')}`;
    },
    [],
  );
  const handleIsPercentage = useCallback(
    (voucherDiscount, isPercentage, voucherTitle) => {
      if (voucherDiscount) {
        if (isPercentage) {
          return `%${voucherDiscount.toFixed(2).replace('.', ',')}`;
        }
        return `- R$${voucherDiscount.toFixed(2).replace('.', ',')}`;
      }
      if (voucherTitle) {
        return `${voucherTitle}`;
      }
      return '-R$0,00';
    },
    [],
  );
  return (
    <Container>
      <div className="print">
        <DeliveryBox>
          <h1>PEDIDO: #{order.orderNumber}</h1>
          {order.isDelivery ? <h1>ENTREGA</h1> : <h1>RETIRADA</h1>}
        </DeliveryBox>

        <OrderStatus>
          <h1>
            {`Data: ${format(
              new Date(order.status.received),
              `d'/'M'/'Y H':'mm`,
              {
                locale: ptLocale,
              },
            )}`}
          </h1>
          <h1>
            {`Entrega prevista: ${format(
              addMinutes(new Date(order.status.received), order.estimatedTime),
              `H':'mm`,
              {
                locale: ptLocale,
              },
            )}`}
          </h1>
        </OrderStatus>

        <ClientStatus>
          <h1>
            {`Nome: ${
              order.client.nickname
                ? order.client.nickname
                : currentUnit.clients.find(
                    (client) => client.id === (order.client as unknown),
                  )?.nickname
            }`}
          </h1>
          <h1>
            {order.invoiceDocument ? `CPF: ${order.invoiceDocument}` : null}
          </h1>
          <h1>
            {`Telefone: ${formattedPhoneNumber(
              order.client.phone
                ? order.client.phone
                : currentUnit.clients.find(
                    (client) => client.id === (order.client as unknown),
                  )?.phone,
            )}`}
          </h1>
          <h1>
            {order.isDelivery
              ? `Endereço: ${order?.address?.street} ${order?.address?.number} - ${order?.address?.city}`
              : 'Retirada no balcao'}
          </h1>
          <h1>
            {order.isDelivery
              ? `Bairro: ${order?.address?.neighborhood}`
              : null}
          </h1>
          <h1>
            {order.isDelivery
              ? `Complemento: ${order?.address?.extraInfo}`
              : null}
          </h1>
          <h1>{order.isDelivery ? `Cidade: ${order?.address?.city}` : null}</h1>
          {order.isDelivery ? `Cep: ${order?.address?.cep}` : null}
        </ClientStatus>

        <Title>
          <h1 className="qtd">Qtd.</h1>
          <h1 className="name">Produto</h1>
          <h1 className="price">Preço</h1>
        </Title>
        {order.items.map((item) => {
          return (
            <Order key={uuid()}>
              <TitleOrder>
                <h1 className="qtd-item">{item.amount}x</h1>
                <h1 className="name-item">{item.name}</h1>
                <h1 className="price-item">
                  R$ {item.price.toFixed(2).replace('.', ',')}
                </h1>
              </TitleOrder>
              {item.subItems.map((subitem) => {
                return (
                  <SubTitleOrder key={uuid()}>
                    <h4>{subitem.amount}x</h4>
                    <h3>{subitem.name}</h3>
                  </SubTitleOrder>
                );
              })}
              <p className="observation-item">
                {item.observations ? `Obs item: ${item.observations}` : ''}
              </p>
            </Order>
          );
        })}

        <h2>OBS PEDIDO: {order.observations}</h2>

        <PaymentStatus>
          <div className="row">
            <h1>Valor do pedido:</h1>
            <h1>R$ {order.subTotalPrice.toFixed(2).replace('.', ',')}</h1>
          </div>
          <div className="row">
            <h1>Taxa de entrega:</h1>
            <h1>R$ {order.deliveryFee?.toFixed(2).replace('.', ',')}</h1>
          </div>
          <div className="row">
            <h1>Cupom:</h1>
            <h1>
              {handleIsPercentage(
                order.voucher?.discount,
                order.voucher?.isPercentage,
                order.voucher?.title,
              )}
            </h1>
          </div>
          <div className="row-total">
            <h1>Total do pedido:</h1>
            <h1>
              {handleTotalPrice(
                order.voucher?.discount,
                order.subTotalPrice,
                order.deliveryFee,
                order.voucher?.isPercentage,
              ) || '0'}
            </h1>
          </div>
        </PaymentStatus>

        <PaymentDescription>
          <h1>Forma de pagamento:</h1>
          <h1>
            {`${
              order.paymentMethod?.type !== 'Dinheiro'
                ? `${order.paymentMethod?.cardBrand} - `
                : ''
            }${order.paymentMethod?.type}`}
          </h1>
          <h1>
            {order.changeFor
              ? `Levar troco para: R$ ${order.changeFor
                  .toFixed(2)
                  .replace('.', ',')}`
              : null}
          </h1>
        </PaymentDescription>
      </div>
      <div className="noprint" />
      <Redirect to="/order" />
    </Container>
  );
};

export default PrintOrder;
