import React, { useEffect, useState, useCallback } from 'react';

import Container from '../../components/Container';
import Title from '../../components/Title';

import Map from './components/Map';
import Delivery from './components/Delivery';
import Address from './components/Address';

import {
  ConfigurationContainer,
  MapContainer,
  ShowAddress,
  LocationButton,
  ContainerOpacity,
} from './styles';
import { useBusiness } from '../../hooks/business';

const Configuration: React.FC = () => {
  // State
  const [editAddress, setEditAddress] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const handleHideEditAddress = useCallback(() => {
    setEditAddress(false);
  }, []);
  return (
    <Container isOpen="configuration" isLoaded={isLoaded}>
      <ConfigurationContainer>
        <Title title="Meu Delivery" />
        <Delivery
          deliveryConfigData={currentUnit.deliveryConfig}
          hasDeliveryTemp={currentUnit.hasDelivery}
          hasTakeAwayTemp={currentUnit.hasTakeaway}
          prepareTimeData={currentUnit.prepareTime}
        />
        {currentUnit.address !== null && currentUnit.address !== undefined ? (
          <MapContainer>
            <LocationButton
              type="button"
              onClick={() => {
                setEditAddress(true);
              }}
            >
              Editar minha localização
            </LocationButton>
            <Map
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAggzjNanWBFb1l6FQDsUs_v8EekB7mDaE"
              loadingElement={
                <div style={{ height: `70vh`, padding: `30px` }} />
              }
              containerElement={
                <div style={{ height: `80vh`, padding: `10px 30px` }} />
              }
              mapElement={<div style={{ height: `70vh`, padding: `30px` }} />}
              addressData={currentUnit.address}
              deliveryConfigData={currentUnit.deliveryConfig}
            />
          </MapContainer>
        ) : (
          <>
            <ShowAddress />
            <Address
              handleHideEditAddress={() => {
                console.log('tirar isso dps, index.tsx do configuration');
              }}
            />
          </>
        )}
        {editAddress ? (
          <>
            <ContainerOpacity onClick={handleHideEditAddress} />
            <Address
              addressData={currentUnit.address}
              handleHideEditAddress={handleHideEditAddress}
            />
          </>
        ) : null}
      </ConfigurationContainer>
    </Container>
  );
};

export default Configuration;
