import React, { useState, useRef, useCallback } from 'react';
import { FiPlus, FiArrowUp, FiArrowDown } from 'react-icons/fi';
import * as Yup from 'yup';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import getValidationErrors from '../../utils/getValidationErrors';

import Title from '../Title';
import TextInput from '../inputs/TextInput';

import {
  Container,
  ChooseUnit,
  Units,
  AddUnit,
  Unit,
  NewUnit,
  ContentNewUnit,
} from './styles';
import ContainerOpacity from '../ContainerOpacity';
import { useBusiness } from '../../hooks/business';
import SpotsButton from '../buttons/SpotsButton';

interface IUnitName {
  name: string;
}

const UnitManagement: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [showChooseUnits, setShowChooseUnits] = useState<boolean>(false);
  const [showAddUnit, setShowAddUnit] = useState<boolean>(false);
  const [busy, setBusy] = useState(false);

  // Hooks

  const { business, selectUnit, createUnit, currentUnit, role } = useBusiness();

  const handleSubmit = useCallback(
    async (data: IUnitName) => {
      try {
        setBusy(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da unidade obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        createUnit(data.name);
        setBusy(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusy(true);
      }
    },
    [createUnit],
  );

  return (
    <Container>
      <Units
        onClick={() => {
          setShowChooseUnits(!showChooseUnits);
        }}
      >
        <h1>{currentUnit ? currentUnit.name : 'nome'}</h1>
        {showChooseUnits && role === 'admin' ? (
          <FiArrowDown size={15} />
        ) : (
          <FiArrowUp size={15} />
        )}
        {showChooseUnits && role === 'admin' ? (
          <ChooseUnit>
            {business.units.map((unitExist) => (
              <Unit
                type="button"
                onClick={() => {
                  selectUnit(unitExist.id);
                  setShowChooseUnits(false);
                }}
              >
                <h1>{unitExist.name}</h1>
              </Unit>
            ))}
            <AddUnit
              type="button"
              onClick={() => {
                setShowAddUnit(true);
              }}
            >
              <h1>Adicionar</h1>
              <FiPlus size={15} />
            </AddUnit>
          </ChooseUnit>
        ) : null}
      </Units>
      {showAddUnit ? (
        <>
          <NewUnit>
            <Title title="Cadastrar nova unidade" />
            <ContentNewUnit>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <h1>Cadastrar nova unidade</h1>
                <TextInput name="name" type="text" />
                <SpotsButton
                  disabled={busy}
                  type="submit"
                  onClick={() => {
                    setBusy(true);
                  }}
                >
                  Cadastrar
                </SpotsButton>
              </Form>
            </ContentNewUnit>
          </NewUnit>
          <ContainerOpacity
            onClick={() => {
              setShowAddUnit(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default UnitManagement;
