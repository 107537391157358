import styled, { keyframes } from 'styled-components';

const apear = keyframes`
  from {
    height: 0px;
  }
  to{
    height: 44px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: start;
  color: #fff;
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  animation: ${apear} 1s;
  p {
    color: #111;
    margin-right: 3px;
  }
  label {
    color: #111;
    margin: auto 5px;
    font-size: 12px;
  }
  select {
    margin: 3px 0;
    border: 1px solid #ccc;
  }
`;

export const TimeContainer = styled.div`
  padding: 0 5px;
  margin: 3px 3px;
  background-color: #eee;
  border-radius: 5px;
`;

export const PlusTime = styled.button`
  border: none;
  background: inherit;
  svg {
    color: #f58a07;
  }
`;

export const Time = styled.div`
  & + div {
    margin-left: 10px;
  }
`;
