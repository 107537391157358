import React, { ButtonHTMLAttributes } from 'react';

import { Container } from './styles';
import ButtonLoader from '../ButtonLoader';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled: boolean;
}

const SpotsButton: React.FC<IProps> = ({ disabled, children, ...rest }) => (
  <Container type="button" {...rest}>
    {disabled ? <ButtonLoader /> : children}
  </Container>
);

export default SpotsButton;
