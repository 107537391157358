import React from 'react';

import Chart from 'react-google-charts';

import Title from '../../../../components/Title';

import { Container } from './styles';
import Loader from '../../../../components/Loader';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  monthData: any;
}

const MonthChart: React.FC<Props> = ({ monthData }) => {
  return (
    <Container>
      <Title title="Faturamento por mês" />
      <Chart
        width="100%"
        height="90%"
        chartType="SteppedAreaChart"
        loader={<Loader />}
        data={monthData}
        options={{
          hAxis: { titleTextStyle: { color: '#333' } },
          vAxis: { minValue: 0 },
          colors: ['red', 'blue'],

          chartArea: { left: 50, width: '90%', height: '85%' },
        }}
      />
    </Container>
  );
};

export default MonthChart;
