import styled from 'styled-components';

export const TitleComponent = styled.h1`
  border-radius: 0px;
  padding-left: 20px;
  text-align: start;
  line-height: 2.5;
  margin: 0;
  height: 40px;
  width: 100%;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  font-weight: 100;
  font-size: 16px;
`;
