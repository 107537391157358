import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  height: 50vh;
  width: 49%;
  background: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
`;
