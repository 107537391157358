import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  width: 50%;
  min-width: 700px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  z-index: 1000;
  animation: ${appear} 1s;

  p {
    color: white;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  form {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    button {
      align-self: flex-end;
      width: 150px;
      border-radius: 3px;
      border: none;
      padding: 10px 16px;
      font-weight: 500;
      transition: background-color 0.2s;
      font-size: 14px;
      margin-top: 100px;
      margin-bottom: 50px;
      margin-right: 10%;
    }
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  div {
    margin-top: 25px;
    height: 40px;
    input {
      height: 15px;
    }

    & + div {
      margin-left: 40px;
    }
  }
  h4 {
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    margin: 5px 5px 0 5px;
    span {
      color: #ff0000;
    }
  }
`;

export const Button = styled.button`
  align-self: flex-end;
  width: 150px;
  background: #70bb35;
  border-radius: 3px;
  border: none;
  padding: 10px 16px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  margin-top: 100px;
  margin-bottom: 50px;

  &:hover {
    background: ${shade(0.2, '#70bb35')};
  }
`;
