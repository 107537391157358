import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const RateContainer = styled.div`
  width: 100%;
  padding: 0 0 50px 0;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const RatingAverage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 3px;
  align-self: flex-end;
  margin-right: 60px;
  padding: 5px;
  min-width: 200px;
  h1 {
    font-size: 14px;
    margin-right: 5px;
  }
  svg {
    margin-right: 5px;
    color: #f58a07;
  }
`;

export const TableRates = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;

  thead {
    border-top: none;
    border-bottom: 1px solid #111;
    .tr-header {
      text-align: center;
      .td-header {
        font-size: 12px;
        font-weight: bold;
        padding: 10px 0;
      }
      .client {
        text-align: start;

        padding-left: 10px;
        width: 15%;
      }

      .phone {
        text-align: center;

        padding-left: 10px;
        width: 10%;
      }

      .comment {
        text-align: center;

        padding-left: 10px;
        width: 45%;
      }
      .rating {
        width: 10%;
      }
      .order {
        width: 10%;
      }
    }
  }
`;
