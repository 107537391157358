import styled, { keyframes, css } from 'styled-components';
import { shade } from 'polished';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;
export const VoucherContainer = styled.div`
  border: 1px solid #e3e6f0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  background: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bbb;
  padding-bottom: 30px;
  animation: ${appearContainer} 1s;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 30px 100px 20px 50px;
    input {
      padding: 10px 5px;
      border-radius: 3px;
      border: 1px solid #ccc;
      font-size: 14px;
      width: 500px;
    }
    button {
      width: 200px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: #f58a07;
      border-radius: 3px;
      border: none;
      padding: 10px 10px;
      color: #fff;
      font-weight: 500;
      transition: background-color 0.2s;
      font-size: 14px;
      p {
        font-size: 14px;
      }
      &:hover {
        background: ${shade(0.2, '#f58a07')};
      }
    }
  }
`;

export const VoucherTable = styled.table`
  width: 94%;
  margin: 30px auto;
  border-collapse: collapse;
  thead {
    tr {
      text-align: center;

      th {
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
        font-size: 12px;
      }
    }
  }
  .name-th {
    width: 20%;
  }
  .price-th {
    width: 10%;
  }
  .qtd-th {
    width: 10%;
  }
  .used-th {
    width: 10%;
  }
  .start-th {
    width: 10%;
  }
  .exppires-th {
    width: 10%;
  }
  .cancel-th {
    width: 20%;
    text-align: center;
    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      border: none;
      background-color: inherit;
      font-size: 14px;
      padding: 5px;
      p {
        background-color: lightgreen;
        padding: 5px 10px;
        border-radius: 3px;
        margin-right: 5px;
      }
      svg {
        align-self: flex-start;
        color: #c53030;
        background-color: #fff;
      }
    }
  }
`;

export const Vouchers = styled.tbody`
  tr {
    th {
      border-bottom: 1px solid #ccc;
      padding: 10px 0;
      p {
        text-align: center;
        padding: 5px 5px;
        font-size: 14px;
        font-weight: 100;
      }
    }
  }
`;

interface OpacityContainerProps {
  showAddVoucher: boolean;
}

const appearOpacity = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
`;

export const OpacityContainer = styled.div<OpacityContainerProps>`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  display: none;
  animation: ${appearOpacity} 1s;

  ${(props) =>
    props.showAddVoucher &&
    css`
      display: block;
    `}
`;

const appearModal = keyframes`
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1000;
  position: fixed;
  top: 25%;
  left: 10%;
  width: 80%;
  border: solid 1px #ccc;
  background: #fff;
  animation: ${appearModal} 1s;
  .padding-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px;

    h1 {
      text-align: center;
      line-height: 30px;
      font-weight: 200;
      font-size: 16px;
    }
    .btn-container {
      width: 100%;
      text-align: center;
      margin-top: 25px;
      button {
        width: 25%;
        font-size: 14px;
        padding: 5px 0;
        margin: 0 5px;
        border: none;
        border-radius: 3px;
        color: #fff;
      }
    }
  }
`;
