import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const OrdersContainer = styled.div`
  width: 100%;
  padding: 0 0 10px 0;
  border-radius: 5px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appearContainer} 1s;
`;

export const TableOrders = styled.table`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;

  thead {
    border-top: none;
    .tr-header {
      text-align: center;
      .td-header {
        font-weight: bold;
        padding: 10px 0;
      }
    }
  }
`;
