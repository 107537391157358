import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  select {
    border: none;
    background: #eee;
  }
  .div-input-pickHour {
    div {
      display: none;
    }
  }
`;
