import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  width: 100%;
  background-color: #f8fbfe;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 15px;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  animation: ${appear} 1s;
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 5px 0 5px 5px;
  background-color: #f8f9fc;
  border-bottom: 1px solid #ccc;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  margin: auto;

  h2 {
    align-self: center;
    flex: 1;
    font-size: 16px;
    font-weight: 100;
    margin-left: 10px;
  }
`;

interface ButtonProps {
  colorHex: string;
}

export const Button = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.colorHex};
  margin-right: 5px;
  padding: 10px 10px;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  background: inherit;
  font-size: 14px;
  transition: background-color 0.2s;
  svg {
    margin-right: 5px;
  }
`;

export const StopBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 5px 6px;
  color: #111;
  font-weight: 200;
  transition: background-color 0.2s;
  font-size: 14px;
  background-color: inherit;
  transform: color 1s;
  svg {
    color: #111;
    margin: 0 3px;
  }
  &:hover {
    color: ${shade(0.1, '#444')};
  }
`;
