import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  border-radius: 5px;

  margin: 3vh 0;
  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 10px 10px;
  margin: 5px;
  color: #111;
  img {
    width: 30px;
  }

  label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    p {
      font-size: 12px;
      margin: 0 10px;
      flex: 3;
    }
    div {
      flex: 1;
      border: none;
      margin: 0;
      padding: 0;
      input {
        height: 20px;
        width: 20px;
      }
    }
  }
  :hover {
    cursor: pointer;
  }
`;
