import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import FirstUnit from '../pages/FirstUnit';
import Dashboard from '../pages/Dashboard';
import EditInfo from '../pages/EditInfo';
import Clients from '../pages/Clients';
import Voucher from '../pages/Voucher';
import Menu from '../pages/Menu';
import Configuration from '../pages/Configuration';
import Rates from '../pages/Rates';
import Accounts from '../pages/Accounts';
import Notify from '../pages/Notify';
import PrintOrder from '../pages/PrintOrder';
import Orders from '../pages/Orders';
// import Home from '../pages/site/pages/Home/indext';
import Tutorial from '../pages/Tutorial';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" isPrivate={false} exact component={SignIn} />
      <Route path="/signup" isPrivate={false} component={SignUp} />
      <Route path="/firstunit" isPrivate access="admin" component={FirstUnit} />
      <Route path="/dashboard" isPrivate access="admin" component={Dashboard} />

      <Route path="/editinfo" isPrivate access="manager" component={EditInfo} />
      <Route path="/clients" isPrivate access="manager" component={Clients} />
      <Route path="/vouchers" isPrivate access="manager" component={Voucher} />
      <Route path="/addItem" isPrivate access="manager" component={Menu} />
      <Route
        path="/configurations"
        isPrivate
        access="manager"
        component={Configuration}
      />
      <Route path="/accounts" isPrivate access="admin" component={Accounts} />
      <Route path="/rates" isPrivate access="manager" component={Rates} />
      <Route path="/notify" isPrivate access="manager" component={Notify} />
      <Route path="/print" isPrivate access="employee" component={PrintOrder} />
      <Route path="/order" isPrivate access="employee" component={Orders} />
      <Route
        path="/tutorials"
        isPrivate
        access="manager"
        component={Tutorial}
      />
      {/* <Route path="/site" isPrivate component={Home} /> */}
    </Switch>
  );
};

export default Routes;
