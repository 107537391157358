import React, { useState, useCallback } from 'react';

import { FormHandles } from '@unform/core';
import { Container, FirstRow, Row } from './styles';
import TextInput from '../../../../../components/inputs/TextInput';
import CheckboxInput from '../../../../../components/inputs/CheckboxInput';

interface IProps {
  isActivated: boolean;
  formRef: React.RefObject<FormHandles>;
}

const GeneralInfoForm: React.FC<IProps> = ({ isActivated, formRef }) => {
  const [price, setPrice] = useState<string>(() => {
    return formRef.current?.getFieldValue('price');
  });

  const formatPrice = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/(\d)(\d{2})$/, '$1,$2');

    setPrice(formattedString);
  }, []);

  return (
    <Container isActivated={isActivated}>
      <FirstRow>
        <div className="first-row">
          <h1>Nome:</h1>
          <TextInput name="name" type="text" />
        </div>
      </FirstRow>
      <Row>
        <div className="secound-row description">
          <h1>Descrição (opcional):</h1>
          <TextInput name="description" type="text" />
        </div>
        <div className="secound-row">
          <h1>Ordenamento</h1>
          <TextInput name="priority" type="text" />
        </div>
      </Row>
      <Row>
        <div className="third-row price">
          <h1>Preço</h1>
          <TextInput
            placeholder="R$ 0,00"
            name="price"
            type="text"
            value={price}
            onChange={(e) => {
              formatPrice(e.target.value);
            }}
          />
          <div className="isFinalPrice">
            <CheckboxInput name="isFinalPrice" />
            <h4>A partir de </h4>
          </div>
        </div>
        <div className="third-row tag">
          <h1>Tag (opcional):</h1>
          <TextInput placeholder="Ex: Mais vendido" name="tag" type="text" />
        </div>
      </Row>
    </Container>
  );
};

export default GeneralInfoForm;
