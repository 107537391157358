/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';
import {
  FiStopCircle,
  FiPlay,
  FiTrash2,
  FiCamera,
  FiEdit,
  FiX,
} from 'react-icons/fi';

import Loader from 'react-spinners/ClipLoader';
import { Container, StopBtn, EditBtn, RemoveBtn, ImageInput } from './styles';
import ItemForm from '../ItemForm';
import { IItem } from '../../../entities/Item';
import { useUnit } from '../../../hooks/unit';
import ContainerOpacity from '../../../components/ContainerOpacity';

interface IProps {
  categoryId: string;
  item: IItem;
}

const ItemTile: React.FC<IProps> = ({ categoryId, item }) => {
  // State
  const [showItemForm, setShowItemForm] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [isLoaderX, setIsLoaderX] = useState(false);
  const [isLoaderTrash, setIsLoaderTrash] = useState(false);

  // Hooks
  const { editItem, deleteItem, deleteItemPhoto, addItemPhoto } = useUnit();

  const handleUpdate = useCallback(
    async (file: File) => {
      const fd = new FormData();
      fd.append('file', file);
      await addItemPhoto(fd, categoryId, item.id);
      console.log(item.id);
      setIsLoader(false);
    },
    [addItemPhoto, categoryId, item.id],
  );
  const handleChange = useCallback(
    (selectorFiles) => {
      handleUpdate(selectorFiles[0]);
    },
    [handleUpdate],
  );

  return (
    <Container>
      <div className="name">
        {item.photo ? (
          <ImageInput>
            <div className="edit-dell">
              {isLoaderX ? (
                <Loader size={15} />
              ) : (
                <FiX
                  size={15}
                  onClick={async () => {
                    setIsLoaderX(true);
                    await deleteItemPhoto(categoryId, item.id);
                    setIsLoaderX(false);
                  }}
                />
              )}
              <label htmlFor={item.id}>
                {isLoader ? <Loader size={15} /> : <FiEdit size={15} />}
              </label>
            </div>
            <input
              style={{ display: 'none' }}
              id={item.id}
              type="file"
              onChange={(e) => {
                setIsLoader(true);
                handleChange(e.target.files);
              }}
            />
            <img
              style={{
                width: '50px',
                height: '50px',
                objectFit: 'cover',
                borderRadius: '8px',
              }}
              src={item.photo.url}
              alt="Imagem do produto"
            />
          </ImageInput>
        ) : (
          <ImageInput>
            <div className="add-photo">
              <label htmlFor={item.id}>
                {isLoader ? <Loader /> : <FiCamera size={15} />}
              </label>
            </div>
            <input
              style={{ display: 'none' }}
              id={item.id}
              type="file"
              onChange={async (e) => {
                setIsLoader(true);
                handleChange(e.target.files);
              }}
            />
          </ImageInput>
        )}
        <h4>{item.name} </h4>
        {item.tag && <strong>{item.tag}</strong>}
      </div>
      <div className="price">
        <h3>
          {`${!item.isFinalPrice ? 'A partir de ' : ''}`} R$
          {item.price.toFixed(2).replace('.', ',')}
        </h3>
      </div>
      <div className="buttons">
        <StopBtn
          type="button"
          onClick={async () => {
            await editItem({ isActive: !item.isActive }, categoryId, item.id);
          }}
        >
          {item.isActive ? <FiStopCircle /> : <FiPlay />}
          {item.isActive ? 'Pausar a venda' : 'Retomar a venda'}
        </StopBtn>
        <EditBtn
          type="button"
          onClick={() => {
            setShowItemForm(true);
            window.scrollTo(0, 65);
          }}
        >
          Editar
        </EditBtn>
        <RemoveBtn
          onClick={async () => {
            setIsLoaderTrash(true);
            await deleteItem(item.id, categoryId);
            setIsLoaderTrash(false);
          }}
          type="button"
        >
          {isLoaderTrash ? <Loader size={15} /> : <FiTrash2 size={15} />}
        </RemoveBtn>
      </div>
      {showItemForm && (
        <>
          <ItemForm
            handleSubmit={async (data) => {
              const baga = { ...data };

              baga.price = parseFloat(baga.price.toString().replace(',', '.'));

              baga.subCategories?.forEach((subCategory) => {
                // eslint-disable-next-line array-callback-return
                subCategory.subItems.map((subItem) => {
                  // eslint-disable-next-line no-param-reassign
                  subItem.price = parseFloat(
                    subItem.price.toString().replace(',', '.'),
                  );
                });
              });
              await editItem(baga, categoryId, item.id);
              setShowItemForm(false);
            }}
            initialData={item}
          />
          <ContainerOpacity
            onClick={() => {
              setShowItemForm(false);
            }}
          />
        </>
      )}
    </Container>
  );
};

export default ItemTile;
