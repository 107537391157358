import styled, { css } from 'styled-components';

export const Days = styled.div``;

export const Day = styled.div`
  div {
    display: flex;
    flex-direction: row;
  }
  .input-day-div {
    div {
      border: none;
      display: none;
    }
  }
`;

interface ButtonProps {
  isOpen: boolean;
}

export const OpenButton = styled.button<ButtonProps>`
  padding: 10px 20px;
  background: #eee;
  border: none;
  padding: 10px 20px;
  border: none;
  border-bottom: 1px solid #ccc;
  transition: background 0.5s;

  &:hover {
    background: #fff;
  }

  ${(props) =>
    props.isOpen &&
    css`
      background-color: #fff;
      border-bottom: none;
      border-right: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-top: 1px solid #ccc;
    `}
`;

export const CloseButton = styled.button<ButtonProps>`
  padding: 10px 20px;
  background-color: #eee;
  border: none;
  border-bottom: 1px solid #ccc;
  transition: background 0.5s, color 0.5s;

  &:hover {
    background: #333;
    color: #fff;
  }
  ${(props) =>
    !props.isOpen &&
    css`
      background-color: #333;
      color: #fff;
    `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  width: 100%;
  border-radius: 5px;

  border: 1px solid #e3e6f0;
  background-color: #fff;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  h1 {
    border-radius: 0px;
    padding-left: 20px;
    text-align: start;
    line-height: 2.5;
    margin: 0;
    height: 40px;
    width: 100%;
    background-color: #f8f9fc;
    border-bottom: 1px solid #e3e6f0;
    font-size: 16px;
    font-weight: 100;
  }
`;
