import React, { useCallback, useRef, useState } from 'react';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';

import TextInput from '../../../components/inputs/TextInput';
import Title from '../../../components/Title';

import { Container, Row, Content } from './styles';
import CheckboxInput from '../../../components/inputs/CheckboxInput';
import getValidationErrors from '../../../utils/getValidationErrors';
import SpotsButton from '../../../components/buttons/SpotsButton';
import { useUnit } from '../../../hooks/unit';
import { useBusiness } from '../../../hooks/business';

interface IFormData {
  title: string;
  maxUses: number;
  isPercentage: boolean;
  discount: number;
  startsAt: Date;
  expiresAt: Date;
  minPrice: number;
  description?: string;
}
interface IProps {
  setShowAddVoucher: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddVoucher: React.FC<IProps> = ({ setShowAddVoucher }) => {
  // Refs
  const fromVoucherRef = useRef<FormHandles>(null);

  // State
  const [busy, setBusy] = useState(false);

  // Hooks
  const { currentUnit } = useBusiness();
  const { createVoucher } = useUnit();

  const HandleVoucher = useCallback(
    async (data: IFormData) => {
      try {
        setBusy(true);
        fromVoucherRef.current?.setErrors({});

        const schema = Yup.object().shape({
          title: Yup.string()
            .required('Titulo obrigatório')
            .min(3, 'Digite ao mínimo 3 caracteres'),
          maxUses: Yup.number().required('Quantidade obrigatória'),
          discount: Yup.number().required('Desconto obrigatório'),
          startsAt: Yup.date().required('Quando começa obrigatório'),
          expiresAt: Yup.date().required('Quando acaba obrigatório'),
          minPrice: Yup.number().required('Mínimo valor obrigatório'),
          description: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await createVoucher({
          unit: currentUnit.id,
          discount: data.discount,
          title: data.title,
          description: data.description,
          expiresAt: data.expiresAt,
          startsAt: data.startsAt,
          maxUses: data.maxUses > 0 ? data.maxUses : 999999,
          minPrice: data.minPrice,
          isPercentage: data.isPercentage,
        });

        setBusy(false);
        setShowAddVoucher(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          fromVoucherRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },
    [createVoucher, currentUnit.id, setShowAddVoucher],
  );

  const [discount, setDiscount] = useState('0');
  const [minPrice, setMinPrice] = useState('0');
  const [maxUses, setMaxUses] = useState('0');

  const formatNumberFields = useCallback(
    (value: string, input: 'discount' | 'maxUses' | 'minPrice') => {
      switch (input) {
        case 'discount':
          setDiscount(
            value.replace(/\D/g, '').replace(/^0+/, '').replace(/^$/, '0'),
          );
          break;

        case 'maxUses':
          setMaxUses(
            value.replace(/\D/g, '').replace(/^0+/, '').replace(/^$/, '0'),
          );
          break;

        case 'minPrice':
          setMinPrice(
            value.replace(/\D/g, '').replace(/^0+/, '').replace(/^$/, '0'),
          );
          break;

        default:
      }
    },
    [],
  );

  return (
    <Container>
      <Title title="Adicione um Cupom" />
      <Content>
        <Form
          initialData={{
            title: '',
            startsAt: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth()
            }-${
              new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
            }`,
            expiresAt: `${new Date().getFullYear()}-${
              new Date().getMonth() + 1 < 10
                ? `0${new Date().getMonth() + 1}`
                : new Date().getMonth()
            }-${
              new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
            }`,
            description: '',
          }}
          onSubmit={HandleVoucher}
          ref={fromVoucherRef}
        >
          <Row>
            <div className="title-input title">
              <h1>Título:</h1>
              <TextInput name="title" type="text" />
            </div>
            <div className="title-input date">
              <h1>Começa em:</h1>
              <TextInput name="startsAt" type="date" />
            </div>
            <div className="title-input date">
              <h1>Expira em:</h1>
              <TextInput name="expiresAt" type="date" />
            </div>
          </Row>
          <Row>
            <div className="title-input description">
              <h1>Descrição: (opcional)</h1>
              <TextInput name="description" type="text" />
            </div>
          </Row>
          <Row>
            <div className="title-input discount">
              <h1>Desconto:</h1>
              <TextInput
                name="discount"
                type="text"
                value={discount}
                onChange={(e) => {
                  formatNumberFields(e.target.value, 'discount');
                }}
              />
              <div className="isPercentage">
                <h1>É porcentagem:</h1>
                <CheckboxInput name="isPercentage" type="checkbox" />
              </div>
            </div>
            <div className="title-input minPrice">
              <h1>Mínimo valor:</h1>
              <TextInput
                name="minPrice"
                type="text"
                value={minPrice}
                onChange={(e) => {
                  formatNumberFields(e.target.value, 'minPrice');
                }}
              />
            </div>
            <div className="title-input date">
              <h1>Quantidade</h1>
              <TextInput
                name="maxUses"
                type="text"
                value={maxUses}
                onChange={(e) => {
                  formatNumberFields(e.target.value, 'maxUses');
                }}
              />
            </div>
          </Row>
          <SpotsButton type="submit" disabled={busy}>
            Criar Cupom
          </SpotsButton>
        </Form>
      </Content>
    </Container>
  );
};

export default AddVoucher;
