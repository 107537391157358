import React, { useState, useCallback, useEffect } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
  Circle,
  Polygon,
} from 'react-google-maps';

import { uuid } from 'uuidv4';

import { IDeliveryConfig } from '../../../../entities/DeliveryConfig';
import { IAddress } from '../../../../entities/Address';

import Title from '../../../../components/Title';

import {
  Container,
  EditPolygon,
  EditButton,
  ActionButtons,
  AlertResetArea,
  ContainerOpacity,
} from './styles';
import { useUnit } from '../../../../hooks/unit';

interface IDeliveryAreaData {
  lat: number;
  lng: number;
}

interface IProps {
  addressData?: IAddress;
  deliveryConfigData: IDeliveryConfig;
}

const Map: React.FC<IProps> = ({ addressData, deliveryConfigData }) => {
  const [startPolygon, setStartPolygon] = useState<boolean>(false);
  const [showAlertEditConfig, setShowAlertEditConfig] = useState<boolean>(
    false,
  );
  const [areaCoords, setAreaCoords] = useState<IDeliveryAreaData[]>([]);
  const colors = ['red', 'blue', 'yellow', 'orange', 'green', 'black'];
  const coords = {
    latitude: addressData?.location.coordinates[1],
    longitude: addressData?.location.coordinates[0],
  };

  const { editUnit } = useUnit();

  const handleClick = useCallback(
    (event) => {
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const coord = { lat, lng };
      if (startPolygon === true) {
        setAreaCoords((state) => [...state, coord]);
      }
    },
    [startPolygon],
  );

  const handleEditConfig = useCallback(() => {
    setStartPolygon(!startPolygon);
    if (deliveryConfigData.rates.length > 0) {
      setShowAlertEditConfig(true);
      setStartPolygon(false);
    }
  }, [deliveryConfigData.rates, startPolygon]);

  const handlePost = useCallback(async () => {
    const tmpDeliveryArea = areaCoords.map((point) => {
      return { latitude: point.lat, longitude: point.lng };
    });
    await editUnit({
      deliveryData: { deliveryArea: tmpDeliveryArea },
    });
    setStartPolygon(false);
  }, [editUnit, areaCoords]);

  useEffect(() => {
    const baga = deliveryConfigData?.area.map((area) => {
      return { lat: area.latitude, lng: area.longitude };
    });
    setAreaCoords([...baga]);
  }, [deliveryConfigData]);

  return (
    <Container>
      <EditPolygon>
        {!startPolygon ? (
          <EditButton isActive type="button" onClick={handleEditConfig}>
            Editar área de entrega
          </EditButton>
        ) : (
          <>
            <EditButton
              isActive={false}
              type="button"
              onClick={() => {
                setStartPolygon(false);
              }}
            >
              Sair da edição
            </EditButton>
            <ActionButtons>
              <EditButton
                isActive
                type="button"
                onClick={() => {
                  areaCoords.pop();
                  setAreaCoords([...areaCoords]);
                }}
              >
                Apagar ultimo valor
              </EditButton>

              <EditButton
                isActive
                type="button"
                onClick={() => {
                  setAreaCoords([]);
                }}
              >
                Resetar área
              </EditButton>

              <EditButton
                isActive
                type="button"
                onClick={handlePost}
                style={{
                  background: '#f58a07',
                  border: 'none',
                }}
              >
                Salvar área
              </EditButton>
            </ActionButtons>
          </>
        )}
      </EditPolygon>
      <GoogleMap
        defaultZoom={13}
        defaultCenter={{
          lat: coords.latitude ? coords.latitude : -26.9078977,
          lng: coords.longitude ? coords.longitude : -48.6697622,
        }}
        onClick={(e) => handleClick(e)}
      >
        <Marker
          position={{
            lat: coords.latitude ? coords.latitude : -26.9078977,
            lng: coords.longitude ? coords.longitude : -48.6697622,
          }}
        />
        <Polygon
          path={areaCoords}
          editable={false}
          options={{
            strokeColor: '#FF0000',
            strokeOpacity: 0.9,
            strokeWeight: 1,
            fillColor: '#FF0000',
            fillOpacity: 0.1,
          }}
        />
        {deliveryConfigData?.rates &&
          deliveryConfigData?.rates.map((rate) => {
            return (
              <Circle
                key={uuid()}
                defaultCenter={{
                  lat: coords.latitude ? coords.latitude : -26.9078977,
                  lng: coords.longitude ? coords.longitude : -48.6697622,
                }}
                radius={parseFloat(rate.radius.toString())}
                options={{
                  fillOpacity: 0,
                  strokeColor: colors[deliveryConfigData.rates.indexOf(rate)],
                  strokeOpacity: 0.7,
                }}
              />
            );
          })}
      </GoogleMap>

      {showAlertEditConfig ? (
        <>
          <ContainerOpacity
            onClick={() => {
              setShowAlertEditConfig(false);
            }}
          />
          <AlertResetArea>
            <Title title="Resetar área de entrega" />
            <div className="padding-container">
              <h1>
                Para editar sua área de entrega, todos os seus raios de entrega
                serão resetados
              </h1>
              <div className="btn-container">
                <button
                  style={{ background: 'red' }}
                  type="button"
                  onClick={() => {
                    setShowAlertEditConfig(false);
                  }}
                >
                  Recusar
                </button>
                <button
                  style={{ background: 'green' }}
                  type="button"
                  onClick={async () => {
                    await editUnit({
                      deliveryData: { deliveryRates: [] },
                    });
                    setShowAlertEditConfig(false);
                    window.location.reload(false);
                  }}
                >
                  Continuar
                </button>
              </div>
            </div>
          </AlertResetArea>
        </>
      ) : null}
    </Container>
  );
};

export default withScriptjs(withGoogleMap(Map));
