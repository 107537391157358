import React from 'react';
import { Container, NavButton } from './styles';

interface IProps {
  onClick(): void;
  isActivated: boolean;
  title: string;
}

const NavComponent: React.FC<IProps> = ({ onClick, isActivated, title }) => {
  return (
    <Container>
      <NavButton onClick={onClick} isActivated={isActivated} type="button">
        {title}
      </NavButton>
    </Container>
  );
};

export default NavComponent;
