/* eslint-disable no-bitwise */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { parseISO, isWithinInterval, endOfDay } from 'date-fns';
import getValidationErrors from '../../utils/getValidationErrors';

import CardDashboard from './components/CardDashboard';
import Container from '../../components/Container';

import MonthChart from './components/MonthChart';
import WeekChart from './components/WeekChart';

import { Data, Graphics, Filter } from './styles';
import { useBusiness } from '../../hooks/business';
import { IOrder } from '../../entities/Order';
import TextInput from '../../components/inputs/TextInput';
import SpotsButton from '../../components/buttons/SpotsButton';

interface IForm {
  startDate: string;
  endDate: string;
}

const Dashboard: React.FC = () => {
  // ref
  const formRef = useRef<FormHandles>(null);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();

  // State
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [filteredOrders, setFilteredOrders] = useState<IOrder[]>(
    currentUnit.orders,
  );

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const getTotal = useCallback((order: IOrder): number => {
    let discount = 0;

    if (order.voucher) {
      const { voucher } = order;

      if (voucher.isPercentage) {
        discount = order.subTotalPrice * (voucher.discount / 100);
      } else {
        discount = voucher.discount;
      }
    }

    const total = order.subTotalPrice + (order.deliveryFee || 0) - discount;

    return total || 0;
  }, []);

  // Logic
  const handleForm = useCallback(
    async (data: IForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          startDate: Yup.date().required('Insira a data inícial'),
          endDate: Yup.date().required('Insira a data final'),
        });

        if (data.startDate === '') {
          delete data.startDate;
        }
        if (data.endDate === '') {
          delete data.endDate;
        }

        await schema.validate(data, {
          abortEarly: false,
        });
        setFilteredOrders(() => {
          return currentUnit.orders.filter((order) => {
            return (
              order.status.finished &&
              isWithinInterval(
                parseISO((order.status.finished as unknown) as string),
                {
                  start: parseISO(data.startDate),
                  end: endOfDay(parseISO(data.endDate)),
                },
              )
            );
          });
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
      }
    },
    [currentUnit.orders],
  );

  return (
    <>
      <Container isOpen="dashboard" isLoaded={isLoaded}>
        <Filter>
          <Form ref={formRef} onSubmit={handleForm}>
            <TextInput type="date" name="startDate" />
            <TextInput type="date" name="endDate" />
            <SpotsButton disabled={false} type="submit">
              Filtrar
            </SpotsButton>
          </Form>
        </Filter>
        <Data>
          <CardDashboard
            icon="cart"
            value={
              filteredOrders?.length > 0
                ? filteredOrders
                    .filter((order) => order.status.finished)
                    .length.toString()
                : '0'
            }
            title="Total de pedidos"
            colorLine="line yellow"
          />
          <CardDashboard
            icon="motor"
            value={
              filteredOrders?.length > 0
                ? filteredOrders
                    .filter(
                      (order) =>
                        order.isDelivery === true && order.status.finished,
                    )
                    .length.toString()
                : '0'
            }
            title="Delivery"
            colorLine="line blue"
          />
          <CardDashboard
            icon="utensils"
            value={
              filteredOrders?.length > 0
                ? filteredOrders
                    .filter(
                      (order) =>
                        order.isDelivery === false && order.status.finished,
                    )
                    .length.toString()
                : '0'
            }
            title="Retiradas"
            colorLine="line aqualina"
          />
          <CardDashboard
            icon="dollar"
            value={filteredOrders
              ?.filter(
                (order) => !order.status.cancelled && order.status.finished,
              )
              .map((order) => getTotal(order))
              .reduce((p, c) => p + c, 0)
              .toFixed(2)}
            title="Faturamento"
            colorLine="line green"
          />
          <CardDashboard
            icon="tag"
            value={
              filteredOrders?.filter(
                (order) => !order.status.cancelled && order.status.finished,
              ).length === 0
                ? '0.00'
                : (
                    filteredOrders
                      ?.filter(
                        (order) =>
                          !order.status.cancelled && order.status.finished,
                      )
                      .map((order) => getTotal(order))
                      .reduce((p, c) => p + c, 0) /
                    filteredOrders?.filter(
                      (order) =>
                        !order.status.cancelled && order.status.finished,
                    ).length
                  ).toFixed(2)
            }
            title="Ticket Médio"
            colorLine="line blue"
          />
          <CardDashboard
            icon="close"
            value={filteredOrders
              ?.filter((order) => order.status.cancelled)
              .length.toString()}
            title="Cancelamentos"
            colorLine="line red"
          />
        </Data>
        <Graphics>
          <MonthChart
            monthData={[
              ['MÊS', 'DELIVERY'],
              [
                'Ago',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 7
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Set',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 8
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Out',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 9
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Nov',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 10
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Dez',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 10
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Jan',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 0
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Fev',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 1
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Mar',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 2
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Abr',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 3
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Mai',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 4
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Jun',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 5
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Jul',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getMonth() === 7
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
            ]}
          />
          <WeekChart
            weekData={[
              ['orders', 'Day'],
              [
                'Segunda',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 1
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Terça',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 2
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Quarta',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 3
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Quinta',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 4
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Sexta',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 5
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Sábado',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 6
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
              [
                'Domingo',
                currentUnit.orders
                  ?.filter((order) =>
                    order.status.finished
                      ? new Date(order.status.received).getDay() === 6
                      : null,
                  )
                  .map((order) => getTotal(order))
                  .reduce((total, current) => total + current, 0),
              ],
            ]}
          />
        </Graphics>
      </Container>
    </>
  );
};

export default Dashboard;
