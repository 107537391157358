import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import { useAuth } from '../../hooks/auth';

import TextInput from '../../components/inputs/TextInput';
import { Container, Content } from './styles';

import Logo from '../../assets/logospots.png';
import getValidationErrors from '../../utils/getValidationErrors';
import SpotsButton from '../../components/buttons/SpotsButton';

interface IFormData {
  email: string;
  password: string;
  username: string;
}

const SignIn: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // States
  const [busy, setBusy] = useState(false);

  // Hooks
  const { signIn } = useAuth();

  // Logic
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      setBusy(true);
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          username: Yup.string().required('Insira o usúario'),
          password: Yup.string().required('Senha obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn(data).catch(() => {
          setBusy(false);
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },

    [signIn],
  );

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextInput placeholder="E-mail" name="email" type="text" />
          <TextInput placeholder="Usuário" name="username" type="text" />
          <TextInput placeholder="Senha" name="password" type="password" />
          <SpotsButton type="submit" disabled={busy}>
            Entrar
          </SpotsButton>
        </Form>
        <div className="bottom-btn">
          <Link to="/signup">
            <button type="button">Cadastrar meu restaurante</button>
          </Link>
        </div>
      </Content>
    </Container>
  );
};

export default SignIn;
