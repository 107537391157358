/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FormHandles, Scope } from '@unform/core';
import * as Yup from 'yup';

import { Form } from '@unform/web';
import { uuid } from 'uuidv4';
import { Container, Content, Title, Nav, ItenBtn } from './styles';

import NavComponent from './components/NavComponent';
import ComplementsForm from './components/ComplementsForm';
import GeneralInfoForm from './components/GeneralInfoForm';
import SubCategoryForm from './components/SubCategoryForm';
import SubItemForm from './components/SubItemForm';
import getValidationErrors from '../../../utils/getValidationErrors';
import SpotsButton from '../../../components/buttons/SpotsButton';
import { IItem } from '../../../entities/Item';
import { ISubCategory } from '../../../entities/SubCategory';

interface IProps {
  initialData?: Omit<IItem, 'id' | 'photo'>;
  handleSubmit(data: IFormProps): Promise<void>;
}
interface IFormProps {
  name: string;
  description?: string;
  priority?: number;
  price: number;
  tag?: string;
  isFinalPrice: boolean;
  subCategories: ISubCategory[];
}
const ItemForm: React.FC<IProps> = ({ handleSubmit, initialData }) => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [navSelection, toggleNavSelection] = useState(true);
  const [busy, setBusy] = useState(false);
  const [formState, setFormState] = useState<Omit<IItem, 'id' | 'photo'>>({
    description: '',
    isFinalPrice: false,
    name: '',
    price: 0,
    subCategories: [],
    priority: 0,
    tag: '',
    isActive: true,
  });

  useEffect(() => {
    if (initialData) {
      setFormState({ ...initialData, isFinalPrice: !initialData.isFinalPrice });
    }
  }, [initialData]);

  useEffect(() => {
    formRef.current?.setData(formState);
  }, [formState, formRef, navSelection, busy]);

  const handleSubmitForm = useCallback(
    async (data: Omit<IItem, 'id'>) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          description: Yup.string(),
          priority: Yup.number().required('Prioridade obrigatória').default(0),
          price: Yup.number()
            .transform((currentValue, originalValue) => {
              return parseFloat(originalValue.replace(',', '.'));
            })
            .required('Preço obrigtório'),
          tag: Yup.string().transform((currentValue, originalValue) => {
            if (originalValue === null) return '';
            return originalValue;
          }),
          subCategories: Yup.array(
            Yup.object().shape({
              name: Yup.string().required('Nome obrigatório'),
              minChoices: Yup.number()
                .when(
                  'choiceType',
                  (choiceType: string, field: Yup.NumberSchema) => {
                    if (choiceType === 'RADIO') {
                      return field.transform(() => 1);
                    }
                    return field.min(0);
                  },
                )
                .max(
                  Yup.ref('maxChoices'),
                  'Não pode ser maior que a Qtd. Máxima',
                ),
              maxChoices: Yup.number()
                .when(
                  'choiceType',
                  (choiceType: string, field: Yup.NumberSchema) => {
                    if (choiceType === 'RADIO') {
                      return field.transform(() => 1);
                    }
                    return field.max(99);
                  },
                )
                .min(
                  Yup.ref('minChoices'),
                  'Não pode ser menor que a Qtd. Mínima',
                ),
              priority: Yup.number().default(0),
              choiceType: Yup.string()
                .oneOf(['RADIO', 'CHECKBOX', 'STACK'])
                .required(),
              subItems: Yup.array(
                Yup.object().shape({
                  name: Yup.string().required('Nome obrigatório'),
                  price: Yup.number()
                    .transform((currentValue, originalValue) => {
                      return parseFloat(originalValue.replace(',', '.'));
                    })
                    .required('Preço obrigatório'),
                  priority: Yup.number()
                    .required('Prioridade obrigatória')
                    .default(0),
                  description: Yup.string(),
                }),
              ).default([]),
            }),
          ).default([]),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setFormState(data);
        setBusy(true);
        const baga = { ...data };
        baga.isFinalPrice = !data.isFinalPrice;
        await handleSubmit(baga);
        setBusy(false);
      } catch (e) {
        if (e instanceof Yup.ValidationError) {
          const errors = getValidationErrors(e);
          formRef.current?.setErrors(errors);
          setBusy(false);
        }
        // eslint-disable-next-line no-alert
        alert('Preencha todos os campos obrigatórios');

        setBusy(false);
      }
    },
    [handleSubmit],
  );

  return (
    <Container>
      <Content>
        <Title>
          <h1>Adicionar novo produto</h1>
        </Title>
        <Form ref={formRef} onSubmit={handleSubmitForm}>
          <Nav>
            <NavComponent
              title="Informações Gerais"
              isActivated={navSelection}
              onClick={() => {
                const tmpFormState = formRef.current?.getData() as Omit<
                  IItem,
                  'id' | 'photo'
                >;
                setFormState(tmpFormState);
                toggleNavSelection(true);
              }}
            />
            <NavComponent
              title="Complementos"
              isActivated={!navSelection}
              onClick={() => {
                const tmpFormState = formRef.current?.getData() as Omit<
                  IItem,
                  'id' | 'photo'
                >;
                setFormState(tmpFormState);
                toggleNavSelection(false);
              }}
            />
          </Nav>
          <GeneralInfoForm isActivated={navSelection} formRef={formRef} />
          <ComplementsForm
            isActivated={!navSelection}
            addSubCategory={() => {
              const newFormState = {
                ...(formRef.current?.getData() as Omit<IItem, 'id' | 'photo'>),
              };
              if (newFormState.subCategories === undefined) {
                newFormState.subCategories = [];
              }
              newFormState.subCategories.push({
                choiceType: 'RADIO',
                maxChoices: 1,
                minChoices: 1,
                name: '',
                priority: 0,
                subItems: [
                  {
                    name: '',
                    description: '',
                    price: 0,
                    priority: 0,
                  },
                ],
              });
              setFormState(newFormState);
            }}
          >
            {formState.subCategories?.map((subCategory, index) => (
              <Scope key={uuid()} path={`subCategories[${index}]`}>
                <SubCategoryForm
                  choice={formState.subCategories[index].choiceType}
                  switchChoiceType={(e) => {
                    const newFormState = {
                      ...(formRef.current?.getData() as Omit<
                        IItem,
                        'id' | 'photo'
                      >),
                    };
                    newFormState.subCategories[index].choiceType =
                      e.target.value;

                    if (e.target.value === 'RADIO') {
                      newFormState.subCategories[index].minChoices = 1;
                      newFormState.subCategories[index].maxChoices = 1;
                    }

                    setFormState(newFormState);
                  }}
                  removeSubCategory={() => {
                    const tmpFormState = formRef.current?.getData() as Omit<
                      IItem,
                      'id' | 'photo'
                    >;
                    tmpFormState.subCategories.splice(index, 1);
                    setFormState(tmpFormState);
                  }}
                >
                  {subCategory.subItems?.map((subItem, subItemIndex) => (
                    <Scope key={uuid()} path={`subItems[${subItemIndex}]`}>
                      <SubItemForm
                        subCategoryIndex={index}
                        subItemIndex={subItemIndex}
                        formRef={formRef}
                        handleRemove={() => {
                          const tmpFormState = formRef.current?.getData() as Omit<
                            IItem,
                            'id' | 'photo'
                          >;
                          tmpFormState.subCategories[index].subItems.splice(
                            subItemIndex,
                            1,
                          );
                          setFormState(tmpFormState);
                        }}
                      />
                    </Scope>
                  ))}
                  <ItenBtn
                    type="button"
                    onClick={() => {
                      const newFormState = {
                        ...(formRef.current?.getData() as Omit<
                          IItem,
                          'id' | 'photo'
                        >),
                      };
                      if (
                        newFormState.subCategories[index].subItems === undefined
                      ) {
                        newFormState.subCategories[index].subItems = [];
                      }
                      newFormState.subCategories[index].subItems.push({
                        name: '',
                        description: '',
                        price: 0,
                        priority: 0,
                      });
                      setFormState(newFormState);
                    }}
                  >
                    Adicionar item
                  </ItenBtn>
                </SubCategoryForm>
              </Scope>
            ))}
          </ComplementsForm>
          <div className="btn">
            <SpotsButton type="submit" disabled={busy}>
              {initialData ? <>Editar</> : <>Cadastrar</>}
            </SpotsButton>
          </div>
        </Form>
      </Content>
    </Container>
  );
};

export default ItemForm;
