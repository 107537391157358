import styled, { css, keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Notifies = styled.div`
  border: 1px solid #e3e6f0;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  background: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #bbb;
  padding-bottom: 30px;
  animation: ${appearContainer} 1s;

  form {
    padding: 40px;
    .line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      p {
        font-size: 14px;
        margin-bottom: 5px;
      }
      & + div {
        margin-top: 20px;
      }
    }
    .title {
      width: 35%;
    }
    .description {
      width: 60%;
    }
    .time {
      width: 30%;
    }
    .date {
      width: 30%;
      margin-right: 10px;
    }
    .secound-line {
      display: flex;
      flex-direction: row;
      p {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .btn-width {
      margin-top: 20px;
      width: 150px;
    }
  }
`;

interface ButtonProps {
  isSelected: boolean;
}

export const Schedule = styled.button<ButtonProps>`
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: inherit;
  margin: 20px;
  width: 150px;

  p {
    font-size: 12px;
  }
  div {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 1px solid #333;
    background-color: #fff;
    margin-right: 5px;
  }
  ${(props) =>
    props.isSelected &&
    css`
      div {
        background-color: #f58a07;
        border: 1px solid #f58a07;
      }
    `}
`;

export const NotifiesTable = styled.table`
  width: 94%;
  margin: 0 auto;
  border-collapse: collapse;
  thead {
    tr {
      th {
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        padding-bottom: 15px;
      }
    }
  }
  .title-th {
    width: 30%;
    p {
      text-align: left;
    }
  }
  .message-th {
    width: 40%;
  }
  .create-th {
    width: 25%;
  }
`;

export const Notified = styled.tbody`
  tr {
    th {
      border-bottom: 1px solid #ccc;
      padding: 15px 0;
      p {
        padding: 0 20px;
        font-size: 14px;
        font-weight: 100;
      }
    }
  }
`;

export const EmojiBtn = styled.button`
  background: yellowgreen;
  color: #fff;
  font-size: 700;
  padding: 5px;
  border: none;
  border-radius: 3px;
`;
