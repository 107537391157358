import React, { useState } from 'react';

import { FaStar } from 'react-icons/fa';

import ContainerOpacity from '../../../../components/ContainerOpacity';

import { Container } from './styles';
import { IReview } from '../../../../entities/Review';
import { useBusiness } from '../../../../hooks/business';
import OrderInfo from '../OrderInfo';

interface IProps {
  index: number;
  review: IReview;
}

const RateStatus: React.FC<IProps> = ({ index, review }) => {
  const [showOrdeInfo, setShowOrderInfo] = useState<boolean>(false);
  const { currentUnit } = useBusiness();
  return (
    <Container isColored={!(index % 2)}>
      <tr className="tr-body">
        <td className="td-body client">
          {
            currentUnit.clients.find((user) => user.id === review.client)
              ?.nickname
          }
        </td>
        <td className="td-body phone">
          {currentUnit.clients.find((user) => user.id === review.client)?.phone}
        </td>
        <td className="td-body comment">{review.comment}</td>
        <td className="td-body rating">
          <div>
            <FaStar size={15} />
            {review.rating}
          </div>
        </td>
        <td className="td-body order">
          <button
            type="button"
            onClick={() => {
              setShowOrderInfo(true);
            }}
          >
            {`#${
              currentUnit.orders.find((order) => order.id === review.order.id)
                ?.orderNumber
            }`}
          </button>
        </td>
      </tr>
      {showOrdeInfo ? (
        <>
          <OrderInfo
            order={currentUnit.orders.find(
              (order) => order.id === review.order.id,
            )}
            HandleHideModal={() => {
              setShowOrderInfo(false);
            }}
          />
          <ContainerOpacity
            onClick={() => {
              setShowOrderInfo(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default RateStatus;
