import React, { useState, useEffect, useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { isBefore, format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';
import { FiX, FiPlus } from 'react-icons/fi';

import Container from '../../components/Container';
import Title from '../../components/Title';
import AddVoucher from './AddVoucher';

import {
  VoucherContainer,
  VoucherTable,
  Vouchers,
  OpacityContainer,
} from './styles';
import { useUnit } from '../../hooks/unit';
import { useBusiness } from '../../hooks/business';

const Voucher: React.FC = () => {
  // State
  const [isLoaded, setIsLoaded] = useState(false);
  const [showAddVoucher, setShowAddVoucher] = useState(false);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();
  const { expireVoucher } = useUnit();

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  const statusVoucher = useCallback((start, expire) => {
    if (isBefore(new Date(), start)) {
      return { label: 'Não começou', color: 'lightblue' };
    }
    if (isBefore(start, new Date()) && isBefore(new Date(), expire)) {
      return { label: 'Em andamento', color: 'acquarine' };
    }
    return { label: 'Expirado', color: 'lightgrey' };
  }, []);

  return (
    <Container isOpen="vouchers" isLoaded={isLoaded}>
      <VoucherContainer>
        <Title title="Cupons" />
        <div>
          <button
            type="button"
            onClick={() => {
              setShowAddVoucher(true);
            }}
          >
            <FiPlus /> <p>Criar cupom</p>
          </button>
        </div>
        <VoucherTable>
          <thead>
            <tr>
              <th className="name-th">Nome</th>
              <th className="price-th">Valor</th>
              <th className="qtd-th">Quantidade</th>
              <th className="used-th">Usados</th>
              <th className="start-th">Começa em: </th>
              <th className="expires-th">Expira em: </th>
              <th className="cancel-th">Status</th>
            </tr>
          </thead>
          {currentUnit.vouchers &&
            currentUnit.vouchers.map((voucher) => {
              return (
                <Vouchers key={voucher.id}>
                  <tr>
                    <th className="name-th">
                      <p>{voucher.title}</p>
                    </th>
                    <th className="price-th">
                      <p>
                        {voucher.isPercentage
                          ? `${voucher.discount}%`
                          : `R$${voucher.discount}`}
                      </p>
                    </th>
                    <th className="qtd-th">
                      <p>{voucher.maxUses}</p>
                    </th>
                    <th className="used-th">
                      <p>{`${voucher.usedBy.length} `}</p>
                    </th>
                    <th className="start-th">
                      <p>
                        {`${format(new Date(voucher.startsAt), `d'/'M'/'Y'`, {
                          locale: ptLocale,
                        })}`}
                      </p>
                    </th>
                    <th className="expires-th">
                      <p>
                        {`${format(new Date(voucher.expiresAt), `d'/'M'/'Y'`, {
                          locale: ptLocale,
                        })}`}
                      </p>
                    </th>
                    <th className="cancel-th">
                      <button type="button">
                        <p
                          style={{
                            background: statusVoucher(
                              new Date(voucher.startsAt),
                              new Date(voucher.expiresAt),
                            ).color,
                          }}
                        >
                          {
                            statusVoucher(
                              new Date(voucher.startsAt),
                              new Date(voucher.expiresAt),
                            ).label
                          }
                        </p>
                        {statusVoucher(
                          new Date(voucher.startsAt),
                          new Date(voucher.expiresAt),
                        ).label !== 'Concluído' &&
                        statusVoucher(
                          new Date(voucher.startsAt),
                          new Date(voucher.expiresAt),
                        ).label !== 'Expirado' ? (
                          <FiX
                            onClick={async () => {
                              expireVoucher(voucher.id);
                            }}
                          />
                        ) : null}
                      </button>
                    </th>
                  </tr>
                </Vouchers>
              );
            })}
        </VoucherTable>
      </VoucherContainer>
      {showAddVoucher && <AddVoucher setShowAddVoucher={setShowAddVoucher} />}
      <OpacityContainer
        showAddVoucher={showAddVoucher}
        onClick={() => {
          setShowAddVoucher(false);
        }}
      />
    </Container>
  );
};

export default Voucher;
