import React, { useState, useEffect } from 'react';
import { FiPlus } from 'react-icons/fi';

import Container from '../../components/Container';

import CategoryTile from './components/CategoryTile';

import { Button } from './styles';

import ContainerOpacity from '../../components/ContainerOpacity';
import CategoryForm from './components/CategoryForm';
import { useBusiness } from '../../hooks/business';
import ItemTile from './ItemTile';

const Menu: React.FC = () => {
  // State
  const [isLoaded, setIsLoaded] = useState(false);
  const [showForm, setShowForm] = useState(false);

  // Hooks
  const { currentUnit, getBusiness } = useBusiness();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {
    getBusiness().then(() => setIsLoaded(true));
  }, [getBusiness]);

  return (
    <Container isOpen="addItem" isLoaded={isLoaded}>
      <Button
        type="button"
        onClick={() => {
          setShowForm(true);
        }}
      >
        <FiPlus size={20} />
        Adicionar uma Categoria
      </Button>
      {currentUnit.menu.map((category) => {
        return (
          <CategoryTile
            category={category}
            key={category.id}
            setShowForm={setShowForm}
          >
            {category.items &&
              category.items.map((item) => {
                return (
                  <ItemTile
                    categoryId={category.id}
                    key={item.id}
                    item={item}
                  />
                );
              })}
          </CategoryTile>
        );
      })}
      {showForm && (
        <>
          <CategoryForm setShowForm={setShowForm} />
          <ContainerOpacity
            onClick={() => {
              setShowForm(false);
            }}
          />
        </>
      )}
    </Container>
  );
};

export default Menu;
