import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiSave } from 'react-icons/fi';

import { FormHandles } from '@unform/core';

import * as Yup from 'yup';

import { Form } from '@unform/web';

import RestaurantDataComponent from './components/RestaurantData';

import Container from '../../components/Container';

import {
  Info,
  Section,
  SaveAll,
  DeleteUnitBtn,
  AlertDeleteUnit,
} from './styles';
import PaymentMethods from './components/PaymentMethods';
import getValidationErrors from '../../utils/getValidationErrors';
import SpotsButton from '../../components/buttons/SpotsButton';
import { useBusiness } from '../../hooks/business';
import { useUnit } from '../../hooks/unit';
import WorkingHoursForm from './components/WorkingHoursForm';
import ContainerOpacity from '../../components/ContainerOpacity';
import Title from '../../components/Title';
import TextInput from '../../components/inputs/TextInput';

interface IPaymentMethodsData {
  label: string;
  value: boolean;
  cardBrand:
    | 'Visa'
    | 'MasterCard'
    | 'Elo'
    | 'AmericanExpress'
    | 'HiperCard'
    | 'Banricompras'
    | 'Diners'
    | 'Goodcard'
    | 'Verdecard'
    | 'Nugo'
    | 'Sodexo'
    | 'Alelo'
    | 'VR'
    | 'Ticket'
    | 'Dinheiro';
}

const EditInfo: React.FC = () => {
  // Form
  const formRef = useRef<FormHandles>(null);
  const alertFormRef = useRef<FormHandles>(null);
  // State
  const [isLoaded] = useState(true);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [paymentMethods, setPaymentMethods] = useState<IPaymentMethodsData[]>([
    {
      value: false,
      label: 'Visa-Débito',
      cardBrand: 'Visa',
    },
    {
      value: false,
      label: 'Visa-Crédito',
      cardBrand: 'Visa',
    },
    {
      value: false,
      label: 'MasterCard-Débito',
      cardBrand: 'MasterCard',
    },
    {
      value: false,
      label: 'MasterCard-Crédito',
      cardBrand: 'MasterCard',
    },
    {
      value: false,
      label: 'Banricompras-Débito',
      cardBrand: 'Banricompras',
    },
    {
      value: false,
      label: 'Banricompras-Crédito',
      cardBrand: 'Banricompras',
    },
    {
      value: false,
      label: 'Diners-Débito',
      cardBrand: 'Diners',
    },
    {
      value: false,
      label: 'Diners-Crédito',
      cardBrand: 'Diners',
    },
    {
      value: false,
      label: 'Goodcard-Débito',
      cardBrand: 'Goodcard',
    },
    {
      value: false,
      label: 'Goodcard-Crédito',
      cardBrand: 'Goodcard',
    },
    {
      value: false,
      label: 'Elo-Débito',
      cardBrand: 'Elo',
    },
    {
      value: false,
      label: 'Elo-Crédito',
      cardBrand: 'Elo',
    },
    {
      value: false,
      label: 'AmericanExpress-Débito',
      cardBrand: 'AmericanExpress',
    },
    {
      value: false,
      label: 'AmericanExpress-Crédito',
      cardBrand: 'AmericanExpress',
    },
    {
      value: false,
      label: 'HiperCard-Débito',
      cardBrand: 'HiperCard',
    },
    {
      value: false,
      label: 'HiperCard-Crédito',
      cardBrand: 'HiperCard',
    },
    {
      value: false,
      label: 'Verdecard-Débito',
      cardBrand: 'Verdecard',
    },
    {
      value: false,
      label: 'Verdecard-Crédito',
      cardBrand: 'Verdecard',
    },
    {
      value: false,
      label: 'Nugo-Débito',
      cardBrand: 'Nugo',
    },
    {
      value: false,
      label: 'Nugo-Crédito',
      cardBrand: 'Nugo',
    },
    {
      value: false,
      label: 'Sodexo-Alimentação',
      cardBrand: 'Sodexo',
    },
    {
      value: false,
      label: 'Alelo-Alimentação',
      cardBrand: 'Alelo',
    },
    {
      value: false,
      label: 'VR-Alimentação',
      cardBrand: 'VR',
    },
    {
      value: false,
      label: 'Ticket-Alimentação',
      cardBrand: 'Ticket',
    },
    {
      value: false,
      label: 'Dinheiro-Dinheiro',
      cardBrand: 'Dinheiro',
    },
  ]);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  // Hooks
  const { currentUnit, deleteUnit, role } = useBusiness();
  const { editUnit } = useUnit();

  // eslint-disable-next-line consistent-return
  const formattedPhoneNumber = useCallback((x) => {
    // recebe um +5547123456789 e retorna (47) 12345-6789
    if (x) {
      return `(${x.slice(3, 5)}) ${x.slice(5, 10)}-${x.slice(10, x.length)}`;
    }
  }, []);

  useEffect(() => {
    const restaurantPaymentMethods: IPaymentMethodsData[] = paymentMethods.map(
      (method) => {
        const paymentMethodExists = currentUnit?.paymentMethods?.find(
          (restaurantPaymentMethod) =>
            `${restaurantPaymentMethod.cardBrand}-${restaurantPaymentMethod.type}` ===
            method.label,
        );
        return {
          value: !!paymentMethodExists,
          label: method.label,
          cardBrand: method.cardBrand,
        };
      },
    );
    const paymentMethodsConvertedToObject = {};
    restaurantPaymentMethods.forEach((restaurantPaymentMethod) => {
      Object.assign(paymentMethodsConvertedToObject, {
        [restaurantPaymentMethod.label]: restaurantPaymentMethod.value,
      });
    });
    setPaymentMethods(restaurantPaymentMethods);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUnit]);

  const handleChangePaymentMethods = useCallback(
    (bagaIndex: number) => {
      const tmpBaga = [...paymentMethods];
      tmpBaga[bagaIndex].value = !tmpBaga[bagaIndex].value;
      setPaymentMethods(tmpBaga);
    },
    [paymentMethods],
  );

  const phoneSubmitValue = useCallback((phone: string) => {
    const telefone = `+55${phone
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .replace(' ', '')}`;

    return telefone;
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      setIsLoader(true);
      Object.keys(data.workingHours).forEach((baga) => {
        // eslint-disable-next-line no-param-reassign
        if (data.workingHours[baga] === '') data.workingHours[baga] = [];
      });

      let bagaman = paymentMethods
        .filter((method) => method.value)
        .map((met) => {
          return {
            cardBrand: met.cardBrand,
            type: met.label.split('-')[1],
          };
        });

      const index = bagaman.indexOf({
        type: 'Dinheiro',
        cardBrand: 'Dinheiro',
      });
      if (index >= 0) {
        bagaman.splice(index, 1);
        bagaman = [
          {
            type: 'Dinheiro',
            cardBrand: 'Dinheiro',
          },
          ...bagaman,
        ];
      }

      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          cnpj: Yup.string()
            .matches(
              /^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})$/,
              'Formato do CNPJ inválido',
            )
            .required('CNPJ obrigatório'),
          contactInfo: Yup.object().shape({
            phone: Yup.string()
              .matches(
                /^\(\d{2}\) ((\d{5})|(\d{4}))-(\d{4})$/,
                'Formato do telefone inválido',
              )
              .required('Telefone obrigatório'),
            whatsApp: Yup.string().matches(
              /^\(\d{2}\) ((\d{5})|(\d{4}))-(\d{4})$/,
              'Formato do telefone inválido',
            ),
          }),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        const { name, cnpj, colors, contactInfo, workingHours } = data;

        await editUnit({
          generalData: {
            paymentMethods: bagaman,
            name,
            cnpj,
            contactInfo: {
              phone: phoneSubmitValue(contactInfo.phone),
              whatsApp: phoneSubmitValue(contactInfo.whatsApp),
            },
            colors,
            workingHours,
          },
        });

        // id: localStorage.getItem('@SpotsDelivery:unitId'),
        setIsLoader(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          setIsLoader(false);
        }
      }
    },
    [editUnit, paymentMethods, phoneSubmitValue],
  );

  const handleDeleteUnit = useCallback(
    async (data: { unitName: string }) => {
      try {
        alertFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          unitName: Yup.string()
            .required('Insira o nome da unidade atual')
            .oneOf([`${currentUnit.name}`], 'Nome não coincide'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await deleteUnit(currentUnit.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          alertFormRef.current?.setErrors(errors);
        }
      }
    },
    [currentUnit.id, currentUnit.name, deleteUnit],
  );

  return (
    <Container isOpen="profile" isLoaded={isLoaded}>
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{
          name: currentUnit.name,
          cnpj: currentUnit.cnpj,
          contactInfo: {
            phone: formattedPhoneNumber(currentUnit.contactInfo.phone || ''),
            whatsApp: formattedPhoneNumber(
              currentUnit.contactInfo.whatsApp || '',
            ),
          },
          colors: currentUnit.colors,
          workingHours: currentUnit.workingHours,
        }}
      >
        <Info>
          <RestaurantDataComponent
            formRef={formRef}
            url={currentUnit?.logo?.url}
          />
          <Section>
            <WorkingHoursForm workingHours={currentUnit.workingHours} />

            <PaymentMethods
              paymentMethods={paymentMethods}
              onHandleBagaman={handleChangePaymentMethods}
            />
          </Section>
        </Info>
        <SaveAll>
          <SpotsButton type="submit" disabled={isLoader}>
            Salvar alterações <FiSave size={20} />
          </SpotsButton>
        </SaveAll>
      </Form>
      {role === 'admin' ? (
        <DeleteUnitBtn
          type="button"
          onClick={() => {
            setShowAlert(true);
          }}
        >
          Deletar unidade
        </DeleteUnitBtn>
      ) : null}
      {showAlert ? (
        <>
          <AlertDeleteUnit>
            <Title title="Remover unidade" />
            <Form ref={alertFormRef} onSubmit={handleDeleteUnit}>
              <div className="padding-container">
                <h1>Confirme o nome da unidade em que você deseja deletar</h1>
                <TextInput name="unitName" type="text" />
                <div className="btn-container">
                  <button
                    style={{ background: 'red' }}
                    type="button"
                    onClick={() => {
                      setShowAlert(false);
                    }}
                  >
                    Recusar
                  </button>
                  <button style={{ background: 'green' }} type="submit">
                    Confirmar
                  </button>
                </div>
              </div>
            </Form>
          </AlertDeleteUnit>
          <ContainerOpacity
            onClick={() => {
              setShowAlert(false);
            }}
          />
        </>
      ) : null}
    </Container>
  );
};

export default EditInfo;
