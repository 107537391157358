import styled from 'styled-components';

export const Container = styled.div`
  width: 15%;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15);
  border: 1px solid #e3e6f0;
  i {
    align-self: flex-end;
    padding-right: 10px;
    margin-bottom: 20px;
    color: #6c757d;
  }

  h1 {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
  }
  p {
    font-size: 12px;
    margin-bottom: 20px;
    color: #333;
    text-transform: uppercase;
  }
  .line {
    margin: 0 auto;
    height: 5px;
    width: 85%;
    border-radius: 5px;
  }
  .blue {
    background: blue;
  }
  .yellow {
    background: yellow;
  }
  .red {
    background: red;
  }
  .aqualina {
    background: aquamarine;
  }
  .green {
    background: green;
  }
  @media screen and (min-width: 1600px) {
    padding: 20px 0;
    i {
      padding-right: 20px;
      margin-bottom: 24px;
    }
    h1 {
      font-size: 18px;
      color: #333;
      margin-bottom: 24px;
      font-weight: 700;
    }
    p {
      font-size: 14px;
      margin-bottom: 24px;
      font-weight: 700;
    }
  }
`;
