import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Container, AddSubCategory } from './styles';

interface IProps {
  isActivated: boolean;
  addSubCategory(): void;
}

const ComplementsForm: React.FC<IProps> = ({
  isActivated,
  addSubCategory,
  children,
}) => {
  return (
    <Container isActivated={isActivated}>
      {children}
      <AddSubCategory type="button" onClick={addSubCategory}>
        <FiPlus size={20} />
        <h1>Adicionar Complemento</h1>
      </AddSubCategory>
    </Container>
  );
};

export default ComplementsForm;
