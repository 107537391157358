/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import ptLocale from 'date-fns/locale/pt-BR';

import { FiX } from 'react-icons/fi';

import { uuid } from 'uuidv4';
import {
  Container,
  InfoClientOrder,
  Order,
  Price,
  Description,
  OrderInformation,
  Table,
  SubItem,
} from './styles';
import { IOrder } from '../../../../entities/Order';
import { useBusiness } from '../../../../hooks/business';

interface IProps {
  order?: IOrder;
  HandleHideModal(): void;
}

const OrderInfo: React.FC<IProps> = ({ HandleHideModal, order }) => {
  const { currentUnit } = useBusiness();

  const formattedPhoneNumber = useCallback((x) => {
    // recebe um +5547123456789 e retorna (47) 12345-6789
    return `(${x.slice(3, 5)}) ${x.slice(5, 10)}-${x.slice(10, x.length)}`;
  }, []);

  const handleTotalPrice = useCallback(
    (voucherDiscount, subTotal, deliveryFee, isPercentage) => {
      if (voucherDiscount) {
        if (!isPercentage) {
          return `R$${(subTotal + deliveryFee - voucherDiscount)
            .toFixed(2)
            .replace('.', ',')}`;
        }
        return `R$${(
          subTotal +
          deliveryFee -
          ((subTotal * voucherDiscount) / 100 || 0)
        )
          .toFixed(2)
          .replace('.', ',')}`;
      }
      return `R$${(subTotal + deliveryFee).toFixed(2).replace('.', ',')}`;
    },
    [],
  );

  const handleIsPercentage = useCallback(
    (voucherDiscount, isPercentage, voucherTitle) => {
      if (voucherDiscount) {
        if (isPercentage) {
          return `%${voucherDiscount.toFixed(2).replace('.', ',')}`;
        }
        return `- R$${voucherDiscount.toFixed(2).replace('.', ',')}`;
      }
      if (voucherTitle) {
        return `${voucherTitle}`;
      }
      return '-R$0,00';
    },
    [],
  );

  return (
    <Container>
      <div className="headline">
        <i>
          <FiX
            size={20}
            onClick={() => {
              HandleHideModal();
            }}
          />
        </i>
      </div>
      <OrderInformation>
        {order ? (
          <>
            <div className="orders-headline">
              <h3>#{order?.orderNumber}</h3>
            </div>
            <InfoClientOrder>
              <div className="first-infoClintOrder-row">
                <Table isDescription={false}>
                  <div className="left">
                    <h2>Nome</h2>
                    <h1>
                      {
                        currentUnit.clients.find(
                          (client) => client.id === (order.client as unknown),
                        )?.nickname
                      }
                    </h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center">
                    <h2>Data do pedido</h2>
                    <h1>
                      {`${format(
                        new Date(order.status.received),
                        `d'/'M'/'Y 'às' H':'mm`,
                        {
                          locale: ptLocale,
                        },
                      )}`}
                    </h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center status">
                    <h2>Estado do pedido</h2>
                    <span
                      style={{
                        background: 'lightblue',
                        color: 'black',
                      }}
                    >
                      Finalizado
                    </span>
                  </div>
                </Table>
              </div>
              <div className="secound-infoClintOrder-row">
                <Table isDescription={false}>
                  <div className="left">
                    <h2>Telefone</h2>
                    <h1>
                      {formattedPhoneNumber(
                        currentUnit.clients.find(
                          (client) => client.id === (order.client as unknown),
                        )?.phone,
                      )}
                    </h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center">
                    <h2>CPF/CNPJ</h2>
                    <h1>
                      {order.invoiceDocument ? order.invoiceDocument : '-'}
                    </h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center">
                    <h2>Cep</h2>
                    <h1>{order.isDelivery ? order?.address?.cep : '-'}</h1>
                  </div>
                </Table>
              </div>
              <div className="third-infoClintOrder-row">
                <Table isDescription>
                  <div className="left">
                    <h2>Endereço</h2>
                    <h1>
                      {order.isDelivery
                        ? `${order?.address?.street} ${order?.address?.number} - ${order?.address?.city}`
                        : '-'}
                    </h1>
                  </div>
                </Table>
              </div>
              <div className="fourth-infoClintOrder-row">
                <Table isDescription={false}>
                  <div className="left">
                    <h2>Complemento</h2>
                    <h1>
                      {order?.address?.extraInfo
                        ? order?.address.extraInfo
                        : '-'}
                    </h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center">
                    <h2>Canal</h2>
                    <h1>{order.isDelivery ? 'Entrega' : 'Retirada'}</h1>
                  </div>
                </Table>
                <Table isDescription={false}>
                  <div className="center">
                    <h2>Forma de Pagamento</h2>
                    <h1>
                      {`${
                        order.paymentMethod?.type !== 'Dinheiro'
                          ? `${order.paymentMethod?.cardBrand} - `
                          : ''
                      }${order.paymentMethod?.type}`}
                    </h1>
                  </div>
                </Table>
              </div>
            </InfoClientOrder>
            {order?.items.map((item, index) => {
              return (
                <Order key={`${item.name}+${index}`}>
                  <div className="row-order">
                    <div className="first-order-column">
                      <Table isDescription={false}>
                        <div className="left">
                          <h2>qtd</h2>
                          <h1>{`${item.amount}x`}</h1>
                        </div>
                      </Table>
                    </div>
                    <div className="secound-order-column">
                      <Table isDescription={false}>
                        <div className="left">
                          <h2>Descrição</h2>
                          <h1>{item.name}</h1>
                        </div>
                      </Table>
                      <SubItem>
                        {item.subItems.map((subItem, i) => {
                          return (
                            <p key={uuid()}>
                              {`${subItem.amount}x ${subItem.name}`}
                            </p>
                          );
                        })}
                      </SubItem>
                    </div>
                    <div className="third-order-column">
                      <Table isDescription={false}>
                        <div className="right">
                          <h2>Preço</h2>
                          <h1>
                            {`${item.price.toFixed(2).replace('.', ',')} reais`}
                          </h1>
                        </div>
                      </Table>
                    </div>
                  </div>
                  <div className="observation">
                    <h1>Observações do item:</h1>
                    <p>{item.observations}</p>
                  </div>
                </Order>
              );
            })}
            <Price>
              <Description>
                <div className="observation">
                  <h1>Observações do pedido:</h1>
                  <p>{order?.observations}</p>
                </div>
              </Description>
              <div className="position">
                <div className="sub-total">
                  <h4>Subtotal</h4>
                  <h4>
                    {`R$${order?.subTotalPrice.toFixed(2).replace('.', ',')}`}
                  </h4>
                </div>
                <div className="voucher">
                  <h4>Cupom</h4>
                  <h4>
                    {handleIsPercentage(
                      order.voucher?.discount,
                      order.voucher?.isPercentage,
                      order.voucher?.title,
                    )}
                  </h4>
                </div>
                <div className="delivery">
                  <h4>Taxa de entrega</h4>
                  <h4>
                    {`R$${order?.deliveryFee?.toFixed(2).replace('.', ',')}`}
                  </h4>
                </div>
                <div className="spread" />
                <div className="total">
                  <h4>Total</h4>
                  <h4>
                    {handleTotalPrice(
                      order.voucher?.discount,
                      order.subTotalPrice,
                      order.deliveryFee,
                      order.voucher?.isPercentage,
                    ) || '0'}
                  </h4>
                </div>
                {order.changeFor ? (
                  <div className="change">
                    <h4>Troco para:</h4>
                    <h4>{`R$${order.changeFor.toString()},00`}</h4>
                  </div>
                ) : null}
              </div>
            </Price>
          </>
        ) : null}
      </OrderInformation>
    </Container>
  );
};

export default OrderInfo;
