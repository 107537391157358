import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import TextInput from '../../components/inputs/TextInput';
import { Container, Content } from './styles';

import Logo from '../../assets/logospots.png';
import { useBusiness } from '../../hooks/business';
import SpotsButton from '../../components/buttons/SpotsButton';

interface IFormData {
  name: string;
}

const FirstUnit: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // States
  const [busy, setBusy] = useState(false);

  // hooks
  const { createUnit } = useBusiness();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setBusy(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome da unidade obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await createUnit(data.name);

        setBusy(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },
    [createUnit],
  );

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />
        <h1>
          Cadastre o nome de uma unidade física do seu negócio para acessar o
          gerênciamento.
        </h1>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextInput placeholder="Nome da unidade" name="name" type="text" />
          <p>Exemplo: Estabelecimento - Cidade.</p>
          <SpotsButton disabled={busy} type="submit">
            Cadastrar
          </SpotsButton>
        </Form>
      </Content>
    </Container>
  );
};

export default FirstUnit;
