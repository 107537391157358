import React, { useEffect, useState } from 'react';

import { uuid } from 'uuidv4';

import { FaStar } from 'react-icons/fa';
import { RateContainer, Content, TableRates, RatingAverage } from './styles';

import Container from '../../components/Container';
import Title from '../../components/Title';
import RateStatus from './components/RateStatus';
import { useBusiness } from '../../hooks/business';

const Rates: React.FC = () => {
  const { currentUnit, getBusiness } = useBusiness();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    getBusiness().then((a) => setIsLoaded(true));
  }, [getBusiness]);

  return (
    <Container isOpen="rates" isLoaded={isLoaded}>
      <RateContainer>
        <Title title="Avaliações" />
        <Content>
          <RatingAverage>
            <h1>Média das avaliações:</h1>
            <FaStar size={15} />
            <h1>
              {currentUnit.reviews.length > 0
                ? currentUnit.reviews
                    .map((review) => review.rating)
                    .reduce((p, c) => p + c) / currentUnit.reviews.length
                : '0'}
            </h1>
          </RatingAverage>
          <TableRates>
            <thead>
              <tr className="tr-header">
                <td className="td-header client">Cliente</td>
                <td className="td-header phone">Telefone</td>
                <td className="td-header comment">Comentário</td>
                <td className="td-header rating">Avaliação</td>
                <td className="td-header order">Pedido</td>
              </tr>
            </thead>
            {currentUnit.reviews.map((review, index) => {
              return (
                <>
                  <RateStatus key={uuid()} index={index} review={review} />
                </>
              );
            })}
          </TableRates>
        </Content>
      </RateContainer>
    </Container>
  );
};

export default Rates;
