import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appear = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Container = styled.div`
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  width: 800px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px;
  animation: ${appear} 1s;
  z-index: 1000;
  margin-bottom: 50px;

  p {
    color: white;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  form {
    width: 100%;
    display: flex;
    padding: 0 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .btn {
      align-self: flex-end;
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 16px;
      button {
        width: 150px;
        border-radius: 3px;
        border: none;
        padding: 10px 16px;
        font-weight: 500;
        transition: background-color 0.2s;
        font-size: 14px;
      }
    }
  }
`;

export const Title = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f9fc;
  border-bottom: 1px solid #e3e6f0;
  padding: 15px 15px 15px 15px;
  h1 {
    font-size: 18px;
    font-weight: 100;
  }
  svg {
    &:hover {
      cursor: pointer;
    }
  }
`;

export const Nav = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  border-bottom: 1px solid #ccc;
`;

export const Button = styled.button`
  align-self: flex-end;
  width: 150px;
  background: #70bb35;
  border-radius: 3px;
  border: none;
  padding: 10px 16px;
  color: #fff;
  font-weight: 500;
  transition: background-color 0.2s;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 50px;

  &:hover {
    background: ${shade(0.2, '#70bb35')};
  }
`;

export const ItenBtn = styled.button`
  width: 100%;
  padding: 5px;
  text-align: center;
  background: #e3e6f0;
  color: rgba(29, 62, 139, 0.9);
  border: none;
  font-size: 14px;
`;
