import React, { useState, useCallback } from 'react';
import { FiX } from 'react-icons/fi';

import { FormHandles } from '@unform/core';
import { Container, SubItems, RemoveButton } from './styles';
import TextInput from '../../../../../components/inputs/TextInput';

interface IProps {
  subCategoryIndex: number;
  subItemIndex: number;
  formRef: React.RefObject<FormHandles>;
  handleRemove(): void;
}

const SubItemForm: React.FC<IProps> = ({
  handleRemove,
  formRef,
  subCategoryIndex,
  subItemIndex,
}) => {
  const [subItemPrice, setSubItemPrice] = useState<string>(() => {
    return formRef.current?.getFieldValue(
      `subCategories[${subCategoryIndex}].subItems[${subItemIndex}].price`,
    );
  });

  const formatPrice = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/^(0{1,})(\d)/, '$2')
      .replace(/(\d)(\d{2})$/, '$1,$2');

    setSubItemPrice(formattedString);
  }, []);

  return (
    <Container>
      <SubItems>
        <div className="SubItems-first-row">
          <div className="SubItems-name">
            <h1>Nome:</h1>
            <TextInput name="name" type="text" />
          </div>

          <div className="SubItems-priority">
            <h1>Ordenamento:</h1>
            <TextInput name="priority" type="text" />
          </div>
        </div>
        <div className="SubItems-secound-row">
          <div className="SubItems-description">
            <h1>Descrição (opcional):</h1>
            <TextInput name="description" type="text" />
          </div>
          <div className="SubItems-price">
            <h1>Preço:</h1>
            <TextInput
              placeholder="R$"
              name="price"
              type="text"
              value={subItemPrice || 0}
              onChange={(e) => {
                formatPrice(e.target.value);
              }}
            />
          </div>
          <RemoveButton onClick={handleRemove} type="button">
            <h1>Remover</h1>
            <FiX size={15} />
          </RemoveButton>
        </div>
      </SubItems>
    </Container>
  );
};

export default SubItemForm;
