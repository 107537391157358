import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import getValidationErrors from '../../utils/getValidationErrors';

import TextInput from '../../components/inputs/TextInput';
import { Container, Content } from './styles';

import Logo from '../../assets/logospots.png';

import SpotsButton from '../../components/buttons/SpotsButton';
import { useAuth } from '../../hooks/auth';

interface IFormData {
  invitation: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp: React.FC = () => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [busy, setBusy] = useState(false);

  // Hooks
  const { signUp } = useAuth();

  // Business Logic
  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setBusy(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string()
            .min(6, 'No mínimo 6 dígitos')
            .max(12, 'No máximo 12 dígitos'),
          invitation: Yup.string().required('Convite obrigatório'),
          confirmPassword: Yup.string()
            .required('Confirme sua senha')
            .oneOf([Yup.ref('password')], 'Senhas não coincidem'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signUp(data);

        setBusy(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },
    [signUp],
  );

  return (
    <Container>
      <Content>
        <img src={Logo} alt="logo" />

        <Form ref={formRef} onSubmit={handleSubmit}>
          <TextInput
            placeholder="Convite para cadastro"
            name="invitation"
            type="text"
          />
          <TextInput placeholder="E-mail" name="email" type="text" />
          <TextInput placeholder="Senha" name="password" type="password" />
          <TextInput
            placeholder="Confirmar senha"
            name="confirmPassword"
            type="password"
          />

          <SpotsButton disabled={busy} type="submit">
            Cadastrar
          </SpotsButton>
        </Form>
        <Link to="/">Voltar para a tela de login</Link>
      </Content>
    </Container>
  );
};

export default SignUp;
