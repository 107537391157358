import styled, { keyframes } from 'styled-components';

const appearContainer = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`;

export const Filter = styled.div`
  display: flex;
  align-items: flex-start;
  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
    div {
      height: 50px;
      width: 350px;
      margin: 0 10px 0 0;
      input {
        width: 80%;
      }
    }
    button {
      margin: 0;
      width: 100px;
    }
  }
`;

export const Data = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  animation: ${appearContainer} 1s;
  @media screen and (max-width: 1150px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 10px;
    grid-column-gap: 10px;
    div {
      width: 100%;
    }
  }
`;

export const Graphics = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  animation: ${appearContainer} 1s;
  @media screen and (max-width: 1150px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    div {
      width: 100%;
    }
  }
`;
