import React, { useState, useCallback, useEffect } from 'react';

import { Scope, FormHandles } from '@unform/core';

import TextInput from '../../../../components/inputs/TextInput';
import Title from '../../../../components/Title';
import Loader from '../../../../components/Loader';

import { Container, LogoInput, ColorsInput, Content } from './styles';
import { useUnit } from '../../../../hooks/unit';
import { useBusiness } from '../../../../hooks/business';

const RestaurantData: React.FC<{
  url?: string;
  formRef: React.RefObject<FormHandles>;
}> = ({ url, formRef }) => {
  // State
  const [image, setImage] = useState<string>();
  const [isLoader, setIsLoader] = useState<boolean>();

  // Hooks
  const { editLogo } = useUnit();
  const { currentUnit } = useBusiness();

  const handleUpdate = useCallback(
    async (file: File) => {
      try {
        setIsLoader(true);
        const fd = new FormData();
        fd.append('file', file);
        await editLogo(fd, currentUnit.id);
        setIsLoader(false);
      } catch (e) {
        setIsLoader(false);
      }
    },
    [currentUnit.id, editLogo],
  );

  const handleChange = useCallback(
    async (selectorFiles) => {
      await handleUpdate(selectorFiles[0]);
    },
    [handleUpdate],
  );

  useEffect(() => {
    setImage(url || '');
  }, [url]);

  const [phone, setPhone] = useState<string>(() => {
    return formRef.current?.getFieldValue('phone');
  });

  const formatPhone = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})(-)(\d)(\d{3})(\d)/, '$1$3-$4$5')
      .replace(/(-\d{4})\d+?$/, '$1');

    setPhone(formattedString);
  }, []);

  const [whats, setWhats] = useState<string>(() => {
    return formRef.current?.getFieldValue('phone');
  });

  const formatWhats = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(\d{4})(-)(\d)(\d{3})(\d)/, '$1$3-$4$5')
      .replace(/(-\d{4})\d+?$/, '$1');

    setWhats(formattedString);
  }, []);

  const [cnpj, setCnpj] = useState<string>(() => {
    return formRef.current?.getFieldValue('cnpj');
  });

  // 34.144.534/0001-03

  const formatCnpj = useCallback((value: string) => {
    const formattedString = value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{2}).(\d{3})(\d)/, '$1.$2.$3')
      .replace(/(\d{2}).(\d{3}).(\d{3})(\d)/, '$1.$2.$3/$4')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');

    setCnpj(formattedString);
  }, []);

  return (
    <Container>
      <Title title="Informações do meu estabelecimento" />
      <Content>
        <LogoInput>
          <div className="logo-content">
            {/* <p>Seu logo</p> */}
            {currentUnit.logo && (
              <div className="logo">
                {isLoader ? <Loader /> : <img src={image} alt="logo" />}
              </div>
            )}
            <div className="choose-logo">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="Logo">
                {!currentUnit.logo && isLoader ? (
                  <Loader />
                ) : (
                  <span>Escolha sua logo</span>
                )}
              </label>
              <input
                id="Logo"
                type="file"
                style={{ display: 'none' }}
                onChange={async (e) => {
                  if (e.target.files) {
                    await handleChange(e.target.files);
                  }
                }}
                accept="image/*"
              />
            </div>
          </div>
        </LogoInput>
        <h1>Nome do restaurante</h1>
        <TextInput name="name" type="text" />
        <h1>CNPJ</h1>
        <TextInput
          name="cnpj"
          type="text"
          value={cnpj}
          onChange={(e) => {
            formatCnpj(e.target.value);
          }}
        />
        <Scope path="contactInfo">
          <h1>Telefone</h1>
          <TextInput
            name="phone"
            type="text"
            value={phone}
            onChange={(e) => {
              formatPhone(e.target.value);
            }}
          />
          <h1>WhatsApp</h1>
          <TextInput
            name="whatsApp"
            type="text"
            value={whats}
            onChange={(e) => {
              formatWhats(e.target.value);
            }}
          />
        </Scope>

        <Scope path="colors">
          <ColorsInput>
            <h1 className="title-color">Cores para seu app</h1>
            <div>
              <h1>Cor Primária</h1>
              <TextInput name="primaryColor" type="color" />
              <h1>Cor Secundária</h1>
              <TextInput name="secondaryColor" type="color" />
            </div>
          </ColorsInput>
        </Scope>
      </Content>
    </Container>
  );
};

export default RestaurantData;
