import React, { useState, useCallback } from 'react';

import { startOfDay, endOfDay } from 'date-fns';
import Container from '../../components/Container';
import Title from '../../components/Title';

import Demand from './components/Demand';

import { OrdersContainer, TableOrders } from './styles';
import { useBusiness } from '../../hooks/business';

const Orders: React.FC = () => {
  const [isLoaded] = useState(true);

  const { currentUnit, role } = useBusiness();

  const handleShowOrder = useCallback(
    (finished?: Date) => {
      if (!finished) {
        return true;
      }

      if (role === 'employee') {
        return (
          new Date() >= startOfDay(new Date(finished)) &&
          new Date() <= endOfDay(new Date(finished))
        );
      }
      return true;
    },
    [role],
  );

  return (
    <Container isOpen="order" isLoaded={isLoaded}>
      <OrdersContainer>
        <Title title="Pedidos" />
        <TableOrders>
          <thead>
            <tr className="tr-header">
              <td className="td-header">Pedido</td>
              <td className="td-header">Canal</td>
              <td className="td-header">Status</td>
              <td className="td-header">Data</td>
              <td className="td-header">Cliente</td>
              <td className="td-header">Telefone</td>
              <td className="td-header">Pagamento</td>
              <td className="td-header">Total</td>
              <td className="td-header">Cupom</td>
              <td className="td-header">Detalhes</td>
            </tr>
          </thead>

          {currentUnit.orders.map(
            (order, index) =>
              handleShowOrder(order.status.finished) && (
                <Demand key={order.id} index={index} order={order} />
              ),
          )}
        </TableOrders>
      </OrdersContainer>
    </Container>
  );
};

export default Orders;
