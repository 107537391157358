import React, { useRef, useState, useCallback } from 'react';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { Form } from '@unform/web';

import getValidationErrors from '../../../../utils/getValidationErrors';

import { Container, Content, Row, Permissions } from './styles';
import Title from '../../../../components/Title';
import TextInput from '../../../../components/inputs/TextInput';
import RadioInputPermission from '../../../../components/inputs/RadioInputPermission';
import SpotsButton from '../../../../components/buttons/SpotsButton';
import { ICreateAccount } from '../../../../dtos/CreateAccount';
import { useBusiness } from '../../../../hooks/business';

interface IProps {
  setShowAddAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddAccount: React.FC<IProps> = ({ setShowAddAccount }) => {
  // Refs
  const formRef = useRef<FormHandles>(null);

  // State
  const [unitSelected, setUnitSelected] = useState('');
  const [busy, setBusy] = useState(false);

  // Hooks
  const { business, createAccount } = useBusiness();

  const handleSubmit = useCallback(
    async (data: ICreateAccount) => {
      try {
        setBusy(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          username: Yup.string()
            .required('Escolha um nome para usuário')
            .min(3)
            .max(20),
          password: Yup.string().required('Senha Obrigatória'),
          confirmPassword: Yup.string()
            .required('Confirme a senha')
            .oneOf([Yup.ref('password')], 'Senhas não coincidem'),
          role: Yup.string().required(),
          unit: Yup.string().required().notOneOf(['select']),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        await createAccount(data);
        setBusy(false);
        setShowAddAccount(false);
      } catch (err) {
        alert('Verifique todos os campos');
        console.log(err);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
        setBusy(false);
      }
    },
    [createAccount, setShowAddAccount],
  );

  return (
    <Container>
      <Title title="Adicionar um novo funcionário" />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Row>
            <div>
              <h4>Usuário</h4>
              <TextInput name="username" type="text" />
            </div>
          </Row>
          <Row>
            <div>
              <h4>Senha</h4>
              <TextInput name="password" type="password" />
            </div>
            <div>
              <h4>Repetir senha</h4>
              <TextInput name="confirmPassword" type="password" />
            </div>
          </Row>
          <Permissions>
            <h1>Permissões</h1>
            <div className="div-input-permission">
              <TextInput name="unit" type="hidden" value={unitSelected} />
            </div>
            <select
              onChange={(e) => {
                const unit = e.target.value;

                setUnitSelected(unit);
              }}
            >
              <option value="select">Selecione a unidade</option>
              {business.units.map((unit) => {
                return <option value={unit.id}>{unit.name}</option>;
              })}
            </select>
            <RadioInputPermission
              name="role"
              options={[
                {
                  label: 'Gestor da unidade',
                  value: 'manager',
                },
                {
                  label: 'Operador da unidade',
                  value: 'employee',
                },
              ]}
            />
          </Permissions>

          <SpotsButton type="submit" disabled={busy}>
            Cadastrar
          </SpotsButton>
        </Form>
      </Content>
    </Container>
  );
};

export default AddAccount;
