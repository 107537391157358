import styled, { keyframes, css } from 'styled-components';

interface ContainerProps {
  isColored: boolean;
}

export const Container = styled.tbody<ContainerProps>`
  border-collapse: collapse;
  width: 95%;
  margin: 0 auto;
  border-top: 1px solid #bbb;

  .tr-body {
    text-align: center;

    .td-body {
      padding: 20px 0;
    }

    .username {
      font-size: 13px;
      padding-left: 10px;
      text-align: start;
    }
    .role,
    .unit,
    .active {
      font-size: 13px;
      padding-left: 10px;
      text-align: center;
    }
    .remove {
      button {
        border: none;
        background: inherit;
        margin: 0;
        padding: 0;
        color: #c53030;
      }
    }
  }
  ${(props) =>
    props.isColored &&
    css`
      background: #f1f3f6;
    `}
  @media screen and (min-width: 1600px) {
    button {
      font-size: 15px;
    }
    .tr-body {
      .td-body {
        font-size: 14px;
      }
      .status {
        span {
          background: green;
          padding: 5px;
          border-radius: 3px;
          color: #fff;
          font-size: 14px;
        }
      }
    }
  }
`;

const appearOpacity = keyframes`
  from {
    opacity: 0;
  }
  to{
    opacity: 0.5;
  }
`;

export const ContainerOpacity = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  background: #000;
  opacity: 0.5;
  animation: ${appearOpacity} 1s;
  z-index: 500;
`;

interface PauseBtnProps {
  isActive: boolean;
}

export const PauseBtn = styled.div<PauseBtnProps>`
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 15px;
  border: 1px solid #555;
  background: #70bb35;
  transition: background 1s;
  margin: 0 auto;
  .circle {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background: #fff;
    border-radius: 50%;
    border: 1px #555 solid;
    transition: all 1s ease;
    display: flex;
    text-align: center;
    justify-content: center;
    transform: translate(-1px, -1px);
    p {
      transform: translateY(25%);
      font-size: 10px;
      color: #bbb;
    }
  }
  ${(props) =>
    !props.isActive &&
    css`
      background: #c53030;
      .circle {
        left: 100%;
        transform: translate(calc(-100% + 1px), -1px);
      }
    `}
  &:hover {
    cursor: pointer;
  }
`;
