import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid #ccc;
  .divider {
    height: 1px;
    width: 80%;
    margin: 0 auto 10px auto;
    background: #ccc;
  }
`;

export const SubItems = styled.div`
  padding: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 2px;
  .SubItems-first-row {
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
  }
  .SubItems-secound-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
  .SubItems-first-row,
  .SubItems-secound-row {
    width: 100%;

    .SubItems-name,
    .SubItems-internalName,
    .SubItems-priority,
    .SubItems-price,
    .SubItems-description {
      h1 {
        align-self: flex-start;
        font-size: 12px;
        font-weight: 200;
        margin-bottom: 10px;
      }
      input {
        height: 15px;
        width: 100%;
        &::placeholder {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
    .SubItems-priority {
      width: 100px;
      margin-left: 20px;
    }
    .SubItems-name {
      margin-right: 20px;
    }
    .SubItems-description {
      flex: 2;
      margin-right: 20px;
    }
    .SubItems-price {
      flex: 1;
    }
  }
`;

export const RemoveButton = styled.button`
  border: none;
  background: inherit;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  h1 {
    font-size: 12px;
    font-weight: 200;
    color: #c53030;
    margin-right: 5px;
  }
  svg {
    color: #c53030;
  }
`;
